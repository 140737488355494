import FuseScrollbars from "@fuse/core/FuseScrollbars";
import { styled } from "@mui/material/styles";
import clsx from "clsx";
import { memo } from "react";
import Logo from "../../../../shared-components/Logo";
import NavbarToggleButton from "../../../../shared-components/NavbarToggleButton";
import UserNavbarHeader from "../../../../shared-components/UserNavbarHeader";
import Navigation from "../../../../shared-components/Navigation";
import UsageCardSmall from "app/shared-components/cards/usageCardSmall";
import { drawerStatsItems } from "app/configs/navigationConfig";

const Root = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  color: theme.palette.text.primary,
  "& ::-webkit-scrollbar-thumb": {
    boxShadow: `inset 0 0 0 20px ${
      theme.palette.mode === "light"
        ? "rgba(0, 0, 0, 0.24)"
        : "rgba(255, 255, 255, 0.24)"
    }`,
  },
  "& ::-webkit-scrollbar-thumb:active": {
    boxShadow: `inset 0 0 0 20px ${
      theme.palette.mode === "light"
        ? "rgba(0, 0, 0, 0.37)"
        : "rgba(255, 255, 255, 0.37)"
    }`,
  },
}));

const StyledContent = styled(FuseScrollbars)(({ theme }) => ({
  overscrollBehavior: "contain",
  overflowX: "hidden",
  overflowY: "auto",
  WebkitOverflowScrolling: "touch",
  backgroundRepeat: "no-repeat",
  backgroundSize: "100% 40px, 100% 10px",
  backgroundAttachment: "local, scroll",
}));

function NavbarStyle1Content(props) {
  return (
    <Root
      className={clsx(
        "flex flex-auto flex-col overflow-hidden h-full",
        props.className
      )}
      style={{
        backgroundColor: "#121212",
        boxShadow: "0px 0px 0px 0px",
      }}
    >
      <div className="flex flex-col items-start mt-20 ml-[18px]  shrink-0 h-48 md:h-72">
        <div className="flex px-4 w-full h-[46px] justify-between">
          <Logo className="ml-16 flex-1 text-white" />
          <NavbarToggleButton />
        </div>
      </div>

      <StyledContent
        className="flex flex-1 flex-col mt-16"
        option={{ suppressScrollX: true, wheelPropagation: false }}
      >
        <Navigation className="text-white" layout="vertical" />
        <div className="mx-16 mt-32">
          <UsageCardSmall
            className="ml-8 mr-8"
            items={drawerStatsItems}
          ></UsageCardSmall>
        </div>
      </StyledContent>
    </Root>
  );
}

export default memo(NavbarStyle1Content);
