import clsx from "clsx";
import PropTypes from 'prop-types';
import './empty-data.css'

export default function EmptyData(props) {
  return (
	<div className="flex flex-row mt-32 align-center items-center justify-center">
		<div className="justfy-center align-center items-center">
			<img className="circular-image"  src="assets/images/icons/empty.png" alt="empty"/>
		</div>
		<div className="mt-10 ml-16 w-[300px] text-gray-600">
			{ props.message }
		</div>
	</div>
  );
  }
EmptyData.propTypes = {
	className: PropTypes.string,
	message: PropTypes.string,
  };

EmptyData.defaultProps = {};
