import { memo } from 'react';
import Box from '@mui/material/Box';

function FuseSplashScreen() {
  return (
    <div id="fuse-splash-screen">
      <div className="flex mb-18">
          <img className="logo-icon" src="assets/images/logo/logo.png" alt="logo" />
          <span className="text-4xl text- ml-8 my-8 text-base-purple" style={{
            fontWeight: 700,
          }}>Podyc</span>
      </div>
      <Box
        id="spinner"
        sx={{
          '& > div': {
            backgroundColor: 'palette.secondary.main',
          },
        }}
      >
        <div className="bounce1" />
        <div className="bounce2" />
        <div className="bounce3" />
      </Box>
    </div>
  );
}

export default memo(FuseSplashScreen);
