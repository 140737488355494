
import { AES, enc } from 'crypto-js';

export default function aesEncrypt(string, secret) {
  const encryptedValue = AES.encrypt(
    string,
    secret.toString()
  );

  return encryptedValue.toString();
}

function aesDecrypt(cipher , secret) {
    try {
        const bytes = AES.decrypt(cipher,  secret.toString());
        const decrypted = bytes.toString(enc.Utf8);
        return decrypted;
      } catch (err) {
        console.log('UNABLE TO DECYPT', err);
      }
}

export { aesEncrypt, aesDecrypt }
