import { Controller, useForm } from 'react-hook-form';
import _ from 'lodash';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import * as yup from 'yup';
import { useAppSelector } from 'app/store';
import format from 'date-fns/format';

import { yupResolver } from '@hookform/resolvers/yup';
import { useSelector } from 'react-redux';
import { selectUser } from "app/store/userSlice";
import { CommentModel } from '../../../../models/CommentModel';

const schema = yup.object().shape({
  message: yup.string().required('You must enter a comment'),
});

const defaultValues = {
  idMember: 'baa88231-0ee6-4028-96d5-7f187e0f4cd5',
  message: '',
  time: format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"),
};


const CardComment = (props) => {
  const { onCommentAdd } = props;

  const user = useSelector(selectUser);;

  const { control, formState, handleSubmit, reset } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { isValid, dirtyFields, errors } = formState;

  const onSubmit = (data) => {
    onCommentAdd(CommentModel(data));
    reset(defaultValues);
  };

  if (!user) {
    return null;
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="flex -mx-8">
      <Avatar className="w-32 h-32 mx-8 ml-16" alt={user.data.displayName} src={user?.data?.avatar??''} />
      <div className="flex flex-col items-start flex-1 mx-8 mr-64">
        <Controller
          name="message"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              className="flex flex-1"
              fullWidth
              error={!!errors.message}
              helperText={errors?.message?.message}
              rows={3}
              variant="outlined"
              placeholder="Write a comment..."
              style={{
                backgroundColor:'#FFFBFF',
                borderRadius:"8px",
                
              }}
                      inputProps={{
                        disableUnderline: true, // Disables the default underline to use modern border
                        style: {
                lineHeight: '2rem',
                backgroundColor:'transparent',
                paddingRight:"4px",
                paddingBottom:"16px",
                          paddingTop:"8px", // Updated padding for a better look and feel
                          borderRadius: "8px", // Rounded corners for a modern look
                          fontWeight: 400,
                          fontSize: "1.4rem", // Base font size
                        },
                      }}
              InputProps={{
                style:{
                  padding: '0px 0px 12px 0px'
                }
              }}
            />
          )}
        />

        <Button
          className="mt-16 rounded-md"
          aria-label="save"
          variant="contained"
          color="secondary"
          type="submit"
          size="small"
          disabled={_.isEmpty(dirtyFields) || !isValid}
        >
          Save
        </Button>
      </div>
    </form>
  );
};

export default CardComment;
