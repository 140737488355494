import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import { SignInForm } from "src/app/main/sign-in/SignInForm";
import { GoogleAuth } from "app/shared-components/auth/GoogleAuth";
import DividerWithText from "../dividers/DividerWithText";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { selectLoginDialog, toggleLoginDialog } from "app/store/authSlice";

export function SignInDialog() {
  const dispatch = useDispatch();
  const loginDialog = useSelector(selectLoginDialog);

  const handleClickOpen = () => {
    dispatch(toggleLoginDialog(true));
  };

  const handleClose = () => {
    dispatch(toggleLoginDialog(false));
  };

  return (
    <React.Fragment>
      <Button variant="contained" className="text-main-bg rounded-md bg-primary hover:scale-105 duration-300 hover:bg-primary" onClick={handleClickOpen}>
        Log in
      </Button>
      <Dialog
        sx={{
          "& .MuiPaper-root": {
            borderRadius: 0,
            height: "100%",
            backgroundColor: "#121212",
          },
        }}
        fullScreen
        open={loginDialog}
        onClose={handleClose}
      >
        <div className="absolute top-16 right-16 text-white">
          <IconButton onClick={handleClose}>
            <CloseIcon className="text-white" />
          </IconButton>
        </div>
        <div className="h-full grid place-items-center">
          <div className="max-w-sm w-full">
            <div className="flex flex-col gap-0 items-center">
              <Typography className="font-bold text-5xl text-main-text-color">Welcome</Typography>
              <Typography className="opacity-80 text-xl text-main-text-color">
                Sign in or sign up to continue
              </Typography>
            </div>
            <div className="mt-32 flex flex-col gap-16">
              <GoogleAuth />
              <DividerWithText className="text-main-text-color"> <span className="text-main-text-color">or</span> </DividerWithText>
              <SignInForm />
            </div>
          </div>
        </div>
      </Dialog>
    </React.Fragment>
  );
}
