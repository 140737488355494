import React, { useState } from "react";
import SettingsTextField from "../../../../shared-components/forms/settingsTextField";
import { Button } from "@mui/material";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import axios from "axios";
import LoadingText from "../LoadingText";
import { showMessage } from "app/store/fuse/messageSlice";



export default function AgentInfo(props) {
  const formData = {
    agentName: {
      id: "agentName",
      validate: (value) => {
        return value.length > 3 && value.length < 10;
      },
      title: "Agent Name",
      icon: "assets/images/name-icon.png",
      placeholder: "Bot",
      description:
        "Enter the name of your agent. This will be used to greet your customers when they start a conversation with you.",
    },
    agentRole: {
      id: "agentRole",
      validate: (value) => {
        return value.length > 3 && value.length < 48;
      },
      title: "Agent Role",
      icon: "assets/images/role-icon.png",
      description:
        "Enter the role of your agent. This will be used during the conversation to direct the customer during the conversation.",
      placeholder: "Customer Support Representative",
    },
    companyName: {
      id: "companyName",
      validate: (value) => {
        return value.length > 3 && value.length < 30;
      },
      title: "Company Name",
      icon: "assets/images/companyName-icon.png",
      description:
        "Enter the name of your company. This will be used to greet your customers when they start a conversation with you.",
      placeholder: "ACME Inc.",
    },
    companyBusiness: {
      id: "companyBusiness",
      validate: (value) => {
        return value.length > 3 && value.length < 128;
      },
      title: "Company Business",
      icon: "assets/images/companyBusiness-icon.png",
      description:
        "Whats the nature of your business? Provide a short description withi 128 characters",
      placeholder: "ACME Inc is an apperel and lifestyle brand caterig to products for the great outdoors",
    },
    companyValues: {
      id: "companyValues",
      validate: (value) => {
        return value.length > 3 && value.length < 128;
      },
      title: "Company Values",
      icon: "assets/images/companyValues-icon.png",
      description:
        "Enter a short description of your company values. This will be used to direct and make the conversation more human.",
      placeholder:
        "ACME Inc strives to provide the best outdoor products and customer service to our customers.",
    },
    conversationPurpose: {
      id: "conversationPurpose",
      validate: (value) => {
        return value.length > 3 && value.length < 128;
      },
      title: "Conversation Purpose",
      icon: "assets/images/role-icon.png",
      description:
        "What is the purpose of this agent? This will be used by the AI to direct the conversation.",
      placeholder:
        "Help the customer and make personalised shopping experience and product reccomendations",
    },
  };

  const [isValidAgentName, setIsValidAgentName] = useState(false);
  const [isValidAgentRole, setIsValidAgentRole] = useState(false);
  const [isValidCompanyName, setIsValidCompanyName] = useState(false);
  const [isValidCompanyBusiness, setIsValidCompanyBusiness] = useState(false);
  const [isValidCompanyValues, setIsValidCompanyValues] = useState(false);
  const [isValidConversationPurpose, setIsValidConversationPurpose] =
    useState(false);
  const [agentName, setAgentName] = useState("");
  const [agentRole, setAgentRole] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companyBusiness, setCompanyBusiness] = useState("");
  const [companyValues, setCompanyValues] = useState("");
  const [conversationPurpose, setConversationPurpose] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  useState(() => {
    if(props.data){
      if (props.data.attributes && props.data.attributes.agent_name) {
        setAgentName(props.data.attributes.agent_name);
        setIsValidAgentName(true);
      }
      if (props.data.attributes && props.data.attributes.agent_role) {
        setAgentRole(props.data.attributes.agent_role);
        setIsValidAgentRole(true);
      }
      if (props.data.attributes && props.data.attributes.company_name) {
        setCompanyName(props.data.attributes.company_name);
        setIsValidCompanyName(true);
      }
      if(props.data.attributes && props.data.attributes.company_business) {
        setCompanyBusiness(props.data.attributes.company_business);
        setIsValidCompanyBusiness(true);
      }
      if (props.data.attributes && props.data.attributes.company_values) {
        setCompanyValues(props.data.attributes.company_values);
        setIsValidCompanyValues(true);
      }
      if (props.data.attributes && props.data.attributes.conversation_purpose) {
        setConversationPurpose(props.data.attributes.conversation_purpose);
        setIsValidConversationPurpose(true);
      }
    }
    
  });

  const handleFormSubmit = () => {
    if (
      isValidAgentName &&
      isValidAgentRole &&
      isValidCompanyName &&
      isValidCompanyBusiness &&
      isValidCompanyValues &&
      isValidConversationPurpose
    ) {
      
        setIsLoading(true);
        if (!isLoading) {
            handleCreateAgentData(
                agentName,
                agentRole,
                companyName,
                companyBusiness,
                companyValues,
                conversationPurpose
              );
        }
    }
  };
  const updateAgentCreateData = async (
    agentName,
    agentRole,
    companyName,
    companyBusiness,
    companyValues,
    conversationPurpose,
  ) => {
   
    try{
      const response = await axios.put(
        `${process.env.REACT_APP_AUTH_BASE_URL}/api/ai-agents/${props.data.id}`,
        {
          data: {
          knowledgebases:{ connect: [props.kb_id] },
          title: 'Agent ' + agentName + ' for' + companyName ,
          agent_name: agentName,
          agent_role: agentRole,
          company_name: companyName,
          company_business:companyBusiness,
          company_values: companyValues,
          conversation_purpose: conversationPurpose
        }
      }
      );
      return response.data
    }
    catch(error){
      throw error;
    }
  };
  
  const AddAgentCreateData = async (
    agentName,
    agentRole,
    companyName,
    companyBusiness,
    companyValues,
    conversationPurpose,
  ) => {
    
    try{
      const response = await axios.post(
        `${process.env.REACT_APP_AUTH_BASE_URL}/api/ai-agents`,
        {
          data: {
            knowledgebases:{ connect: [props.kb_id] },
            title: 'Agent ' + agentName + ' for' + companyName ,
            agent_name: agentName,
            agent_role: agentRole,
            company_name: companyName,
            company_business:companyBusiness,
            company_values: companyValues,
            conversation_purpose: conversationPurpose
          }
        }
      );
      return response.data
    }
    catch(error){
      throw error;
    }
  
  };

  const handleCreateAgentData = async (
    agentName,
    agentRole,
    companyName,
    companyBusiness,
    companyValues,
    conversationPurpose,
  ) => {
    try {
      var response;
      if (props.data?.id != null) {
        response = await updateAgentCreateData(
            agentName,
            agentRole,
            companyName,
            companyBusiness,
            companyValues,
            conversationPurpose,
            );
      } else {
        response = await AddAgentCreateData(
            agentName,
            agentRole,
            companyName,
            companyBusiness,
            companyValues,
            conversationPurpose,
        )
      }

      if (
        response.data.attributes.agent_name &&
        response.data.attributes.agent_role &&
        response.data.attributes.company_name &&
        response.data.attributes.company_business &&
        response.data.attributes.company_values &&
        response.data.attributes.conversation_purpose
      ) {
        setAgentName(response.data.attributes.agent_name);
        setAgentRole(response.data.attributes.agent_role);
        setCompanyName(response.data.attributes.company_name);
        setCompanyBusiness(response.data.attributes.company_business);
        setCompanyValues(response.data.attributes.company_values);
        setConversationPurpose(response.data.attributes.conversation_purpose);

        setIsValidAgentName(true);
        setIsValidAgentRole(true);
        setIsValidCompanyBusiness(true);
        setIsValidCompanyName(true);
        setIsValidCompanyValues(true);
        setIsValidConversationPurpose(true);


        setIsLoading(false);
        dispatch(
          showMessage({
            message: "Your agent details have been updated successfully",
          })
        );
      } else {
        throw new Error("Could not add your agent details");
      }
    } catch (e) {
        setIsLoading(false);
        setAgentName("");
        setAgentRole("");
        setCompanyName("");
        setCompanyValues("");
        setConversationPurpose("");
        setCompanyBusiness("");

        setIsValidAgentName(false);
        setIsValidAgentRole(false);
        setIsValidCompanyName(false);
        setIsValidCompanyBusiness(false);
        setIsValidCompanyValues(false);
        setIsValidConversationPurpose(false);
        
        setIsLoading(false);
        
        dispatch(
        showMessage({
          message:
            "Failure. Agent details could not be updated. Please try again.",
        })
      );
    } finally {
    }
  };

  return (
    <div className="settigs-grid-container">
      <div className="page-heading">Agent Info</div>
      <span className="w-full mt-16 mb-32 p-0.5 bg-grey-300 lg:w-1/3"></span>
      {isLoading ? (
        <LoadingText className />
      ) : (
        <div className="grid  w-full grid-cols-1 gap-y-32 ">
          <div className="settings-grid-item">
            <SettingsTextField
              finalValue={isValidAgentName ? agentName : ""}
              id={formData.agentName.id}
              icon={formData.agentName.icon}
              title={formData.agentName.title}
              description={formData.agentName.description}
              placeholder={formData.agentName.placeholder}
              validate={formData.agentName.validate}
              onChange={(id, value, isValid) => {
                setAgentName(value);
                setIsValidAgentName(isValid);
              }}
            />
          </div>
          <div className="grid-item">
            <SettingsTextField
              finalValue={isValidAgentRole ? agentRole : ""}
              id={formData.agentRole.id}
              icon={formData.agentRole.icon}
              title={formData.agentRole.title}
              description={formData.agentRole.description}
              placeholder={formData.agentRole.placeholder}
              validate={formData.agentRole.validate}
              onChange={(id, value, isValid) => {
                setAgentRole(value);
                setIsValidAgentRole(isValid);
              }}
            />
          </div>
          <div className="grid-item">
            <SettingsTextField
              finalValue={isValidCompanyName ? companyName : ""}
              id={formData.companyName.id}
              icon={formData.companyName.icon}
              title={formData.companyName.title}
              description={formData.companyName.description}
              placeholder={formData.companyName.placeholder}
              validate={formData.companyName.validate}
              onChange={(id, value, isValid) => {
                setCompanyName(value);
                setIsValidCompanyName(isValid);
              }}
            />
          </div>
          <div className="grid-item">
            <SettingsTextField 
              finalValue={isValidCompanyBusiness ? companyBusiness : ""}
              isMultilineMode={true}
              id={formData.companyBusiness.id}
              icon={formData.companyBusiness.icon}
              title={formData.companyBusiness.title}
              description={formData.companyBusiness.description}
              placeholder={formData.companyBusiness.placeholder}
              validate={formData.companyBusiness.validate}
              onChange={(id, value, isValid) => {
                setCompanyBusiness(value);
                setIsValidCompanyBusiness(isValid);
              }}
            />
          </div>
          <div className="grid-item">
            <SettingsTextField
              finalValue={isValidCompanyValues ? companyValues : ""}
              isMultilineMode={true}
              id={formData.companyValues.id}
              icon={formData.companyValues.icon}
              title={formData.companyValues.title}
              description={formData.companyValues.description}
              placeholder={formData.companyValues.placeholder}
              validate={formData.companyValues.validate}
              onChange={(id, value, isValid) => {
                setCompanyValues(value);
                setIsValidCompanyValues(isValid);
              }}
            />
          </div>
          <div className="grid-item">
            <SettingsTextField
              finalValue={isValidConversationPurpose ? conversationPurpose : ""}
              isMultilineMode={true}
              id={formData.conversationPurpose.id}
              icon={formData.conversationPurpose.icon}
              title={formData.conversationPurpose.title}
              description={formData.conversationPurpose.description}
              placeholder={formData.conversationPurpose.placeholder}
              validate={formData.conversationPurpose.validate}
              onChange={(id, value, isValid) => {
                setConversationPurpose(value);
                setIsValidConversationPurpose(isValid);
              }}
            />
          </div>
          <Button
            disabled={
                isLoading ||
                !isValidAgentName ||
                !isValidAgentRole ||
                !isValidCompanyName ||
                !isValidCompanyValues ||
                !isValidConversationPurpose

            }
            onClick={() => {
              handleFormSubmit();
            }}
            variant="contained"
            color="secondary"
            className="press-button shine-button w-96 h-40 mt-16 rounded-md bg-primary hover:bg-primary text-white"
            aria-label="Save"
            // disabled={_.isEmpty(dirtyFields) || !isValid}
            type="submit"
            size="large"
          >
            Save
          </Button>
        </div>
      )}
    </div>
  );
}

AgentInfo.propTypes = {
  data: PropTypes.any,
  kb_id: PropTypes.number,
};
