import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import * as yup from "yup";
import _ from "@lodash";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import jwtService from "../../auth/services/jwtService";
import Typography from "@mui/material/Typography";
/**
 * Form Validation Schema
 */
const schema = yup.object().shape({
  email: yup
    .string()
    .email("You must enter a valid email")
    .required("You must enter a email"),
});

const defaultValues = {
  email: "",
};

export default function ForgotPassword() {
  const { control, formState, handleSubmit, setError } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { isValid, dirtyFields, errors } = formState;

  function onSubmit({ email, password }) {
    jwtService
      .forgotPassword(email)
      .then((user) => {
        // No need to do anything, user data will be set at app/auth/AuthContext
      })
      .catch((error) => {
        setError(error);
      });
  }

  return (
    <div className="flex w-full h-full align-center items-center justify-center bg-main-bg">
      <Paper className="rounded-md shadow-lg shadow-primary  min-w-480 bg-main-text-color">
        <div className="w-full p-32">
          <div className="flex mb-24">
            <img
              className="logo-icon w-96"
              src="assets/images/logo/logo-text.png"
              alt="logo"
            />
          </div>
          <Typography className="mt-32 text-4xl font-extrabold tracking-tight leading-tight">
            Reset Password
          </Typography>
          <div className="flex items-baseline mt-6 font-regular text-base text-lightGrey">
            <p>Don't have an account?</p>
            <a className="ml-4 text-main-bg font-bold" href="/sign-up">
              Sign up{" "}
            </a>
          </div>
          <form
            name="loginForm"
            noValidate
            className="flex flex-col justify-center w-full mt-24"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  className="mb-24"
                  style={{
                    backgroundColor: "transparent",
                  }}
                  placeholder="Enter your email address"
                  inputProps={{
                    disableUnderline: true, // Disables the default underline to use modern border
                    style: {
                      backgroundColor: "transparent",
                      paddingRight: "4px",
                      paddingBottom: "4px",
                      paddingTop: "2px", // Updated padding for a better look and feel
                      fontWeight: 400,
                      height: "4rem", // Fix height of text field
                      fontSize: "1.5rem", // Base font size
                    },
                  }}
                  autoFocus
                  type="email"
                  error={!!errors.email}
                  helperText={errors?.email?.message}
                  variant="outlined"
                  fullWidth
                />
              )}
            />

            <Button
              variant="contained"
              color="secondary"
              className="press-button shine-button w-full mt-16 rounded-md bg-primary hover:bg-primary text-main-bg"
              aria-label="Forgot Password"
              // disabled={_.isEmpty(dirtyFields) || !isValid}
              type="submit"
              size="large"
            >
              Forgot Password
            </Button>
          </form>
        </div>
      </Paper>
    </div>
  );
}
