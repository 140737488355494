import React from "react";
import "./main-modal.css";
import BaseDialog from "app/shared-components/dialog/base-dialog";
import SourceCard from "./source-card/source-card";

export default function AddSourceModal({ open, handleClose, onItemClick }) {
  const handleCardClick = (cardName) => {
    onItemClick(cardName);
  };

  const data = [
    {
      id: 1,
      cardName: "url",
      imageUrl: "assets/images/icons/web-url.svg",
      textHeading: "Url",
      textDescription:
        "Add any URL as a source. Podyc ingests information from content scraped by the url ",
    },
    {
      id: 2,
      cardName: "textPdf",
      imageUrl: "assets/images/icons/document.svg",
      textHeading: "Documents",
      textDescription:
        "Add any pdf document as a source. Podyc ingests text based content from your pdf documents. Ensure that the pdf document has text based content and not purely images. So scanned documents, screenshots, and other image based pdfs will not work.",
    },
    {
      id: 3,
      cardName: "youtube",
      imageUrl: "assets/images/icons/youtube.svg",
      textHeading: "Youtube Video",
      textDescription:
        "Add any public youtube video. Podyc ingests the text scripts from a youtube video",
    },
  ];

  return (
    <BaseDialog open={open} handleClose={handleClose} title="Add Source" className="w-[40%]">
      <div className="flex flex-col gap-20 pl-20 m-24">
        {data.map((item) => (
          <SourceCard
            key={item.textHeading}
            title={item.textHeading}
            description={item.textDescription}
            icon={item.imageUrl}
            onClick={() => handleCardClick(item.cardName)}
          />
        ))}
      </div>
    </BaseDialog>
  );
}
