import React, { useState } from 'react';
import './switch.css'

const CustomSwitch = ({
  selectionMode,
  option1,
  option2,
  onSelectSwitch,
}) => {
  const [getSelectionMode, setSelectionMode] = useState(selectionMode);

  const updatedSwitchData = val => {
    setSelectionMode(val);
    onSelectSwitch(val);
  };

  return (
    <div>
      <div
      className='switch-container'
      >
        <button
          onClick={() => updatedSwitchData(1)}
          className='flex-1 justify-center items-center rounded py-2 px-8'
          style={{
            backgroundColor: getSelectionMode === 1 ? '#000000' : '#F9FAFB',
          }}
        >
          <span
            style={{
              color: getSelectionMode === 1 ? 'white' : 'gray',
            }}
          >
            {option1}
          </span>
        </button>
        <button
          onClick={() => updatedSwitchData(2)}
          className='flex-1 justify-center items-center rounded py-2 px-8'
          style={{
            backgroundColor: getSelectionMode === 2 ? '#000000' : '#F9FAFB',
          }}
        >
          <span
            style={{
              color: getSelectionMode === 2 ? 'white' : 'gray',
            }}
          >
            {option2}
          </span>
        </button>
      </div>
    </div>
  );
};

export default CustomSwitch;
