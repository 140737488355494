const defaultAlbumPosters = [
  "assets/images/album_art_1.png",
  "assets/images/album_art_2.png",
  "assets/images/album_art_3.png",
  "assets/images/album_art_4.png",
  "assets/images/album_art_5.png",
  "assets/images/album_art_6.png",
  "assets/images/album_art_7.png",
  "assets/images/album_art_8.png",
];

export function getRandomPoster() {
  const poster =
    defaultAlbumPosters[Math.floor(Math.random() * defaultAlbumPosters.length)];

  return poster;
}
