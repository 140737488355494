const { uniqueId } = require('@lodash');

/**
 * The list model.
 */
function ListModel(data) {
  data = data || {};

  return {
    id: uniqueId(),
    boardId: '',
    title: '',
    ...data
  };
}

module.exports = ListModel;
