import React, { useState } from "react";
import PaginationComponent from "app/shared-components/pagination/PaginationComponent";
import moment from "moment";
import FileDownloadIcon from '@mui/icons-material/FileDownload';


const FileResultsTable = ({ fileResults }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const totalPages = Math.ceil(fileResults?.length ?? 0 / itemsPerPage);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems =
    fileResults != null
      ? fileResults.slice(indexOfFirstItem, indexOfLastItem)
      : [];
  const getStatusClass = (status) => {
    switch (status) {
      case "completed":
        return "bg-green-100 text-green-600";
      case "failed":
        return "bg-red-100 text-red-600";
      case "processing":
        return "bg-teal-100 text-teal-600";
      case "pending":
        return "bg-yellow-100 text-yellow-600";
      default:
        return "";
    }
  };

  const [showDropdown, setShowDropdown] = useState(false);
  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  return (
    <div className="overflow-x-auto border-1 py-16 rounded-md shadow-md">
      <table className="min-w-full bg-white">
        <thead>
          <tr>
            <th className="px-4 py-2 w-[10%]">Sl No</th>
            <th className="px-4 py-2">Report Objective</th>
            <th className="px-4 py-2">Credits Used</th>
            <th className="px-4 py-2">Status</th>
            <th className="px-4 py-2">Time</th>
            <th className="px-4 py-2">Pdf</th>
            <th className="px-4 py-2">Docx</th>
            <th className="px-4 py-2">Markdown</th>
          </tr>
        </thead>
        <tbody>
          {currentItems.map((item, index) => (
            <tr key={index} className="border-t">
              <td className="px-32 py-16">{index + 1}</td>
              <td className="px-32 py-16 w-[30%]">{item.attributes.query}</td>
              <td className="px-32 py-16 w-[10%]">{item.attributes.credits_used}</td>
              <td
                className={`px-32 py-16 rounded w-[10%] ${getStatusClass(
                  item.attributes.status
                )}`}
              >
                {item.attributes.status}
              </td>
              <td className="flex w-full py-16">
                {moment(item.attributes.createdAt).format("MMM, DD, YYYY - hh:mm A")}
              </td>
              <td className="px-16 py-16 text-blue-500 cursor-pointer w-[10%] items-center">
                <FileDownloadIcon className="flex w-full text-base-purple" onClick={() => {
                    window.open(item.attributes.pdf_url, "_blank");
                }
                } />
               </td>
               <td className="px-16 py-16 text-blue-500 cursor-pointer w-[10%] items-center">
                <FileDownloadIcon className="flex w-full text-base-purple" onClick={() => {
                    window.open(item.attributes.docx_url, "_blank");

                }} />
               </td>
               <td className="px-16 py-16 text-blue-500 cursor-pointer w-[10%] items-center">
                <FileDownloadIcon className="flex w-full text-base-purple" onClick={() => {
                    window.open(item.attributes.markdown_url, "_blank");

                }} />
               </td>
            </tr>
          ))}
        </tbody>
      </table>

      <PaginationComponent
        className="mt-16"
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={setCurrentPage}
      />
    </div>
  );
};

export default FileResultsTable;

