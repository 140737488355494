import Tooltip from '@mui/material/Tooltip';
import { useAppSelector } from 'app/store';
import Chip from '@mui/material/Chip';
import { selectLabelById } from '../../store/labelsSlice';
import { getLabelbgColorCss } from 'src/app/utils/colors';
import clsx from 'clsx';

function BoardCardLabel(props) {
  const { id } = props;

  const label = useAppSelector(selectLabelById(id));

  if (!label) {
    return null;
  }
  const labelColor=getLabelbgColorCss(label.attributes.title);

  return (
    <Tooltip title={label.attributes.title} key={id}>
      <Chip className={clsx( 'font-light text-xs text-white mx-4 mb-6',labelColor)} label={label.attributes.title} size="small" />
    </Tooltip>
  );
}

export default BoardCardLabel;
