import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import JwtService from 'src/app/auth/services/jwtService'
import history from '@history';
import { showMessage } from 'app/store/fuse/messageSlice';


function GoogleAuthCallback() {
  const location = useLocation()
  useEffect(() => {
    if (!location) {
      return
    }
    const { search } = location
	JwtService.signInWithGoogle(search).then((user) => {
       history.push('/dashboards/home');
      })
      .catch((error) => {
       showMessage({message:"Something went wrong try again"});
	   history.push('/sign-in');
      });;
  }, [location])

  return (
    <div className='flex flex-col items-center justify-center h-full gap-4'>
      <div className="w-8 h-8 border-4 border-primary border-t-transparent bg-primary rounded-full animate-spin" />
      <span className='text-2xl font-semibold !text-white'>
        Signing in with Google... Please wait
      </span>
    </div>
  )
}

export default GoogleAuthCallback
