import authRoles from "../../auth/authRoles";
import { PlaylistDetailsPage } from ".";

export const playlistDetailsPageConfig = {
  settings: {
    layout: {
      config: {
        navbar: {
          display: true,
        },
        toolbar: {
          display: false,
        },
        footer: {
          display: false,
        },
        leftSidePanel: {
          display: false,
        },
        rightSidePanel: {
          display: false,
        },
      },
    },
  },
  auth: authRoles.user,
  routes: [
    {
      path: "/playlist/:id",
      element: <PlaylistDetailsPage />,
    },
  ],
};
