// src/components/StatsCard.js
import { useState, useEffect, useCallback } from "react";
import { Button } from "@mui/material";
import { useSelector } from "react-redux";
import { selectUsage, getUsage } from "app/store/usageSlice";
import { selectUser } from "app/store/userSlice";
import { useDispatch } from "react-redux";
import { humanReadableNumber } from "../text-truncate/human-readable-text";
import Progress from "app/shared-components/indicators/progress_bar";
import { CreditCardIcon, FileBoxIcon, FolderPenIcon } from "lucide-react";
import TryIcon from '@mui/icons-material/Try';
import LoyaltyIcon from '@mui/icons-material/Loyalty';
import UnsubscribeIcon from '@mui/icons-material/Unsubscribe';
import history from '@history';
const UsageCardSmall = () => {

  const usage = useSelector(selectUsage);
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const [items, setItems] = useState([]);

  useEffect(() => {
    console.log('Fetching usage...');
    dispatch(getUsage());
  }, [dispatch]);

  useEffect(() => {
    console.log('Usage changed:', usage);
    make();
  }, [usage, user]);

  const make = useCallback(() => {
    if (
      usage == null ||
      user.data.organization == null ||
      Object.keys(user.data.organization).length === 0
    ) {
      console.log('Invalid usage or organization data');
      return;
    }
    const subscriptionType = (
      <div className="flex py-8">
        <div className="flex-shrink-0 mt-4">
          {{
            trial: <TryIcon className="w-20 h-20" />,
            subscribed: <LoyaltyIcon className="w-20 h-20" />,
            unsubscribed: <UnsubscribeIcon className="w-20 h-20" />
          }[usage.subscription_type] || <CreditCardIcon className="w-20 h-20" />}
        </div>
        <div className="flex flex-col w-full">
          <div className="ml-16 text-[14px] font-bold my-4">
            Subscription Type
          </div>
          <div className="ml-16 text-md font-regular my-4 text-main-text-color font-semibold">
            {usage.subscription_type.charAt(0).toUpperCase() + usage.subscription_type.slice(1)}
          </div>
        </div>
      </div>
    );

    const planName = (
      <div className="flex py-8">
        <div className="flex-shrink-0 mt-4">{<FileBoxIcon className="w-20 h-20" />}</div>
        <div className="flex flex-col w-full">
          <div className="ml-16 text-[14px] font-bold my-4">
            Plan Name
          </div>
          <div className="ml-16 text-md font-regular my-4 text-main-text-color font-semibold">
            {usage.plan_name}
          </div>
        </div>
      </div>
    );

    const creditsUsage = (
      <div className="flex py-8">
        <div className="flex-shrink-0 mt-4">{<CreditCardIcon className="w-20 h-20" />}</div>
        <div className="flex flex-col w-full">
          <div className="ml-16 text-[14px] font-bold my-4">
            Credits Usage
          </div>
          {usage.quota > 0 && (
            <div className="ml-16 my-8 mr-32">
              <Progress progress={(usage.queries_count / usage.quota) * 100 || 0} className="" />
            </div>
          )}
          <div className="ml-16 text-md font-regular my-4">
            {`${humanReadableNumber(usage.queries_count)} / ${humanReadableNumber(usage.quota || 0)}`}
          </div>
        </div>
      </div>
    );

    setItems([usage.plan_name ? planName : null, subscriptionType, creditsUsage]);
  }, [usage, user]);

  return (
    <div className="bg-main-bg/60 p-8 rounded-lg border border-main-bg/40 text-main-text-color">
      {items.map((item, index) => (
        <div key={index} className="flex flex-col px-8">
          {item}
        </div>
      ))}
      <Button
        onClick={() => {
         history?.push("/dashboards/organisation");
        }}
        variant="contained"
        color="secondary"
        className="press-button shine-button text-black w-full mt-16rounded-md  hover:bg-primary bg-primary"
        aria-label="Sign in"
        type="submit"
        size="small"
      >
        Upgrade
      </Button>
    </div>
  );
};

export default UsageCardSmall;
