export const getImageForKB = (type) => {
    switch (type) {
        case 14:
            return "assets/images/qna_agent.svg";
        case 16:
            return "assets/images/sales-icon.svg";
        case 15:
            return "assets/images/support-icon.svg";
        case 19:
            return "assets/images/qa-icon.svg";

    }
}