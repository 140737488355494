import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	Checkbox,
	ListItemText,
	Chip,
	Box,
} from '@mui/material';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
};

MultiSelectDropdown.defaultProps = {
	defaultValues: [],
};

MultiSelectDropdown.propTypes = {
	options: PropTypes.array.isRequired,
	defaultValues: PropTypes.array,
	onSubmit: PropTypes.func.isRequired,
};

export default function MultiSelectDropdown({
	options,
	defaultValues,
	onSubmit,
}) {
	const [selectedOptions, setSelectedOptions] = useState(defaultValues);

	const handleChange = (event) => {
		const {
			target: { value },
		} = event;
		setSelectedOptions(
			typeof value === 'string' ? value.split(',') : value
		);
	};

	const handleClose = () => {
		// Trigger onSubmit callback when the dropdown is closed
		onSubmit(selectedOptions);
	};

	return (
		<FormControl fullWidth>
			<InputLabel
				id="multi-select-label"
				style={{
					color: 'black',
					backgroundColor: 'white',
					paddingInline: '10px',
				}}
			>
				Select Users
			</InputLabel>
			<Select
				labelId="multi-select-label"
				multiple
				value={selectedOptions}
				onChange={handleChange}
				onClose={handleClose}
				renderValue={(selected) => (
					<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
						{selected.map((value) => (
							<Chip
								key={value}
								label={value}
							/>
						))}
					</Box>
				)}
				MenuProps={MenuProps}
				style={{ color: 'black' }} // Change text color
			>
				{options.map((option) => (
					<MenuItem
						key={option}
						value={option}
					>
						<Checkbox
							checked={selectedOptions.indexOf(option) > -1}
							sx={{
								'& .MuiSvgIcon-root': {
									color: option ? 'primary.main' : 'grey', // Change 'grey' to your desired color
								},
							}}
						/>
						<ListItemText primary={option} />
					</MenuItem>
				))}
			</Select>
		</FormControl>
	);
}
