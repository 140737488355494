import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import { useState } from 'react';
import { useAppSelector } from 'app/store';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import ToolbarMenu from './ToolbarMenu';
import { selectLabels } from '../../../../store/labelsSlice';

const LabelsMenu = (props) => {
  const { labels, onToggleLabel } = props;

  const labelsArr = useAppSelector(selectLabels);

  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton onClick={handleMenuOpen} size="large">
        <FuseSvgIcon className='text-black'>heroicons-outline:tag</FuseSvgIcon>
      </IconButton>
      <ToolbarMenu state={anchorEl} onClose={handleMenuClose}>

        <div>
          {labelsArr.map((label) => {
            return (
              <MenuItem
                className="px-8"
                key={label.id}
                onClick={() => {
                  onToggleLabel(label);
                }}
              >
                <Checkbox 
                checked={labels.some(l => l.id === label.id)
                  //labels.includes(label.id)
                } 
                sx={{
                  '& .MuiSvgIcon-root': {
                    color: 'black',
                  },
                }}
                />
                <ListItemText className="mx-8">{label.attributes.title}</ListItemText>
                <ListItemIcon className="min-w-24">
                  <FuseSvgIcon  className='text-black'>heroicons-outline:tag</FuseSvgIcon>
                </ListItemIcon>
              </MenuItem>
            );
          })}
        </div>
      </ToolbarMenu>
    </div>
  );
};

export default LabelsMenu;
