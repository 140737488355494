import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loginDialog: false,
  signupDialog: false,
};
const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    toggleLoginDialog: (state, action) => {
      state.signupDialog = false;
      state.loginDialog = action.payload;
    },
    toggleSignupDialog: (state, action) => {
      state.loginDialog = false;
      state.signupDialog = action.payload;
    },
  },
});

export const { toggleLoginDialog, toggleSignupDialog } = authSlice.actions;
export const selectLoginDialog = ({ authSlice }) => authSlice.loginDialog;
export const selectSignupDialog = ({ authSlice }) => authSlice.signupDialog;

export default authSlice.reducer;
