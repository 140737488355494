import { React, useState, useEffect } from "react";
import { Typography} from "@mui/material";
import { useDispatch } from "react-redux";
import "./kb.css";
import ConfirmDialog from "app/shared-components/dialog/confirm-dialog";
import LoadingSpinner from "app/shared-components/loading-spinner/loading-spinner";
import { ChunksTable } from "./tables/ChunksTable";

export default function ChunksListPage({ open, documentId, documentName , handleClose }) {
  const [deleteSourceModalOpen, setDeleteSourceModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const [kbloading, setKBLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);


  useEffect(() => {}, [dispatch]);


  const handleAddSourceModalOpen = () => {
    setSuccessMessage("");
    setFailureMessage("");
    setAddSourceModalOpen(true);
  };
  const handleDeleteSourceModalOpen = () => {
    setDeleteSourceModalOpen(true);
  };
  const onDeleteCancel = () => {
    setDeleteSourceModalOpen(false);
  };
  async function HandleDeleteSource() {
    setDeleteSourceModalOpen(false);
    setLoading(true);
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_AUTH_BASE_URL}/api/delete_kb_source/${kbID}`
      );
      setData([]);
      setLoading(false);
      dispatch(showMessage({ message: "All trained data has been cleared" }));
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }



  return (
    <div className="w-full">
      <div>
        {(
          <div>
            <ChunksTable
              documentName={documentName}
              documentId={documentId}
              onAddClick={handleAddSourceModalOpen}
              onDeleteAllClick={handleDeleteSourceModalOpen}
            />
          </div>
        )}
        <ConfirmDialog
          open={deleteSourceModalOpen}
          message="Are you sure want to delete all trained data?!"
          onCancel={onDeleteCancel}
          onConfirm={HandleDeleteSource}
        />
      </div>
    </div>
  );
}
