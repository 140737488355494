import React, { useState } from "react";
import history from '@history';
import { Box, IconButton, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CodeSyntaxHighlighter from 'app/shared-components/code-syntax-highlighter/CodeSyntaxHighlighter'
import {aesEncrypt} from "src/app/utils/encryptions";
import { useSelector } from "react-redux";
import { selectUser } from "app/store/userSlice";
import { Theme } from "@fullcalendar/core/internal";
import { ThemeForm } from "app/shared-components/theme-form/theme-form";

export default function ChatBotStylePage() {
	const user = useSelector(selectUser);
	let kbID = new URLSearchParams(location.search).get('kb');
	kbID= aesEncrypt(kbID, process.env.REACT_APP_ENCRYPTION_KEY)
	let orgID = aesEncrypt(user.data.organization.org_id, process.env.REACT_APP_ENCRYPTION_KEY);
	
    return(
		<div className="w-full px-24 md:px-32 pb-24 mt-20">
			 <ThemeForm />
		</div>
    );
}
