import React, { useState } from "react";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { useSpring, animated } from "react-spring";

const NumberPicker = ({ onChange }) => {
  const [currentNumber, setCurrentNumber] = useState(3);
  const min = 3; // Set your minimum value here
  const max = 100; // Set your maximum value here

  const increment = () => {
    if (currentNumber < max) {
      setCurrentNumber(currentNumber + 1);
      onChange(currentNumber + 1);
    }
  };

  const decrement = () => {
    if (currentNumber > min) {
      setCurrentNumber(currentNumber - 1);
      onChange(currentNumber - 1);
    }
  };

  const props = useSpring({
    number: currentNumber,
    from: { number: currentNumber },
    config: { duration: 200 },
  });

  return (
    <div className="flex flex-col items-center justify-center p-4">
      <div className="w-full max-w-md p-4">
        <label
          htmlFor="text-input"
          className="items-start block text-lg font-medium text-black mb-2"
        >
          Max Sections
        </label>
        <label
          htmlFor="text-input"
          className="block text-md font-regular text-black mb-2"
        >
          How many sections do you want to write?
        </label>
        <div className="flex mt-8 w-fit h-fit items-center bg-base-purple rounded-3xl shadow-lg px-8 py-4">
          <button
            className="text-3xl text-white cursor-pointer focus:outline-none"
            onClick={decrement}
          >
            <ArrowLeftIcon fontSize="large" />
          </button>
          <animated.span className="mx-8 text-3xl text-white font-bold">
            {props.number.interpolate((n) => n.toFixed(0))}
          </animated.span>
          <button
            className="text-3xl text-white cursor-pointer focus:outline-none"
            onClick={increment}
          >
            <ArrowRightIcon fontSize="large" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default NumberPicker;
