/**
 * Card Type
 */
const CardType = {
	id: '',
	boardId: '',
	listId: '',
	title: '',
	description: '',
	labels: [],
	dueDate: 0,
	attachmentCoverId: '',
	memberIds: [],
	attachments: [],
	subscribed: false,
	checklists: [],
	activities: {}
  };
  
  /**
   * Cards Type
   */
  const CardsType = [];
  