import {
  Avatar,
  Box,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListSubheader,
  Typography,
} from "@mui/material";
import { PlayIcon } from "lucide-react";
import { getRandomPoster } from "src/app/utils/album";

export function Tracks() {
  return (
    <Box className="mt-4">
      <Typography className="text-main-text-color font-bold">Tracks</Typography>
      <List dense className="mt-4 bg-main-bg text-main-text-color">
        {[...new Array(10)].map((_, i) => (
          <ListItem
            key={i}
            className="pl-0"
            secondaryAction={
              <IconButton edge="end" aria-label="delete">
                <PlayIcon className="w-16 h-16 text-main-text-color" />
              </IconButton>
            }
          >
            <ListItemAvatar>
              <Avatar src={getRandomPoster()}></Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={"The Impact of Trump's Reelection on Global Politics"}
            />
          </ListItem>
        ))}
      </List>
    </Box>
  );
}
