// Import the uniqueId function from lodash library
const { uniqueId } = require('lodash');

/**
 * The board model.
 */
function BoardModel(data) {
  data = data || {};

  return {
    id: uniqueId(), // Generate a unique ID
    title: data.title || 'Untitled Board',
    description: data.description || '',
    icon: data.icon || 'heroicons-outline:template',
    lastActivity: data.lastActivity || new Date(),
    members: data.members || [],
    settings: {
      subscribed: (data.settings && data.settings.subscribed) || true,
      cardCoverImages: (data.settings && data.settings.cardCoverImages) || true,
    },
    lists: data.lists || [],
    labels: data.labels || [],
  };
}

module.exports = BoardModel;
