

import FusePageSimple from '@fuse/core/FusePageSimple';
import KanbanAppHeader from './ScrumboardHeader';
import Board from './board/Board';
import { getMembers } from './store/membersSlice';
import { useEffect } from 'react';
import { useAppDispatch } from 'app/store/index';
import { Outlet } from 'react-router-dom';
import BoardList from './board/board-list/BoardList';
import withReducer from 'app/store/withReducer';
import reducer from './store';



 const ScrumboardApp = () => {
	const dispatch = useAppDispatch();

	useEffect(() => {
		dispatch(getMembers());
	}, [dispatch]);

	return <Outlet />;

	// return (
	// 	<FusePageSimple
	// 		header={<KanbanAppHeader />}
	// 		content={
	// 			<div className="flex flex-col w-full px-28 sm:px-16 items-center my-48">
	// 				<BoardList/>
	// 			</div>
	// 		}
	// 	/>
	// );
}
export default withReducer('scrumboardApp', reducer)(ScrumboardApp);

