import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { useEffect } from 'react';
import JwtService from '../../auth/services/jwtService';
import { useSelector } from 'react-redux';
import { selectUser } from 'app/store/userSlice';


function SignOutPage() {
  const user = useSelector(selectUser);
  useEffect(() => {
    setTimeout(() => {
      JwtService.logout(user.data.email);
    }, 1000);
  }, []);

  return (
    <div className="flex flex-col flex-auto items-center sm:justify-center min-w-0">
      <Paper className="flex items-center w-full sm:w-auto min-h-full sm:min-h-auto rounded-0 py-32 px-16 sm:p-48 sm:rounded-2xl sm:shadow">
        <div className="w-full max-w-320 sm:w-320 mx-auto sm:mx-0">
          <div className="flex mb-24">
            <img className="logo-icon w-28" src="assets/images/logo/logo.png" alt="logo" />
            <span className="logo-heading">Podyc</span>
          </div>
          <Typography className="mt-32 text-4xl font-extrabold tracking-tight leading-tight text-center">
            You have signed out!
          </Typography>
        </div>
      </Paper>
    </div>
  );
}

export default SignOutPage;
