import React, { useState } from "react";
import { Button, DialogActions, CircularProgress } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CustomSwitch from "app/shared-components/toggle-switch/CustomSwitch";
import "./upload.css";
import BaseDialog from "app/shared-components/dialog/base-dialog";

export default function UrlUploadInput({
  open,
  handleClose,
  handleSubmit,
  loading,
  successMessage,
  failureMessage,
  setSuccessMessage,
  setFailureMessage,
  hideSwitch = false,
  onBackClick,
}) {
  const [link, setLink] = useState("");
  const [type, setType] = useState(hideSwitch ? "youtube" : "single");
  const [valid, setValid] = useState(true);
  const validateUrl = (url) => {
    var parsedUrl;
    try {
      parsedUrl = new URL(url);
      if (
        hideSwitch &&
        !(
          parsedUrl.hostname.endsWith("youtube.com") ||
          parsedUrl.hostname.endsWith("youtu.be")
        )
      ) {
        return false;
      }
    } catch (_) {
      return false;
    }
    return true;
  };

  const handleInputChange = (event) => {
    const inputValue = event.target.value;

    setValid(validateUrl(inputValue.trim()));
    setLink(inputValue.trim());
    if (successMessage != "" || failureMessage != "") {
      setSuccessMessage("");
      setFailureMessage("");
    }
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    if (valid) {
      if (hideSwitch) {
        handleSubmit(link, "single");
      } else {
        handleSubmit(link, type);
      }
      setLink("");
      setType("single");
    }
  };

  const onSelectSwitch = (index) => {
    switch (index) {
      case 1:
        setType("single");
        break;
      case 2:
        setType("scrape");
        break;
    }
  };

  return (
    <BaseDialog
      open={open}
      title={hideSwitch ? "Add Youtube Url" : "Add Url"}
      showBackButton={true}
      handleClose={handleClose}
      handleBack={onBackClick}
    >
      <div className="flex m-24">
        <div className="border border-[#E4E7EC] p-8 flex rounded-lg w-full">
          <input
            type="text"
            value={link}
            onChange={handleInputChange}
            className="mx-6 flex-grow"
            placeholder={
              hideSwitch
                ? "http://www.youtube.com/abcd"
                : "http://url.domain.com"
            }
          />
        </div>
        {!hideSwitch && (
          <div className="ml-20 min-w-max">
            <CustomSwitch
              selectionMode={1}
              option1={"Single"}
              option2={"Scrape"}
              onSelectSwitch={onSelectSwitch}
            />
          </div>
        )}
      </div>
      {!valid && (
        <div className="text-red ml-24">
          {hideSwitch ? "Enter valid youtube URL" : "Enter valid URL"}
        </div>
      )}
      <div className="mt-8 text-[#667085] text-sm m-24">
        {!hideSwitch
          ? "Add any URL as a source. Podyc ingests information from content scraped by the url . Choose single mode to ingest contents found in a single url.  When you use scrape mode, Podyc will extract all the links from the give url and you can curate which links you want to upload to ingest."
          : "Add any public youtube video. Podyc ingests the text scripts in the video and adds it to your knowledgebase. The contents in the page willl NOT be ingested, only the text scripts from the video."}
      </div>
      {successMessage && (
        <div className="text-center m-16 text-green-600 ml-24 text-sm ">
          Check back in a bit. Training new data sources can take upto 10
          minutes depending on the size of the data source
        </div>
      )}

      {successMessage && (
        <div className="text-green-600 text-center mt-20 text-sm font-medium">{successMessage}</div>
      )}
      {failureMessage && (
        <div className="text-red-600 text-center mt-20">{failureMessage}</div>
      )}
      {/* </DialogContent> */}
      <DialogActions className="justify-end px-0 mx-0 mt-20">
        {loading ? (
          <CircularProgress thickness={5} size={30} color="secondary" className="mr-24"/>
        ) : (
          <Button
            disabled={link.length === 0 || !valid}
            className="press-button shine-button bg-black hover:bg-base-purple min-h-42 max-w-200 max-h-42 mr-24 mb-16"
            variant="outlined"
            onClick={handleFormSubmit}
          >
            Submit
          </Button>
        )}
      </DialogActions>
    </BaseDialog>
  );
}
