import React, { useState } from 'react';
import SettingsTextField from '../../../../shared-components/forms/settingsTextField';
import { validatePhoneNumber } from 'src/app/utils/validations';
import { Button } from '@mui/material';
import PropTypes from 'prop-types';
import aesEncrypt from '../../../../utils/encryptions';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import LoadingText from '../LoadingText';
import { showMessage } from 'app/store/fuse/messageSlice';

export default function WhatsappIntegration(props) {
	const formData = {
		phoneNumber: {
			id: 'phoneNumber',
			validate: (value) => {
				return validatePhoneNumber(value);
			},
			title: 'Phone number',
			icon: 'assets/images/base_url_icon.png',
			placeholder: '9800010000',
			description:
				'Enter the phone number linked to your whatsapp busiess account here. Ensure that you have verified your business on whatsapp before entering the phone number here.',
		},
		phoneNumberID: {
			id: 'phoneNumberID',
			validate: (value) => {
				return value.length > 3;
			},
			title: 'Phone number ID',
			icon: 'assets/images/whatsapp_no_id.png',
			description:
				'Enter ID of your phone number which can be found in your whatsapp business account settings. If you are having trouble finding this, our team can help you with this, just reach out!',
			placeholder: 'Your phone number ID',
		},
		whatsappAPIToken: {
			id: 'whatsappAPIToken',
			validate: (value) => {
				return value.length > 3;
			},
			title: 'Whatsapp Permanent Token',
			icon: 'assets/images/whatsapp_token.png',
			description:
				'Enter your permanent whatsapp API token here. This can be got from your facebook developer account. If you are having trouble finding this, our team can help you with this, just reach out!',
			placeholder: 'Your whatsapp API token',
		},
	};

	const [isValidPhoneNumber, setIsValidPhoneNumber] = useState(false);
	const [isWhatsappAPITokenValid, setIsWhatsappAPITokenValid] =
		useState(false);
	const [isPhoneNumberIDValid, setIsPhoneNumberIDValid] = useState(false);
	const [phoneNumber, setPhoneNumber] = useState('');
	const [whatsappAPIToken, setWhatsappAPIToken] = useState('');
	const [phoneNumberID, setPhoneNumberID] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const dispatch = useDispatch();

	useState(() => {
		if (props.data.attributes && props.data.attributes.phone_number) {
			setPhoneNumber(props.data.attributes.phone_number);
			setIsValidPhoneNumber(true);
		}
		if (props.data.attributes && props.data.attributes.phone_number_id) {
			setPhoneNumberID(props.data.attributes.phone_number_id);
			setIsPhoneNumberIDValid(true);
		}
		if (props.data.attributes && props.data.attributes.access_token) {
			setWhatsappAPIToken(props.data.attributes.access_token);
			setIsWhatsappAPITokenValid(true);
		}
	});

	const handleFormSubmit = () => {
		if (
			isValidPhoneNumber &&
			isPhoneNumberIDValid &&
			isWhatsappAPITokenValid
		) {
			const encryptedValue = aesEncrypt(
				whatsappAPIToken,
				process.env.REACT_APP_ENCRYPTION_KEY
			);

			setIsLoading(true);
			if (!isLoading) {
				handleWhatsappIntegrationData(
					phoneNumber,
					phoneNumberID,
					encryptedValue
				);
			}
		}
	};
	const updateWhatsappIntegrationData = async (
		id,
		phoneNumber,
		phoneID,
		whatsappToken
	) => {
		try {
			const response = await axios.put(
				`${process.env.REACT_APP_AUTH_BASE_URL}/api/whatsapp-integration-models/${id}`,
				{
					data: {
						phone_number: phoneNumber,
						phone_number_id: phoneID,
						access_token: whatsappToken,
						knowledgebase: props.kb_id,
					},
				}
			);
			return response;
		} catch (e) {
			throw e;
		}
	};
	const AddWhatsappIntegrationData = async (
		phoneNumber,
		phoneID,
		whatsappToken
	) => {
		try {
			const response = await axios.post(
				`${process.env.REACT_APP_AUTH_BASE_URL}/api/whatsapp-integration-models`,
				{
					data: {
						phone_number: phoneNumber,
						phone_number_id: phoneID,
						access_token: whatsappToken,
						knowledgebase: props.kb_id,
					},
				}
			);
			return response;
		} catch (e) {
			throw e;
		}
	};

	const handleWhatsappIntegrationData = async (
		phoneNumber,
		phoneID,
		whatsappToken
	) => {
		try {
			var response;
			if (props.data?.id != null) {
				response = await updateWhatsappIntegrationData(
					props.data?.id,
					phoneNumber,
					phoneID,
					whatsappToken
				);
			} else {
				response = await AddWhatsappIntegrationData(
					phoneNumber,
					phoneID,
					whatsappToken
				);
			}

			if (
				response.data.data.attributes.access_token &&
				response.data.data.attributes.phone_number_id &&
				response.data.data.attributes.phone_number
			) {
				setPhoneNumber(response.data.data.attributes.phone_number);
				setPhoneNumberID(response.data.data.attributes.phone_number_id);
				setWhatsappAPIToken(response.data.data.attributes.access_token);

				setIsWhatsappAPITokenValid(true);
				setIsPhoneNumberIDValid(true);
				setIsValidPhoneNumber(true);

				setIsLoading(false);
				dispatch(
					showMessage({
						message: 'Your whatsapp integration has been added',
					})
				);
			} else {
				throw new Error('Could not add your whatsapp integration');
			}
		} catch (e) {
			setIsLoading(false);
			setPhoneNumber('');
			setPhoneNumberID('');
			setWhatsappAPIToken('');

			setIsWhatsappAPITokenValid(false);
			setIsPhoneNumberIDValid(false);
			setIsValidPhoneNumber(false);

			setIsLoading(false);
			dispatch(
				showMessage({
					message:
						'Failure. Your whatsapp integration could not be added. Check your details or reachc out to our team',
				})
			);
		} finally {
		}
	};

	return (
		<div className="settigs-grid-container">
			<div className="page-heading">Whatsapp Integration</div>
			<span className="w-full mt-16 mb-32 p-0.5 bg-grey-300 lg:w-1/3"></span>
			{isLoading ? (
				<LoadingText className />
			) : (
				<div className="grid  w-full grid-cols-1 gap-y-32 ">
					<div className="settings-grid-item">
						<SettingsTextField
							finalValue={isValidPhoneNumber ? phoneNumber : ''}
							id={formData.phoneNumber.id}
							icon={formData.phoneNumber.icon}
							title={formData.phoneNumber.title}
							description={formData.phoneNumber.description}
							placeholder={formData.phoneNumber.placeholder}
							validate={formData.phoneNumber.validate}
							onChange={(id, value, isValid) => {
								setPhoneNumber(value);
								setIsValidPhoneNumber(isValid);
							}}
						/>
					</div>
					<div className="grid-item">
						<SettingsTextField
							finalValue={
								isPhoneNumberIDValid ? phoneNumberID : ''
							}
							id={formData.phoneNumberID.id}
							icon={formData.phoneNumberID.icon}
							title={formData.phoneNumberID.title}
							description={formData.phoneNumberID.description}
							placeholder={formData.phoneNumberID.placeholder}
							validate={formData.phoneNumberID.validate}
							onChange={(id, value, isValid) => {
								setPhoneNumberID(value);
								setIsPhoneNumberIDValid(isValid);
							}}
						/>
					</div>
					<div className="grid-item">
						<SettingsTextField
							passwordMode={true}
							finalValue={
								isWhatsappAPITokenValid ? whatsappAPIToken : ''
							}
							id={formData.whatsappAPIToken.id}
							icon={formData.whatsappAPIToken.icon}
							title={formData.whatsappAPIToken.title}
							textFielldTip={
								'Once you add your token here. You will not be able to see it again. \
                Save this token safely or retrieve it from your meta dashboard if you want to modify it'
							}
							description={formData.whatsappAPIToken.description}
							placeholder={formData.whatsappAPIToken.placeholder}
							validate={formData.whatsappAPIToken.validate}
							onChange={(id, value, isValid) => {
								setWhatsappAPIToken(value);
								setIsWhatsappAPITokenValid(isValid);
							}}
						/>
					</div>
					<Button
						disabled={
							isLoading ||
							!isValidPhoneNumber ||
							!isPhoneNumberIDValid ||
							!isWhatsappAPITokenValid
						}
						onClick={() => {
							handleFormSubmit();
						}}
						variant="contained"
						color="secondary"
						className="press-button shine-button w-96 h-40 mt-16 rounded-md bg-primary hover:bg-primary text-white"
						aria-label="Save"
						// disabled={_.isEmpty(dirtyFields) || !isValid}
						type="submit"
						size="large"
					>
						Save
					</Button>
				</div>
			)}
		</div>
	);
}

WhatsappIntegration.propTypes = {
	data: PropTypes.any,
	kb_id: PropTypes.number,
};
