// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.switch-container{
	height: '44px';
	background-color: #F9FAFB;
	border: 1px solid #D0D5DD;
	border-radius: 4px;
	display: 'flex';
	justify-content: 'center';
	padding: 5px;
}
`, "",{"version":3,"sources":["webpack://./src/app/shared-components/toggle-switch/switch.css"],"names":[],"mappings":"AAAA;CACC,cAAc;CACd,yBAAyB;CACzB,yBAAyB;CACzB,kBAAkB;CAClB,eAAe;CACf,yBAAyB;CACzB,YAAY;AACb","sourcesContent":[".switch-container{\n\theight: '44px';\n\tbackground-color: #F9FAFB;\n\tborder: 1px solid #D0D5DD;\n\tborder-radius: 4px;\n\tdisplay: 'flex';\n\tjustify-content: 'center';\n\tpadding: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
