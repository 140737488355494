import NavLinkAdapter from "@fuse/core/NavLinkAdapter";
import { alpha, styled } from "@mui/material/styles";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useMemo } from "react";
import FuseNavBadge from "../../FuseNavBadge";
import MySvgIcon from "../../../FuseSvgIcon/SvgIcon";
import { Image } from "@mui/icons-material";
import { ReactSVG } from "react-svg";
import { SvgIcon } from "@mui/material";
import { gradientDark } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { border, borderRight } from "@mui/system";

const Root = styled(ListItem)(({ theme, ...props }) => ({
  minHeight: 44,
  width: "100%",
  fontSize: "16px",
  borderRadius: "10px",
  margin: "0 0 4px 0",
  paddingRight: 16,
  paddingLeft: props.itempadding > 80 ? 80 : props.itempadding,
  paddingTop: 10,
  paddingBottom: 10,
  color: "#808080",
  cursor: "pointer",
  textDecoration: "none!important",
  "&:hover": {
    color: "#66eebb",
  },
  "&.active": {
    backgroundColor:
      theme.palette.mode === "light"
        ? "#66eebb !important"
        : "rgba(255, 255, 255, .1)!important",
    pointerEvents: "none",
    transition: "border-radius .15s cubic-bezier(0.4,0.0,0.2,1)",
    "& > .fuse-list-item-text-primary": {
      color: "#66eebb",
    },

    "& > .fuse-list-item-text": {
      color: "#121212",
      fontWeight: "400",
    },
  },
}));

function FuseNavVerticalItem(props) {
  const { item, nestedLevel, onItemClick } = props;

  const itempadding = nestedLevel > 0 ? 38 + nestedLevel * 16 : 16;

  return useMemo(
    () => (
      <Root
        button
        component={NavLinkAdapter}
        to={item.url || ""}
        activeClassName={item.url ? "active text-black" : ""}
        className={clsx("fuse-list-item ", item.active && "active")}
        onClick={() => onItemClick && onItemClick(item)}
        end={item.end}
        itempadding={itempadding}
        role="button"
        sx={item.sx}
        disabled={item.disabled}
      >
        <div className="mr-8 ">{item.icon}</div>

        <ListItemText
          className="fuse-list-item-text"
          primary={item.title}
          secondary={item.subtitle}
          classes={{
            primary:
              "text-14 font-regular sfuse-list-item-text-primary truncate",
            secondary:
              "text-12 font-medium fuse-list-item-text-secondary leading-normal truncate",
          }}
        />
        {item.badge && <FuseNavBadge badge={item.badge} />}
      </Root>
    ),
    [item, itempadding, onItemClick]
  );
}

FuseNavVerticalItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string,
    icon: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    url: PropTypes.string,
  }),
};

FuseNavVerticalItem.defaultProps = {};

const NavVerticalItem = FuseNavVerticalItem;

export default NavVerticalItem;
