import {
  selectIsPlaying,
  setIsPlaying,
  setSelectedFileResult,
} from "app/store/fileResultSlice";
import { useDispatch } from "react-redux";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import { useSelector } from "react-redux";
import { selectPlayerView } from "app/store/playerSlice";
export function PlayTrack({ track }) {
  const dispatch = useDispatch();
  const isPlaying = useSelector(selectIsPlaying);

  const handlePlayPause = () => {
    dispatch(setSelectedFileResult(track));
    dispatch(setIsPlaying(!isPlaying));
  };

  return (
    <button
      onClick={handlePlayPause}
      className="p-4 rounded-full bg-primary hover:bg-primary-600 transition-colors"
    >
      {isPlaying ? (
        <PauseIcon className="w-20 h-20 text-white" />
      ) : (
        <PlayArrowIcon className="w-20 h-20 text-white" />
      )}
    </button>
  );
}
