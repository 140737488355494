import CircularProgress from '@mui/material/CircularProgress';

function LoadingText() {
  return (
    <div className="text-gray-500 text-md flex flex-row justify-center items-center">
      <CircularProgress color="secondary" />
      <div className="flex">Loading...</div>
    </div>
  );
}

export default LoadingText;
