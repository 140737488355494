import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import _ from 'lodash';
import createAppAsyncThunk from 'app/store/createAppAsyncThunk';
import { removeList } from './listsSlice';
import { removeCard, updateCard } from './cardSlice';


const cardsAdapter = createEntityAdapter({});

export const getCards = createAppAsyncThunk(
	'scrumboardApp/cards/getCards',
	async (boardId) => {
		try {
			const response = await axios.get(
				`${process.env.REACT_APP_AUTH_BASE_URL}/api/scrumboard-cards?filters[scrumboard][id][$eq]=${boardId}&populate[0]=members_ids,memberIds,activities,listId,labels,contact_booking`
			);
			const data = response.data.data;
			return data;
		} catch (e) {
			throw e;
		}
	}
);

export const newCard = createAppAsyncThunk(
	'scrumboardApp/cards/newCard',
	async ({ listId, newData }, { getState }) => {
		const AppState = getState();
		const board = AppState.scrumboardApp.board.data;
		try {
			const response = await axios.post(
				`${process.env.REACT_APP_AUTH_BASE_URL}/api/scrumboard-cards?populate[0]=members_ids,memberIds,activities,listId,labels,contact_booking`,
				{
					data: { listId: listId, scrumboard: board.id, ...newData },
				}
			);
			const data = response.data.data;

			return data;
		} catch (e) {
			console.log(e);
		}
	}
);

export const { selectAll: selectCards, selectById } = cardsAdapter.getSelectors(
	(state) => state.scrumboardApp?.cards
);

export const cardsSlice = createSlice({
	name: 'scrumboardApp/cards',
	initialState: cardsAdapter.getInitialState({}),
	reducers: {
		resetCards: () => {},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getCards.fulfilled, (state, action) =>
				cardsAdapter.setAll(state, action.payload)
			)
			// .addCase(removeList.fulfilled, (state, action) => {
			// 	const listId = action.payload;
			// 	const cards = selectCards(state);
			// 	const removedCardIds = _.map(_.filter(cards, { listId }), 'id');
			// 	return cardsAdapter.removeMany(state, removedCardIds);
			// })
			.addCase(newCard.fulfilled, (state, action) =>
				cardsAdapter.addOne(state, action.payload)
			)
			.addCase(updateCard.fulfilled, (state, action) =>
				cardsAdapter.setOne(state, action.payload)
			)
			.addCase(removeCard.fulfilled, (state, action) =>
				cardsAdapter.removeOne(state, action.payload)
			);
	},
});

export const { resetCards } = cardsSlice.actions;

export const selectCardById = (id) => (state) => selectById(state, id);

export default cardsSlice.reducer;
