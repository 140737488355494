import React, { useState, useEffect, useRef } from 'react';
import { SketchPicker } from 'react-color';

const ColorPickerField = ({col , onChange }) => {
  const [showPicker, setShowPicker] = useState(false);
  const pickerRef = useRef();
  const [color, setColor] = useState(col);

  const handleColorChange = (color) => {
    setColor(color.hex);
    onChange(color.hex);

  };

  const handleClickOutside = (event) => {
    if (pickerRef.current && !pickerRef.current.contains(event.target)) {
      setShowPicker(false);
    }
  };

  useEffect(() => {
    setColor(col);
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="relative bg-[#F0F0FE] rounded-md shadow-md cursor-pointer w-128 my-16 p-16">
      <div className="flex items-center space-x-2" onClick={() => setShowPicker(!showPicker)}>
        <div
          className="w-16 h-16 rounded-full"
          style={{ backgroundColor: color }}
        />
        <div className='text-bold pl-8'>{color.toUpperCase()}</div>
      </div>
      {showPicker && (
        <div ref={pickerRef} className="absolute z-10 mt-2">
          <SketchPicker color={color} onChangeComplete={handleColorChange} />
        </div>
      )}
    </div>
  );
};

export default ColorPickerField;
