import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import FuseSvgIcon from "@fuse/core/FuseSvgIcon";

const getSourceWidget = (type) => {
  switch (type.toLowerCase()) {
    case "url":
      return (
        <div className="flex flex-row">
          <img
            height={18}
            width={18}
            src="assets/images/icons/web-url-circle.png"
            alt="web"
          />
          <div className="ml-5">URL</div>
        </div>
      );
    case "document":
      return (
        <div className="flex flex-row">
          <img
            height={18}
            width={18}
            src="assets/images/icons/document-circle.png"
            alt="web"
          />
          <div className="ml-5">Document</div>
        </div>
      );
    case "youtube":
      return (
        <div className="flex flex-row">
          <img
            height={18}
            width={18}
            src="assets/images/icons/youtube-circle.png"
            alt="web"
          />
          <div className="ml-5">YouTube</div>
        </div>
      );
    case "sourcecode":
      return (
        <div className="flex flex-row">
          <img
            height={18}
            width={18}
            src="assets/images/icons/web-url-circle.png"
            alt="web"
          />
          <div className="ml-5">Source code</div>
        </div>
      );
  }
};

export default function KBSourceDataGrid({
  data,
  handlePageChange,
  handleNextPage,
  handlePreviousPage,
  kbloading,
  currentPage,
  pageSize,
}) {
  const estimatedRowHeight = 80; // Adjust based on your row content
  const gridHeaderHeight = 56; // Adjust if your header height is different
  const paginationControlHeight = 52; // Adjust based on your pagination control height

  // Calculate total grid height dynamically
  const totalGridHeight =
    data.length * estimatedRowHeight +
    gridHeaderHeight +
    paginationControlHeight;

  const columns = [
    { field: "serialNo", headerName: "SR.No.", width: 100 },
    {
      field: "source_type",
      headerName: "TYPE",
      width: 150,
      renderCell: (params) => {
        return getSourceWidget(params.row.source_type);
      },
    },
    {
      field: "source_name",
      headerName: "SOURCE",
      width: 280,
      cellClassName: (params) => {
        return "!whitespace-normal";
      },
      renderCell: (params) => {
        if (params.row.source_type == "document") {
          const pathArray = params.row.source_name.split("/");
          return pathArray[pathArray.length - 1];
        } else {
          return (
            <div
              title={params.row.source_name}
              className="py-10"
              style={{ overflowWrap: "anywhere" }}
            >
              {" "}
              {params.row.source_name}
            </div>
          );
        }
      },
    },

    {
      field: "status",
      headerName: "Status",
      width: 150,
      cellClassName: (params) => {
        const status = params.value;
        return `pill ${status}`;
      },
      cellRenderer: (params) => {
        const status = params.value;
        return status;
      },
    },
  ];

  return (
    <div
      className="flex h-full w-full"
      style={{ height: `${totalGridHeight}px`, width: "100%" }}
    >
      <DataGrid
        rows={data.map((row, index) => ({
          ...row,
          serialNo: index + 1 + pageSize * (currentPage - 1),
        }))}
        columns={columns}
        pagination
        loading={kbloading}
        pageSize={pageSize}
        rowCount={pageSize}
        getRowHeight={() => "auto"}
        onPageChange={handlePageChange}
        disableColumnMenu
        disableColumnSelector
        disableRowSelector
        components={{
          Pagination: () => (
            <div className="flex">
              <button onClick={handlePreviousPage} disabled={currentPage === 1}>
                <FuseSvgIcon className="text-48" size={24} color="action">
                  material-solid:chevron_left
                </FuseSvgIcon>
              </button>
              <span className="p-10">Page {currentPage}</span>
              <button
                disabled={data.length < pageSize || kbloading}
                onClick={handleNextPage}
              >
                <FuseSvgIcon className="text-48" size={24} color="action">
                  material-solid:chevron_right
                </FuseSvgIcon>
              </button>
            </div>
          ),
        }}
      />
    </div>
  );
}
