/**
 * List Type
 */
const ListType = {
	id: '',
	boardId: '',
	title: '',
  };
  
  /**
   * Lists Type
   */
  const ListsType = []; // Array of ListType objects
  