import React from 'react';
import * as PropTypes from 'prop-types';
import { Divider, Stack } from '@mui/material';
import './ai-agent-card.css';
/**
 * React component for AI agent long card.
 *
 * @param {object} props - The properties passed to the component.
 * @param {string} props.title - The title of the AI agent.
 * @param {string} props.description - The description of the AI agent.
 * @param {boolean} props.isActive - Indicates whether the AI agent is active.
 * @param {number} props.details - The details of the AI agent.
 * @param {number} props.speed - The speed of the AI agent.
 * @param {number} props.cost - The cost of the AI agent.
 * @param {function} props.onSelect - The function to be called when the AI agent is selected.
 * @returns {JSX.Element} The AI agent card component.
 */
export default function AiAgentCardLong(props) {
	/**
	 * Handles the button click event.
	 */
	const handleButtonClick = () => {
		if (!props.isActive && props.onSelect) {
			props.onSelect();
		}
	};
	return (
		<div
			className={`${
				props.isActive ? 'active-task-card-long' : 'task-card-long'
			} px-20 pt-10 pb-20 mb-5`}
		>
			<div className="text-3xl">{props.title}</div>
			<div
				id="description"
				className="text-sm mt-8"
			>
				{props.description}
			</div>
			<Stack
				direction="row"
				className="max-w-fit rounded-lg bg-white space-between place-content-evenly p-5 mt-14"
				divider={
					<Divider
						orientation="vertical"
						flexItem
					/>
				}
				spacing={2}
			>
				<div className="flex flex-row">
					<span className="text-sm">Details {props.details}</span>
					<img
						className="self-start"
						height={'14px'}
						width={'14px'}
						src="assets/images/icons/flash.png"
					/>
				</div>

				<div className="flex flex-row">
					<span className="text-sm">Speed {props.speed}</span>
					<img
						className="self-start"
						height={'14px'}
						width={'14px'}
						src="assets/images/icons/flash.png"
					/>
				</div>
				<div className="flex flex-row">
					<span className="text-sm">Cost {props.cost}</span>
					<img
						className="self-start"
						height={'14px'}
						width={'14px'}
						src="assets/images/icons/flash.png"
					/>
				</div>
			</Stack>
			<div
				className={`${
					props.isActive
						? 'active-button-top-right'
						: 'select-button-top-right'
				}`}
				onClick={(e) => {
					handleButtonClick();
				}}
			>
				{props.isActive ? 'Active' : 'Select'}
			</div>
		</div>
	);
}
AiAgentCardLong.propTypes = {
	title: PropTypes.string,
	description: PropTypes.string,
	isActive: PropTypes.bool,
	details: PropTypes.number,
	speed: PropTypes.number,
	cost: PropTypes.number,
	onSelect: PropTypes.func,
};

AiAgentCardLong.defaultProps = {};
