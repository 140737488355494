import React, { useState } from 'react';
import SettingsTextField from '../../../../shared-components/forms/settingsTextField';
import { validateUrl } from 'src/app/utils/validations';
import aesEncrypt from '../../../../utils/encryptions';
import { Button } from '@mui/material';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { showMessage } from 'app/store/fuse/messageSlice';
import axios from 'axios';
import LoadingText from '../LoadingText';
export default function ShopifyIntegration(props) {
	const formData = {
		shopifyBaseUrl: {
			id: 'shopifyBaseUrl',
			validate: (value) => {
				return validateUrl(value);
			},
			title: 'Shopify Store Base URL',
			icon: 'assets/images/base_url_icon.png',
			description: 'Enter the base url of your shopify store here.',
			placeholder: 'https://example.myshopify.com',
		},
		shopifyStoreFrontToken: {
			id: 'shopifyStoreFrontToken',
			validate: (value) => {
				return value.length > 3;
			},
			title: 'Shopify Store Front API Token',
			icon: 'assets/images/shopify_token.png',
			description:
				'Enter the shopify store front api token here. This can be found in your shopify store dashboard. If you are finding it difficult enabling the sore front api, our team can help you with this, just reach out!',
			placeholder: 'Enter your shopify store front api token here',
		},
	};

	const [isBaseUrlValid, setIsBaseUrlValid] = useState(false);
	const [isStoreFrontTokenValid, setIsStoreFrontTokenValid] = useState(false);
	const [baseUrl, setBaseUrl] = useState('');
	const [storeFrontToken, setStoreFrontToken] = useState('');
	const [isLoading, setIsLoading] = useState(false);

	const dispatch = useDispatch();

	useState(() => {
		if (props.data.attributes && props.data.attributes.url) {
			setBaseUrl(props.data.attributes.url);
			setIsBaseUrlValid(true);
		}
		if (
			props.data.attributes &&
			props.data.attributes.store_front_api_key
		) {
			setStoreFrontToken(props.data.attributes.store_front_api_key);
			setIsStoreFrontTokenValid(true);
		}
	});

	const updateStoreFrontToken = (value) => {
		setStoreFrontToken(value);
	};

	const handleFormSubmit = () => {
		if (isBaseUrlValid && isStoreFrontTokenValid) {
			const encryptedValue = aesEncrypt(
				storeFrontToken,
				process.env.REACT_APP_ENCRYPTION_KEY
			);
			if (!isLoading)
				handleShopifyTokenDataUpdate(baseUrl, encryptedValue);
		}
	};
	const AddShopifyTokenData = async (baseUrl, token) => {
		try {
			const response = await axios.post(
				`${process.env.REACT_APP_AUTH_BASE_URL}/api/shopify-models`,
				{
					data: {
						url: baseUrl,
						store_front_api_key: token,
						knowledgebase: props.kb_id,
					},
				}
			);
			return response;
		} catch (e) {
			throw e;
		}
	};
	const UpdateShopifyTokenData = async (id, baseUrl, token) => {
		try {
			const response = await axios.put(
				`${process.env.REACT_APP_AUTH_BASE_URL}/api/shopify-models/${id}`,
				{
					data: {
						url: baseUrl,
						store_front_api_key: token,
						knowledgebase: props.kb_id,
					},
				}
			);
			return response;
		} catch (e) {
			throw e;
		}
	};

	const handleShopifyTokenDataUpdate = async (baseUrl, token) => {
		setIsLoading(true);
		var response;
		try {
			if (props.data?.id != null) {
				response = await UpdateShopifyTokenData(
					props.data?.id,
					baseUrl,
					token
				);
			} else {
				response = AddShopifyTokenData(baseUrl, token);
			}
			const response = await axios.post(
				`${process.env.REACT_APP_AUTH_BASE_URL}/api/shopify-models`,
				{
					data: {
						url: baseUrl,
						store_front_api_key: token,
						knowledgebase: props.kb_id,
					},
				}
			);
			if (
				response.data.data.attributes.store_front_api_key &&
				response.data.data.attributes.url
			) {
				setStoreFrontToken(
					response.data.data.attributes.store_front_api_key
				);
				setBaseUrl(response.data.data.attributes.url);
				setIsStoreFrontTokenValid(true);
				setIsBaseUrlValid(true);
				dispatch(
					showMessage({
						message: 'Your Shopify Integration has been added',
					})
				);
				setIsLoading(false);
			} else {
				setIsLoading(false);
				throw new Error('Could not add your shopify integration');
			}
		} catch (e) {
			setStoreFrontToken('');
			setBaseUrl('');
			setIsStoreFrontTokenValid(false);
			setIsBaseUrlValid(false);
			dispatch(
				showMessage({
					message: 'Failure. Could not add your shopify integration',
				})
			);
			setIsLoading(false);
		} finally {
		}
	};

	return (
		<div className="settigs-grid-container">
			<div className="page-heading">Shopify Integration</div>
			<span className="w-full mt-16 mb-32 p-0.5 bg-grey-300 lg:w-1/3"></span>
			{isLoading ? (
				<LoadingText className />
			) : (
				<div className="grid  w-full grid-cols-1 gap-y-32 ">
					<div className="settings-grid-item">
						<SettingsTextField
							id={formData.shopifyBaseUrl.id}
							icon={formData.shopifyBaseUrl.icon}
							title={formData.shopifyBaseUrl.title}
							description={formData.shopifyBaseUrl.description}
							placeholder={formData.shopifyBaseUrl.placeholder}
							finalValue={isBaseUrlValid ? baseUrl : ''}
							validate={formData.shopifyBaseUrl.validate}
							onChange={(id, value, isValid) => {
								setBaseUrl(value);
								setIsBaseUrlValid(isValid);
							}}
						/>
					</div>
					<div className="grid-item">
						<SettingsTextField
							id={formData.shopifyStoreFrontToken.id}
							finalValue={
								isStoreFrontTokenValid ? storeFrontToken : ''
							}
							passwordMode={true}
							icon={formData.shopifyStoreFrontToken.icon}
							title={formData.shopifyStoreFrontToken.title}
							description={
								formData.shopifyStoreFrontToken.description
							}
							placeholder={
								formData.shopifyStoreFrontToken.placeholder
							}
							textFielldTip={
								'Once you add your token here. You will not be able to see it again. \
                Save this token safely or retrieve it from the shopify dashboard if you want to modify it'
							}
							validate={formData.shopifyStoreFrontToken.validate}
							onChange={(id, value, isValid) => {
								setIsStoreFrontTokenValid(isValid);
								updateStoreFrontToken(value);
							}}
						/>
					</div>
					<Button
						disabled={
							!isBaseUrlValid ||
							!isStoreFrontTokenValid ||
							isLoading
						}
						onClick={() => {
							handleFormSubmit();
						}}
						variant="contained"
						color="secondary"
						className="press-button shine-button w-96 h-40 mt-16 rounded-md bg-primary hover:bg-primary text-white"
						aria-label="Save"
						// disabled={_.isEmpty(dirtyFields) || !isValid}
						type="submit"
						size="large"
					>
						Save
					</Button>
				</div>
			)}
		</div>
	);
}

ShopifyIntegration.propTypes = {
	data: PropTypes.any,
	kb_id: PropTypes.number,
};
