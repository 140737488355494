import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import lazyWithReducer from 'app/store/lazyWithReducer';
import reducer from './store';
import ScrumboardApp from './ScrumboardApp';
import { Boards } from './boards/Boards';
import Board from './board/Board';

// const KanbanDashboardApp =  lazy(() => import('./KanbanDashboardApp'));
// // lazyWithReducer('kanbanDashboardApp',() => import('./KanbanDashboardApp'),reducer);
// const Board = lazy(() => import('./board/Board'));
// const Boards = lazy(() => import('./boards/Boards'));


const ScrumboardConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    // {
    //   path: 'dashboards/kanban',
    //   element: <KanbanDashboardApp />,
    // },
    {
			path: 'dashboards/kanban',
			element: <ScrumboardApp />,
			children: [
				{
					path: '',
					element: <Navigate to="/dashboards/kanban/boards" />
				},
				{
					path: 'boards',
					element: <Boards />
				},
				{
					path: 'boards/:boardId',
					element: <Board />
				}
			]
		}
  ],
};

export default ScrumboardConfig;
