import React, { useState, useEffect } from "react";
import axios from "axios";
import { formatDateToHumanReadable } from "src/app/utils/date-utils";
import PaginationComponent from "src/app/shared-components/pagination/PaginationComponent";
import StatusPill from "src/app/shared-components/pill/StatusPill";
import { validateUrl } from "src/app/utils/validations";
import Pill from "app/shared-components/pill/pill";
import qs from "qs";
import CTAButon from "app/shared-components/buttons/cta-button";

export function DatasourceTable({
  datastoreId,
  onAddClick,
  onDeleteAllClick,
  onDocumentClick,
  isInitiallyOpen = false,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [datasourceName, setDatasourceName] = useState("Datasource");
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(-1);
  const itemsPerPage = 5;



  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const getDatasource = async (page) => {
    try {
      const query = {
        populate: {
          documents: {
            sort: "createdAt:asc",
            limit: itemsPerPage,
            start: (page - 1) * itemsPerPage,
          },
        },
      };
      const queryToString = qs.stringify(query, { encode: false });
      const datasource = await axios.get(
        `${process.env.REACT_APP_AUTH_BASE_URL}/api/datasources/${datastoreId}?${queryToString}`
      );
      setDatasourceName(datasource.data.data.attributes.name);
      setDocuments((prev) => [
        ...prev,
        ...datasource.data.data.attributes.documents.data,
      ]);
      setLastPage(
        datasource.data.data.attributes.documents.data.length < itemsPerPage
          ? page
          : -1
      );
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getDatasource(1);
  }, [datastoreId]);

  useEffect(() => {
    setIsOpen(isInitiallyOpen);
  }, [isInitiallyOpen]);

  const totalPages =
    lastPage != -1
      ? lastPage
      : documents
      ? Math.ceil(documents.length / itemsPerPage) + 1
      : 1;

  const onPageChange = (page) => {
    setCurrentPage(page);
    if (lastPage != page && documents.length < page * itemsPerPage) {
      getDatasource(page);
    }
  };

  return (
    <div className="m-5 mt-32 border border-gray-300 shadow-lg rounded-lg overflow-hidden">
      <div
        className="bg-white text-black p-4 flex flex-col cursor-pointer"
        onClick={handleToggle}
      >
        <div className="flex justify-between items-center">
          <div className="m-16 flex flex-row">
            <img
              src="assets/images/datasource-icon.png"
              className="w-32 h-32 p-4 bg-[#EBEFF9] rounded-2xl"
            ></img>
            <div className="ml-8 mt-6 font-semibold tex-title-text-color">{datasourceName}</div>
            <div className="mx-16 mt-4">
              <Pill text={"New"} />
            </div>
          </div>

          <div className="flex mr-16 mt-4">
          <div>
            <CTAButon
              text="Add Data"
              image="assets/images/add-icon.svg"
              onPress={onAddClick}
            />
            </div>
            <div className="ml-16">
            <CTAButon
              text="Delete All"
              image="assets/images/delete-icon-white.svg"
              onPress={onDeleteAllClick}
            />
            </div>

            <span
              className={`mb-4 mt-12 ml-32 transform transition-transform duration-300 ${
                isOpen ? "rotate-180" : ""
              }`}
              style={{
                transformOrigin: "center",
                display: "inline-block",
                width: "25px",
                height: "25px",
              }}
            >
              ▼
            </span>
          </div>
        </div>
        <div className="mt-2 w-1/2 ml-16 mb-16 text-base text-body-text-color font-regular">
          A datasource is where you will add your data to be used for training
          your agents. Whenever you add your data, it will be stored here in a
          datasource.
        </div>
      </div>
      <div
        className={`transition-max-height duration-500 ease-in-out ${
          isOpen ? "max-h-screen" : "max-h-0 overflow-hidden"
        }`}
      >
        {isOpen && (
          <>
            {documents && documents.length > 0 ? (
              <>
                <div className="flex bg-[#F9FBFC] p-16 border-grey-300 border-t-1 border-b-1">
                  <div className="w-96 font-semibold p-2 pl-16">SL No</div>
                  <div className="w-1/3 font-semibold p-2">Document Name</div>
                  <div className="w-128 font-semibold p-2">Status</div>
                  <div className="flex-1 font-semibold p-2">Document Type</div>
                  <div className="flex-1 font-semibold p-2">Date Added</div>
                  <div className="flex-1 font-semibold p-2">Tokens Used</div>
                  <div className="flex-1 font-semibold p-2">Actions</div>
                </div>
                {documents
                  .slice(
                    (currentPage - 1) * itemsPerPage,
                    currentPage * itemsPerPage
                  )
                  .map((_, index) => (
                    <div
                      onClick={() => {
                        onDocumentClick(
                          documents[(currentPage - 1) * itemsPerPage + index]
                            .id,
                          documents[(currentPage - 1) * itemsPerPage + index]
                            .attributes.document_name
                        );
                      }}
                      key={index}
                      className="flex p-4 text-md border-b bg-[#faf9fb] border-gray-200 last:border-none hover:bg-white transition-colors hover:cursor-pointer"
                    >
                      <div className="w-96 p-16 px-32 text-grey-900">
                        {(currentPage - 1) * itemsPerPage + index + 1}
                      </div>
                      {validateUrl(
                        documents[(currentPage - 1) * itemsPerPage + index]
                          .attributes.document_name
                      ) && (
                        <div className="w-1/3 p-16  text-start text-grey-900 bg-transparent">
                          <a
                            href={
                              documents[
                                (currentPage - 1) * itemsPerPage + index
                              ].attributes.document_name
                            }
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              color: "blue",
                              textDecoration: "underline",
                              backgroundColor: "transparent",
                              textAlign: "start",
                              justifyContent: "start",
                            }}
                          >
                            {
                              documents[
                                (currentPage - 1) * itemsPerPage + index
                              ].attributes.document_name
                            }
                          </a>
                        </div>
                      )}
                      {!validateUrl(
                        documents[(currentPage - 1) * itemsPerPage + index]
                          .attributes.document_name
                      ) && (
                        <div className="w-1/3 p-16 text-grey-900 ">
                          {
                            documents[(currentPage - 1) * itemsPerPage + index]
                              .attributes.document_name
                          }
                        </div>
                      )}
                      <div className="w-128 py-16">
                        <StatusPill
                          status={
                            documents[(currentPage - 1) * itemsPerPage + index]
                              .attributes.status
                          }
                        />
                      </div>

                      <div className="flex-1 py-16 text-grey-900">
                        {documents[(currentPage - 1) * itemsPerPage + index]
                          .attributes.document_type ?? "url"}
                      </div>
                      <div className="flex-1 py-16 text-grey-900">
                        {formatDateToHumanReadable(
                          documents[(currentPage - 1) * itemsPerPage + index]
                            .attributes.createdAt
                        )}
                      </div>
                      <div className="flex-1 py-16 text-grey-900">
                        {
                          documents[(currentPage - 1) * itemsPerPage + index]
                            .attributes.tokens_used
                        }
                      </div>
                      <div className="flex-1 py-16 text-grey-800">Actions</div>
                    </div>
                  ))}
                <div className="">
                  <PaginationComponent
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={onPageChange}
                  />
                </div>
              </>
            ) : (
              <div className="flex flex-col items-center justify-center p-10">
                <img
                  src="/assets/images/no-data.jpg"
                  alt="No data"
                  className="mb-4 h-[150px]"
                />
                <p className="text-body-text-color mx-64 w-[300px] text-center my-16">
                  Looks like you haven't added any data to this datasource yet.
                  Click the add button to add your data
                </p>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default DatasourceTable;
