export const getLabelbgColorCss = (label) => {
  switch (label.toLowerCase()) {
    case "critical":
    case "urgent":
    case "bug":
      return "bg-[#D32F2F]";
    case "high":
      return "bg-[#F57C00]";
    case "moderate":
      return "bg-[#FBC02D]";
    case "low":
      return "bg-[#1976D2]";

    default:
      return "bg-[#616161]";
  }
};

export function desaturateColor(hex, desaturationPercent) {
  // Convert hex to RGB
  let r = parseInt(hex.slice(1, 3), 16);
  let g = parseInt(hex.slice(3, 5), 16);
  let b = parseInt(hex.slice(5, 7), 16);

  // Convert RGB to HSL
  r /= 255;
  g /= 255;
  b /= 255;
  const max = Math.max(r, g, b);
  const min = Math.min(r, g, b);
  let h,
    s,
    l = (max + min) / 2;

  if (max === min) {
    h = s = 0; // achromatic
  } else {
    const d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      case b:
        h = (r - g) / d + 4;
        break;
    }
    h /= 6;
  }

  // Desaturate
  s *= (100 - desaturationPercent) / 100;

  // Convert HSL back to RGB
  let q = l < 0.5 ? l * (1 + s) : l + s - l * s;
  let p = 2 * l - q;

  function hue2rgb(p, q, t) {
    if (t < 0) t += 1;
    if (t > 1) t -= 1;
    if (t < 1 / 6) return p + (q - p) * 6 * t;
    if (t < 1 / 2) return q;
    if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
    return p;
  }

  r = hue2rgb(p, q, h + 1 / 3);
  g = hue2rgb(p, q, h);
  b = hue2rgb(p, q, h - 1 / 3);

  // Convert RGB back to hex
  r = Math.round(r * 255).toString(16);
  g = Math.round(g * 255).toString(16);
  b = Math.round(b * 255).toString(16);

  return `#${r.padStart(2, "0")}${g.padStart(2, "0")}${b.padStart(2, "0")}`;
}

export const gradients = [
  "bg-gradient-to-br from-green-400 via-green-600 to-black",

  "bg-gradient-to-br from-indigo-600 to-pink-500",
  "bg-gradient-to-br from-yellow-300 via-orange-500 to-red-600",
  "bg-gradient-to-br from-teal-400 via-blue-500 to-purple-600",
  "bg-gradient-to-br from-gray-700 via-gray-900 to-black",
  "bg-gradient-to-br from-green-400 via-green-600 to-black",
  "bg-gradient-to-br from-blue-500 via-teal-400 to-green-500",
  "bg-gradient-to-br from-indigo-600 to-blue-500",
  "bg-gradient-to-br from-cyan-400 via-blue-500 to-indigo-600",
  "bg-gradient-to-br from-teal-400 via-blue-500 to-purple-600",
  "bg-gradient-to-br from-blue-300 via-indigo-500 to-purple-700",
];

export function getRandomGradient() {
  const randomIndex = Math.floor(Math.random() * gradients.length);
  return gradients[randomIndex];
}

