import _ from '@lodash';
import { styled } from '@mui/material/styles';
import Avatar from '@mui/material/Avatar';
import Card from '@mui/material/Card';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import clsx from 'clsx';
import { Draggable } from 'react-beautiful-dnd';
import { useAppDispatch, useAppSelector } from 'app/store';
import { AvatarGroup } from '@mui/material';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import { MouseEvent } from 'react';
import { openCardDialog } from '../../store/cardSlice';
import { selectCardById } from '../../store/cardsSlice';
import BoardCardLabel from './BoardCardLabel';
import { selectMembers } from '../../store/membersSlice';
import BoardCardDueDate from './BoardCardDueDate';
import BoardCardCheckItems from './BoardCardCheckItems';
import { selectBoard } from '../../store/boardSlice';
import { CardType } from '../../types/CardType';
import EmailIcon from '@mui/icons-material/Email';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

const StyledCard = styled(Card)(({ theme }) => ({
	'& ': {
		transitionProperty: 'box-shadow',
		transitionDuration: theme.transitions.duration.short,
		transitionTimingFunction: theme.transitions.easing.easeInOut
	}
}));



/**
 * The board card component.
 */
const BoardCard = (props) =>{
	const { cardId, index } = props;

	const dispatch = useAppDispatch();
	const { data: board } = useAppSelector(selectBoard);
	const card = useAppSelector(selectCardById(cardId));
	const commentsCount = getCommentsCount(card);
	const cardCoverImage = _.find(card?.attributes.attachments, { id: card?.attributes.attachmentCoverId });

	function handleCardClick(ev, _card) {
		ev.preventDefault();

		dispatch(openCardDialog(_card));
	}

	function getCommentsCount(_card) {
		return _.sum(_card?.attributes?.activities.data.map((x) => (x.attributes.type === 'comment' ? 1 : 0)));
	}

	if (!board) {
		return null;
	}

	return (
		<Draggable
			draggableId={cardId.toString()}
			index={index}
		>
			{(provided, snapshot) => (
				<div
					ref={provided.innerRef}
					{...provided.draggableProps}
					{...provided.dragHandleProps}
				>
					<StyledCard
						className={clsx(
							snapshot.isDragging ? 'shadow-lg' : 'shadow-lg',
							'w-full mb-12 rounded-lg cursor-pointer'
						)}
						onClick={(ev) => handleCardClick(ev, card)}
					>
						{card?.attributes.ticketId && 
							(<Typography className='font-regular ml-16 mt-14 text-gray'>
								{card?.attributes.ticketId}
							</Typography>)}
						{board?.attributes.settings.cardCoverImages && cardCoverImage && (
							<img
								className="block"
								src={cardCoverImage.src}
								alt="card cover"
							/>
						)}

						<div className="p-16 pb-0">
							{card?.attributes.labels.data.length > 0 && (
								<div className="flex flex-wrap mb-8 -mx-4">
									{card?.attributes.labels.data.map((l) => (
										<BoardCardLabel
											id={l.id}
											key={l.id}
										/>
									))}
								</div>
							)}

							<Typography className="font-regular mb-12 text-[15px]">{card?.attributes.title}</Typography>

							{(card?.attributes.dueDate || card?.attributes.checklists.length > 0) && (
								<div className="flex items-center mb-12 -mx-4">
									<BoardCardDueDate dueDate={card?.attributes.dueDate} />

									<BoardCardCheckItems card={card} />
								</div>
							)}
						</div>

						<div className="flex justify-between h-48 px-16">
							<div className="flex items-center space-x-4">
								{card?.attributes.subscribed && (
									<FuseSvgIcon
										size={16}
										color="action"
									>
										heroicons-outline:eye
									</FuseSvgIcon>
								)}

								{card?.attributes.description !== '' && (
									<FuseSvgIcon
										size={16}
										color="action"
									>
										heroicons-outline:document-text
									</FuseSvgIcon>
								)}

								{card?.attributes.attachments && (
									<span className="flex items-center space-x-2">
										<FuseSvgIcon
											size={16}
											color="action"
										>
											heroicons-outline:paper-clip
										</FuseSvgIcon>
										<Typography color="gray">{card?.attributes.attachments.length}</Typography>
									</span>
								)}
								{commentsCount > 0 && (
									<span className="flex items-center space-x-2">
										<FuseSvgIcon
											size={16}
											color="action"
										>
											heroicons-outline:chat
										</FuseSvgIcon>

										<Typography color="text.secondary">{commentsCount}</Typography>
									</span>
								)}
								{card?.attributes?.contact_booking?.data?.attributes?.phone_number&&(
									<span className="flex items-center space-x-4">
										<WhatsAppIcon
									fontSize="12px"
									className="text-gray ml-2"
								/>

									<Typography className="text-gray">{card?.attributes?.contact_booking?.data?.attributes?.phone_number}</Typography>
								</span>
								)}
								{!card?.attributes?.contact_booking?.data?.attributes?.phone_number && card?.attributes?.contact_booking?.data?.attributes?.email &&(
									<span className="flex items-center space-x-2 ">
										<EmailIcon
											fontSize="12px"
											className="text-gray ml-2"
										/>

									<Typography className="text-gray">{card?.attributes?.contact_booking?.data?.attributes?.email}</Typography>
								</span>
								)}
							</div>

							<div className="flex items-center justify-end space-x-12">
								{card?.attributes.memberIds.data.length > 0 && (
									<div className="flex justify-start">
										<AvatarGroup
											max={3}
											classes={{ avatar: 'w-24 h-24 text-12' }}
										>
											{card?.attributes.memberIds.data.map((m) => {
												const member =m;
												// const member = _.find(members, { id });
												return (
													<Tooltip
														title={member?.attributes.username}
														key={m.id}
													>
														<Avatar
															key={index}
															alt="member"
															src={member?.attributes.avatar??''}
														/>
													</Tooltip>
												);
											})}
										</AvatarGroup>
									</div>
								)}
							</div>
						</div>
					</StyledCard>
				</div>
			)}
		</Draggable>
	);
}

export default BoardCard;
