import { React, useState, useEffect } from "react";
import { Button, Typography, Stack, IconButton } from "@mui/material";
import { useLocation, Link } from "react-router-dom";
import Grid from "@mui/material/Grid";
import { useSelector } from "react-redux";
import { selectUser, setUser } from "app/store/userSlice";
import { showMessage } from "app/store/fuse/messageSlice";
import { useDispatch } from "react-redux";
import KBSourceDataGrid from "./KBSourceDataGrid";
import FileUploadArea from "./FileUploadArea";
import AddSourceModal from "./add-source-modal";
import UrlUploadInput from "./UrlUploadInput/UrlUploadInput";
import ScrapedInput from "./UrlUploadInput/ScrapedInput";
import { ReactSVG } from "react-svg";
import FusePageSimple from "@fuse/core/FusePageSimple";
import axios from "axios";
import "./kb.css";
import { selectAiTasks, getAiTasks } from "app/store/aiTaskSlice";
import EmptyData from "app/shared-components/empty-data/empty-data";
import BaseDialog from "app/shared-components/dialog/base-dialog";
import AiAgentCardsList from "./ai-agent-card/ai-agent-card-list";
import SourceCodeTrainModal from "./popups/source-code-train";
import ConfirmDialog from "app/shared-components/dialog/confirm-dialog";
import ShopifyIntegration from "./settings-sections/shopifyIntegration";
import WhatsappIntegration from "./settings-sections/whatsappIntegration";
import SlackIntegration from "./settings-sections/SlackIntegration";
import {
  logPopulateDocEvent,
  logPopulateSourceEvent,
  logPopulateUrlEvent,
} from "src/app/utils/analytics";
import history from "@history";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import PrefixPrompt from "./prefix-prompt";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import LeadForm from "./lead-form/lead-form";
import AgentInfo from "./settings-sections/agentInfo";
import FormatColorFillIcon from "@mui/icons-material/FormatColorFill";
import {
  selectUsage,
  hasExceededTrainingTokenLimit,
  hasExceededScrapeTrainLimit,
} from "app/store/usageSlice";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import LoadingSpinner from "app/shared-components/loading-spinner/loading-spinner";
import { getImageForKB } from "./utils/KBUtils";
import EmailIntegration from "./settings-sections/emailIntegration";
import { getUsage } from "app/store/usageSlice";
import DatasourceTable from "./tables/DatasourceTable";
import ChunksListPage from "./ChunksListPage";
import DisabledPill from "app/shared-components/pill/pill";
import CTAButon from "app/shared-components/buttons/cta-button";
import { getImageForAgentTaskId } from "src/app/main/factory/ImageFactory";

export default function ChatbotDetailPage() {
  const aiTaskData = useSelector(selectAiTasks);
  const [uploadLinkOpen, setuploadLinkOpen] = useState(false);
  const [scrapeModalOpen, setScrapeModalOpen] = useState(false);
  const [addSourceModalOpen, setAddSourceModalOpen] = useState(false);
  const [deleteSourceModalOpen, setDeleteSourceModalOpen] = useState(false);
  const [agentsModalOpen, setAgentsModalOpen] = useState(false);
  const [uploadFileOpen, setUploadFileOpen] = useState(false);
  const [uploadSourceCodeOpen, setUploadSourceCodeOpen] = useState(false);
  const [link, setLink] = useState("");
  const [scrapeUrls, setScrapeUrls] = useState([]);
  const [scrapeSwitch, setScrapeSwitch] = useState(false);
  const [loading, setLoading] = useState(false);
  const [prefixLoading, setPrefixLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [failureMessage, setFailureMessage] = useState("");
  const user = useSelector(selectUser);
  const usage = useSelector(selectUsage);
  const location = useLocation();
  const kbID = new URLSearchParams(location.search).get("kbID");
  const kbName = new URLSearchParams(location.search).get("kbName");
  const kbrID = new URLSearchParams(location.search).get("kbrID");
  const [kbRecord, setkbRecord] = useState();
  const [selectedDocumentId, setSelectedDocumentId] = useState();
  const [selectedDocumentName, setSelectedDocumentName] = useState();

  const dispatch = useDispatch();

  const [data, setData] = useState([]);
  const [kbloading, setKBLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;

  useEffect(() => {
    fetchData();
    dispatch(getUsage());
  }, [currentPage]);

  useEffect(() => {
    dispatch(getAiTasks());
  }, [dispatch]);

  const geKBRecord = async () => {
    try {
      const kbRec = await axios.get(
        `${process.env.REACT_APP_AUTH_BASE_URL}/api/knowledgebases/${kbrID}?populate[0]=default_ai_task,shopify_model,whatsapp_integration_model,slack_integration_model,scrumboard,ai_agent,datasource`
      );
      setkbRecord(kbRec.data.data);
    } catch (e) {
      console.log(e);
    }
  };

  const fetchData = async () => {
    try {
      setKBLoading(true);
      if (!kbRecord) {
        await geKBRecord();
      }
      const response = await axios.get(
        `${process.env.REACT_APP_AUTH_BASE_URL}/api/kb_source`,
        {
          params: {
            page_num: currentPage,
            page_size: pageSize,
            kb_id: kbID,
          },
        }
      );
      setData(response.data.kb_details);
      setKBLoading(false);
    } catch (error) {
      setKBLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  const handleChunkTableClose = () => {
    setSelectedDocumentId(null);
    setSelectedDocumentName(null);
  };

  const agentModelOpen = () => {
    setAgentsModalOpen(true);
  };
  const agentModelClose = () => {
    setAgentsModalOpen(false);
  };

  const handlePageChange = (params) => {
    setCurrentPage(params.page);
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const reLoadData = () => {
    setData([]);
    setCurrentPage(1);
    fetchData();
  };

  const handleAddSourceModalOpen = () => {
    setSuccessMessage("");
    setFailureMessage("");
    setAddSourceModalOpen(true);
  };
  const handleDeleteSourceModalOpen = () => {
    setDeleteSourceModalOpen(true);
  };
  const onDeleteCancel = () => {
    setDeleteSourceModalOpen(false);
  };
  async function HandleDeleteSource() {
    setDeleteSourceModalOpen(false);
    setLoading(true);
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_AUTH_BASE_URL}/api/delete_kb_source/${kbID}`
      );
      setData([]);
      setLoading(false);
      dispatch(showMessage({ message: "All trained data has been cleared" }));
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }

  const handleAIAgentUpdate = async (id) => {
    if (agentsModalOpen) {
      agentModelClose();
    }

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_AUTH_BASE_URL}/api/knowledgebases/${kbrID}`,
        { data: { default_ai_task: id } }
      );
      geKBRecord();
      dispatch(showMessage({ message: "Your agent has been updated" }));
    } catch (e) {
      console.log(e);
    } finally {
    }
  };

  const handleScrapeModalClose = () => {
    setScrapeModalOpen(false);
    setuploadLinkOpen(false);
    setScrapeSwitch(false);
  };
  const handleItemClick = (item) => {
    switch (item) {
      case "url":
        setScrapeSwitch(false);
        setAddSourceModalOpen(false);
        setuploadLinkOpen(true);
        break;
      case "textPdf":
        setAddSourceModalOpen(false);
        setUploadFileOpen(true);
        break;
      case "youtube":
        setScrapeSwitch(true);
        setAddSourceModalOpen(false);
        setuploadLinkOpen(true);
        break;
      case "sourceCode":
        setuploadLinkOpen(false);
        setAddSourceModalOpen(false);
        setUploadFileOpen(false);
        setUploadSourceCodeOpen(true);
    }
  };
  const handlePopupBack = () => {
    setuploadLinkOpen(false);
    setUploadFileOpen(false);
    setUploadSourceCodeOpen(false);
    setAddSourceModalOpen(true);
  };
  const handleMainModalClose = () => {
    setSuccessMessage("");
    setFailureMessage("");
    setAddSourceModalOpen(false);
  };

  const handleUploadLinkClose = () => {
    setuploadLinkOpen(false);
    setScrapeSwitch(false);
  };

  const handleUploadLinkSubmit = async (link, type) => {
    if (type == "scrape") {
      setLink(link);
      setLoading(true);

      const options = {
        method: "POST",
        url: `${process.env.REACT_APP_AUTH_BASE_URL}/api/linkextrack`,
        headers: { "Content-Type": "application/json" },
        data: { url: link },
      };

      try {
        const response = await axios.request(options);
        if (response.data) {
          const formattedUrls = response.data.urls.map((url, index) => ({
            id: index + 1,
            url: url,
          }));
          setScrapeUrls(formattedUrls);
          setScrapeModalOpen(true);
          setLoading(false);
        } else {
          setSuccessMessage("");
          setFailureMessage("Could not extract links");
        }
      } catch (error) {
        setSuccessMessage("");
        setFailureMessage(
          error.response?.data?.description ?? "Could not extract links"
        );
        setLoading(false);
      }
    } else {
      if (hasExceededTrainingTokenLimit(user.data.organization.plan, usage)) {
        dispatch(
          showMessage({
            message:
              "Your have run out of training tokens. Please upgrade your plan to train more",
          })
        );
        handleUploadLinkClose();
        return;
      }

      if (!link || link.trim() === "") {
        setFailureMessage("Add a url to train");
      } else {
        setLoading(true);
        const options = {
          method: "POST",
          url: `${process.env.REACT_APP_AUTH_BASE_URL}/api/v3/train`,
          headers: { "Content-Type": "application/json" },
          data: {
            kb_id: kbID,
            urls: [link],
            kbr_id: parseInt(kbrID),
          },
        };

        axios
          .request(options)
          .then(function (response) {
            if (response.data.py_result.status == "Success") {
              logPopulateUrlEvent(user.data.email, "link", {
                kb_id: kbID,
                urls: [link],
              });
              setFailureMessage("");
              setSuccessMessage("Link Train Added Successful");
              reLoadData();
              var updatedUser = JSON.parse(JSON.stringify(user)); // Shallow copy of the user object
              updatedUser.data.organization.current_month_usage.query_count += 1;
              dispatch(setUser(updatedUser));
            } else {
              setSuccessMessage("");
              setFailureMessage("Link Train Failed !!");
            }
          })
          .catch(function (error) {
            setSuccessMessage("");
            setFailureMessage(
              error?.response?.data?.error?.message ?? "Link Train Failed !!"
            );
          })
          .finally(() => {
            dispatch(getUsage());
            setLoading(false);
          });
      }
    }
  };

  const handleUploadScrapedLinksSubmit = async (links) => {
    if (hasExceededScrapeTrainLimit(links.length, usage)) {
      dispatch(
        showMessage({
          message: `Only you can train upto ${user.data.organization.plan.allowed_scrape_train_count} links at a time. Please select less than ${user.data.organization.plan.allowed_scrape_train_count}. Or update your plan to train more links`,
        })
      );
      return;
    }
    if (hasExceededTrainingTokenLimit(user.data.organization.plan, usage)) {
      dispatch(
        showMessage({
          message:
            "You have run out of training tokens. Please upgrade your plan to train more",
        })
      );
      handleUploadLinkClose();
      return;
    }

    setLoading(true);
    const options = {
      method: "POST",
      url: `${process.env.REACT_APP_AUTH_BASE_URL}/api/v3/train`,
      headers: { "Content-Type": "application/json" },
      data: {
        kb_id: kbID,
        urls: links.map((link) => link.url),
        kbr_id: parseInt(kbrID),
      },
    };

    axios
      .request(options)
      .then(function (response) {
        if (response.data.py_result.status == "Success") {
          logPopulateUrlEvent(user.data.email, "link", {
            kb_id: kbID,
            urls: links.map((link) => link.url),
          });
          setFailureMessage("");
          setSuccessMessage("Links Train Added Successful");
          reLoadData();
          var updatedUser = JSON.parse(JSON.stringify(user)); // Shallow copy of the user object
          updatedUser.data.organization.current_month_usage.query_count += 1;
          dispatch(setUser(updatedUser));
        } else {
          setSuccessMessage("");
          setFailureMessage("Links Train Failed !!");
        }
      })
      .catch(function (error) {
        setSuccessMessage("");
        setFailureMessage(
          error?.response?.data?.error?.message ?? "Links Train Failed !!"
        );
      })
      .finally(() => {
        dispatch(getUsage());
        setLoading(false);
      });
  };

  const handleUploadFileClose = () => {
    setUploadFileOpen(false);
  };
  const handleUploadSourceCodeClose = () => {
    setUploadSourceCodeOpen(false);
  };

  const handleUploadFileSubmit = async (files) => {
    if (hasExceededTrainingTokenLimit(user.data.organization.plan, usage)) {
      dispatch(
        showMessage({
          message:
            "You have run out of training tokens. Please upgrade your plan to train more",
        })
      );
      handleUploadLinkClose();
    } else if (files.length === 0) {
      setFailureMessage("No file selected for upload");
    } else {
      setLoading(true);
      const form = new FormData();

      form.append("files.file", files[0]);
      form.append("kb_id", kbID);
      form.append("force_train", true);
      form.append("kbr_id", kbrID);

      const options = {
        method: "POST",
        url: `${process.env.REACT_APP_AUTH_BASE_URL}/api/file-trains`,
        headers: {
          "Content-Type":
            "multipart/form-data; boundary=----WebKitFormBoundary1tzbASF75A9d5cTB",
        },
        data: form,
      };

      axios
        .post(options.url, form, { headers: options.headers })
        .then((response) => {
          if ((response.data.py_result.status = "Success")) {
            logPopulateDocEvent(user.data.email, "Doc", form);
            setFailureMessage("");
            setSuccessMessage("File Uploaded successfully");
            reLoadData();
            var updatedUser = JSON.parse(JSON.stringify(user));
            updatedUser.data.organization.current_month_usage.query_count += 1;
            dispatch(setUser(updatedUser));
          } else {
            setSuccessMessage("");
            setFailureMessage("File Train Failed !!");
          }
        })
        .catch((error) => {
          var err = error?.response?.data?.error?.message;
          setSuccessMessage("");
          setFailureMessage(err ?? "File Upload Failed !!");
        })
        .finally(() => {
          dispatch(getUsage());
          setLoading(false);
        });
    }
  };

  const handleUploadSourceCodeSubmit = async (files) => {
    if (hasExceededTrainingTokenLimit(user.data.organization.plan, usage)) {
      dispatch(
        showMessage({
          message:
            "You have run out of training tokens. Please upgrade your plan to train more",
        })
      );
      handleUploadLinkClose();
    } else if (files.length === 0) {
      setFailureMessage("No file selected for upload");
    } else {
      setLoading(true);
      const form = new FormData();

      form.append("files.file", files[0]);
      form.append("kb_id", kbID);
      form.append("force_train", true);

      const options = {
        method: "POST",
        url: `${process.env.REACT_APP_AUTH_BASE_URL}/api/code-trains`,
        headers: {
          "Content-Type":
            "multipart/form-data; boundary=----WebKitFormBoundary1tzbASF75A9d5cTB",
        },
        data: form,
      };

      axios
        .post(options.url, form, { headers: options.headers })
        .then((response) => {
          if (response.data.attributes.train_status) {
            logPopulateSourceEvent(user.data.email, "sourceCode", form);
            setFailureMessage("");
            setSuccessMessage("File Uploaded successfully");
            reLoadData();
            var updatedUser = JSON.parse(JSON.stringify(user));
            updatedUser.data.organization.current_month_usage.query_count += 1;
            dispatch(setUser(updatedUser));
          } else {
            setSuccessMessage("");
            setFailureMessage("File Train Failed !!");
          }
        })
        .catch((error) => {
          var err = error?.response?.data?.error?.message;
          setSuccessMessage("");
          setFailureMessage(err ?? "File Upload Failed !!");
        })
        .finally(() => {
          dispatch(getUsage());
          setLoading(false);
        });
    }
  };
  const handlePrefixUpdate = async (value) => {
    setPrefixLoading(true);
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_AUTH_BASE_URL}/api/knowledgebases/${kbrID}`,
        { data: { prompt_prefix: value } }
      );
      dispatch(
        showMessage({
          message: "Successfully updated prefix to agent",
        })
      );
    } catch (e) {
      dispatch(
        showMessage({
          message: "Failed to update prefix. Try again ",
        })
      );
    } finally {
      setPrefixLoading(false);
    }
  };

  return (
    <FusePageSimple
      content={
        <div className="w-full px-64 md:px-28 pb-24 mt-20">
          {kbloading && (
            <div className="flex flex-col justify-center items-center w-full h-full">
              <LoadingSpinner className={"w-60 h-60 text-[#6200ea]"} />
              <Typography className={"mt-16 text-lg font-regular"}>
                Loading your agent..
              </Typography>
            </div>
          )}
          {!kbloading && (
            <div className="flex item-heading py-10">
              <div className="flex">
                <IconButton
                  className="w-40 h-40"
                  onClick={(e) => {
                    history.back();
                  }}
                >
                  <ArrowBackIcon style={{ color: "black" }} />
                </IconButton>
                <Typography className="text-3xl mt-2 font-semibold tracking-tight leading-8 mb-10 sm:mb-0">
                  <span className="flex align-center">{kbName}</span>
                </Typography>
              </div>
              <Stack className="items-center mr-16" direction="row" spacing={2}>
                <CTAButon
                  text="Deploy Agent"
                  image="assets/images/deploy.svg"
                  onPress={() => {
                    history.push(
                      `/dashboards/knowledge_base/chat_bot?kbID=${kbID}`
                    );
                  }}
                />
                <CTAButon
                  text="Style Chatbot UI"
                  image="assets/images/style.svg"
                  onPress={() => {
                    history.push(
                      `/dashboards/knowledge_base/chat_bot_style?kb=${kbrID}`
                    );
                  }}
                />
                <CTAButon
                  text="Tickets"
                  image="assets/images/tickets.svg"
                  onPress={() => {
                    history.push(
                      `/dashboards/kanban/boards/${kbRecord?.attributes.scrumboard.data?.id}`
                    );
                  }}
                />
              </Stack>
            </div>
          )}
          <div className="flex flex-row">
            {kbRecord?.attributes?.default_ai_task.data.attributes.name && (
              <img
                className="w-36 h-36 flex-shrink-0 ml-4 bg-base-purple rounded-full p-8"
                src={getImageForAgentTaskId(
                  kbRecord?.attributes?.default_ai_task.data.id
                )}
              />
            )}
            {kbRecord?.attributes?.default_ai_task.data.attributes.name && (
              <span className="ml-8 font-medium mt-8">
                {kbRecord?.attributes?.default_ai_task.data.attributes.name}
              </span>
            )}
          </div>

          {!kbloading &&
          !_.isEmpty(kbRecord) &&
          (kbRecord.attributes.type == "datasource" ||
            kbRecord.attributes.type == "autoAgent") ? (
            <div>
              {
                <DatasourceTable
                  isInitiallyOpen={true}
                  datastoreId={
                    kbRecord?.attributes.datasource?.data.length > 0
                      ? kbRecord?.attributes?.datasource?.data[0].id
                      : null
                  }
                  onAddClick={handleAddSourceModalOpen}
                  onDeleteAllClick={handleDeleteSourceModalOpen}
                  onDocumentClick={(documentId, documentName) => {
                    setSelectedDocumentId(documentId);
                    setSelectedDocumentName(documentName);
                  }}
                />
              }
              {!_.isEmpty(data) && (
                <div className="settigs-grid-container bg-white border-1">
                  <div className="mt-4 flex flex-row items-center justify-between ">
                    <div className="flex flex-row mb-8">
                      <img
                        src="assets/images/datasource-icon.png"
                        className="w-32 h-32 p-4 bg-[#EBEFF9] rounded-2xl"
                      ></img>
                      <div className="ml-8 mt-6 font-semibold">
                        {"Existing Datasources"}
                      </div>
                      <div className="mx-16 mt-4">
                        <DisabledPill text={"Moving Soon"} />
                      </div>
                    </div>
                  </div>
                  <div className="mt-2 w-1/2  ml-8 mt-8 mb-16 text-base text-grey-700 font-regular">
                    This is where all your older data sources are stored. All
                    this data is still being used to train your agent. You can
                    add new data sources by clicking the Add datasources button.
                  </div>

                  {_.isEmpty(data) && !kbloading ? (
                    <EmptyData
                      className="mt-52 text-center"
                      message="You don’t have any data source yet. Click the Add datasources
                button to add your data like pdfs, urls, sitemaps or youtube videos to train your agent."
                    />
                  ) : (
                    <Grid
                      container
                      className="table-container w-full border rounded-lg mt-10 mx-auto"
                    >
                      <Grid item xs={12} sx={{ justifyContent: "flex-end" }}>
                        <div className="h-full w-full">
                          <KBSourceDataGrid
                            data={data}
                            handlePageChange={handlePageChange}
                            handleNextPage={handleNextPage}
                            handlePreviousPage={handlePreviousPage}
                            kbloading={kbloading}
                            currentPage={currentPage}
                            pageSize={pageSize}
                          />
                        </div>
                      </Grid>
                    </Grid>
                  )}
                </div>
              )}
            </div>
          ) : (
            <div></div>
          )}
          {kbRecord?.attributes.type == "datasource" && (
            <PrefixPrompt
              onSubmit={handlePrefixUpdate}
              value={kbRecord?.attributes.prompt_prefix}
              isLoading={prefixLoading}
            />
          )}
          {kbRecord && kbRecord.attributes.type == "datasource" && (
            <LeadForm
              kbID={kbRecord?.id}
              value={kbRecord?.attributes.collect_leads}
            />
          )}
          {_.isEmpty(kbRecord) ? (
            <div></div>
          ) : (
            <div className="mt-48 w-full">
              {kbRecord.attributes.type == "shopify" ? (
                <ShopifyIntegration
                  className="mt-16"
                  data={
                    kbRecord.attributes.shopify_model.data != null
                      ? kbRecord.attributes.shopify_model.data
                      : null
                  }
                  kb_id={kbRecord.id}
                />
              ) : (
                <div></div>
              )}
              {kbRecord.attributes.type == "autoAgent" ? (
                <AgentInfo
                  className="mt-32"
                  data={
                    kbRecord.attributes.ai_agent.data != null
                      ? kbRecord.attributes.ai_agent.data
                      : null
                  }
                  kb_id={kbRecord.id}
                />
              ) : (
                <div></div>
              )}
              <SlackIntegration
                className="mt-32"
                data={
                  kbRecord.attributes.slack_integration_model?.data != null ? (
                    kbRecord.attributes.slack_integration_model.data
                  ) : (
                    <div></div>
                  )
                }
                kb_id={kbRecord.id}
              />
              <WhatsappIntegration
                className="mt-32"
                data={
                  kbRecord.attributes.whatsapp_integration_model?.data !=
                  null ? (
                    kbRecord.attributes.whatsapp_integration_model.data
                  ) : (
                    <div></div>
                  )
                }
                kb_id={kbRecord.id}
              />
              <EmailIntegration
                className="mt-32"
                data={kbRecord}
                kb_id={kbRecord.id}
              />
            </div>
          )}
          <BaseDialog
            title="All AI Task Details"
            open={agentsModalOpen}
            handleClose={agentModelClose}
          >
            <AiAgentCardsList onAgentSelect={handleAIAgentUpdate} />
          </BaseDialog>

          <AddSourceModal
            open={addSourceModalOpen}
            handleClose={handleMainModalClose}
            onItemClick={handleItemClick}
          />
          <BaseDialog
            title="Extracted Data"
            open={selectedDocumentId != null}
            handleClose={handleChunkTableClose}
          >
            <ChunksListPage
              documentName={selectedDocumentName}
              documentId={selectedDocumentId}
            ></ChunksListPage>
          </BaseDialog>
          <FileUploadArea
            open={uploadFileOpen}
            handleClose={handleUploadFileClose}
            handleSubmit={handleUploadFileSubmit}
            onBackClick={handlePopupBack}
            uploading={loading}
            successMessage={successMessage}
            failureMessage={failureMessage}
          />
          <UrlUploadInput
            open={uploadLinkOpen}
            hideSwitch={scrapeSwitch}
            handleClose={handleUploadLinkClose}
            handleSubmit={handleUploadLinkSubmit}
            onBackClick={() => {
              handlePopupBack();
            }}
            loading={loading}
            successMessage={successMessage}
            failureMessage={failureMessage}
            setSuccessMessage={setSuccessMessage}
            setFailureMessage={setFailureMessage}
          />
          <ScrapedInput
            open={scrapeModalOpen}
            handleClose={handleScrapeModalClose}
            handleSubmit={handleUploadScrapedLinksSubmit}
            loading={loading}
            successMessage={successMessage}
            failureMessage={failureMessage}
            url={link}
            scrapeUrls={scrapeUrls}
          />
          <SourceCodeTrainModal
            open={uploadSourceCodeOpen}
            handleClose={handleUploadSourceCodeClose}
            handleSubmit={handleUploadSourceCodeSubmit}
            onBackClick={handlePopupBack}
            uploading={loading}
            successMessage={successMessage}
            failureMessage={failureMessage}
          />
          <ConfirmDialog
            open={deleteSourceModalOpen}
            message="Are you sure want to delete all trained data?!"
            onCancel={onDeleteCancel}
            onConfirm={HandleDeleteSource}
          />
        </div>
      }
    />
  );
}
