import React from "react";
import ReactMarkdown from 'react-markdown';
import {Prism as SyntaxHighlighter} from 'react-syntax-highlighter'
import {a11yDark} from 'react-syntax-highlighter/dist/esm/styles/prism'
import remarkGfm from 'remark-gfm';
import remarkMath from 'remark-math';
import rehypeKatex from 'rehype-katex';
import rehypeRaw from 'rehype-raw';
import './code-highlight.css'

export default function CodeSyntaxHighlighter({ answer }) {

    return (
        <ReactMarkdown
            children={answer}
            remarkPlugins={[remarkGfm, remarkMath]}
            rehypePlugins={[rehypeRaw, rehypeKatex]}
            components={{
                code({ node, inline, className, children, ...props }) {
                  const match = /language-(\w+)/.exec(className || '');
                  return !inline && match ? (
                    <SyntaxHighlighter
                      {...props}
                      children={String(children).replace(/\n$/, '')}
                      style={a11yDark}
                      language={match[1]}
                      className="syntax-code"
                      PreTag="div"
                    />
                  ) : (
                    <code {...props} className={className}>
                      {children}
                    </code>
                  );
                },
                ul({ children }) {
                  return <ul className="bullet-points">{children}</ul>;
                },
                li({ children }) {
                  return <li className="bullet-point">{children}</li>;
                },
            }}
        />
    );
}
