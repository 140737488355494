import Chip from '@mui/material/Chip';
import clsx from 'clsx';
import getUnixTime from 'date-fns/getUnixTime';
import format from 'date-fns/format';
import fromUnixTime from 'date-fns/fromUnixTime';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';

function BoardCardDueDate(props) {
  const { dueDate } = props;
  let date = Date(dueDate)
  if (!dueDate) {
    return null;
  }
  const formatetime=(time)=>{
    if(typeof time === 'string' ){
      return format(new Date(time), 'MMM do yy');
    }else{
      return format(fromUnixTime(time), 'MMM do yy');
    }
  }

  return (
    <Chip
      size="small"
      className={clsx(
        'flex items-center font-regular text-12 mx-4 mb-6',
        Date.now() > new Date(dueDate).getTime() ? 'bg-[#D32F2F] text-white' : 'bg-[#56ae57] text-white'
      )}
      sx={{
        '& .MuiChip-icon': {
          color: 'inherit'
        }
      }}
      label={formatetime(dueDate)}
      icon={
        <FuseSvgIcon size={14} color="inherit">
          heroicons-outline:clock
        </FuseSvgIcon>
      }
    />
  );
}

export default BoardCardDueDate;
