import { lazy } from 'react';

const KnowledgeBaseDashboardApp = lazy(() => import('./KnowledgeBaseDashboardApp'));

const KnowledgeBaseDashboardAppConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'dashboards/knowledge_base',
      element: <KnowledgeBaseDashboardApp />,
    },
  ],
};

export default KnowledgeBaseDashboardAppConfig;
