import { React, useState, useEffect } from "react";
import { Button, Typography, Stack, IconButton } from "@mui/material";
import { useSelector } from "react-redux";
import { selectUser, setUser } from "app/store/userSlice";
import { useDispatch } from "react-redux";
import {
  selectUsage,
  hasExceededTrainingTokenLimit,
} from "app/store/usageSlice";
import FusePageSimple from "@fuse/core/FusePageSimple";
import axios from "axios";
import history from "@history";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import LongTextField from "app/shared-components/forms/LongTextField";
import DynamicTextFields from "app/shared-components/forms/DynamicTextFields";
import NumberPicker from "app/shared-components/forms/NumberPicker";
import FileResultsTable from "./fileResultsTable";

export default function TurboWriterPage() {
  const user = useSelector(selectUser);
  const kbName = new URLSearchParams(location.search).get("kbName");
  const kbrID = new URLSearchParams(location.search).get("kbrID");
  const dispatch = useDispatch();
  const [objective, setObjective] = useState("");
  const [guidelines, setGuidelines] = useState([]);
  const [isFormValid, setIsFormValid] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [sections, setSections] = useState(3);
  const [fileRestults, setFileResults] = useState([]);
  const usage = useSelector(selectUsage);

  useEffect(() => {}, [currentPage]);

  useEffect(() => {}, [dispatch]);

  useEffect(() => {
    getFileResults();
  }, []);

  const geKBRecord = async () => {
    try {
      const kbRec = await axios.get(
        `${process.env.REACT_APP_AUTH_BASE_URL}/api/knowledgebases/${kbrID}?populate[0]=default_ai_task,shopify_model,whatsapp_integration_model,slack_integration_model,scrumboard,ai_agent`
      );
      setkbRecord(kbRec.data.data);
    } catch (e) {
      console.log(e);
    }
  };

  const getFileResults = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_AUTH_BASE_URL}/api/file-results-models?filters[knowledgebase]=${kbrID}`
      );
      setFileResults(response.data.data);
    } catch (e) {
      console.log(e);
    }
  };

  const checkisFormValid = () => {
    if (objective.length < 32 || guidelines.length < 1) {
      return false;
    }

    for (const guideline of guidelines) {
      if (guideline.length < 16) {
        return false;
      }
    }
    return true;
  };

  const onSubmit = async ({ kbrId, query, sections, guides }) => {
    const response = await axios
      .post(`${process.env.REACT_APP_AUTH_BASE_URL}/api/multi-agent`, {
        kb_id: parseInt(kbrId),
        objective: query,
        max_sections: sections,
        guidelines: guides,
      })
      .catch((error) => {
        console.log(error);
      });
    if (response.status === 200) {
      console.log("success");
      console.log(response.data);
    } else {
      console.log("failed");
      console.log(response.data);
    }
  };

  const handlePageChange = (params) => {
    setCurrentPage(params.page);
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const reLoadData = () => {
    setData([]);
    setCurrentPage(1);
    fetchData();
  };

  const calculateCredits = (sections) => {
    return Math.ceil((sections * 0.99) / 0.002048);
  };

  const calculateCost = (sections) => {
    return (sections * 0.99).toFixed(2);
  };

  const validateLimit = () => {
    if (hasExcededCreditLimit(usage)) {
      return false;
    }

    return true;
  };

  return (
    <FusePageSimple
      content={
        <div className="w-full mt-36 md:px-28 pb-24 flex flex-col justify-center items-center">
          <div className="flex w-full item-heading items-start justify-start place-content-start py-10">
            <div className="flex">
              <IconButton
                className="w-40 h-40"
                onClick={(e) => {
                  history.back();
                }}
              >
                <ArrowBackIcon style={{ color: "black" }} />
              </IconButton>
              <Typography className="text-3xl mt-2 font-semibold tracking-tight leading-8 mb-10 sm:mb-0">
                <span className="flex align-center">{kbName}</span>
              </Typography>
            </div>
          </div>

          <div className="flex flex-col bg- w-full">
            <div className="mt-24 flex flex-col items-center justify-center">
              <div className="text-center">
                <h1 className="text-5xl font-bold text-gray-900">
                  What will you{" "}
                  <span className="text-base-purple">write today?</span>
                </h1>
              </div>
            </div>
            <div>
              <div className="mt-24 w-full">
                <LongTextField
                  name="objective"
                  topLabelText={"Objective"}
                  onTextChange={(value) => {
                    setObjective(value);
                    setIsFormValid(checkisFormValid());
                  }}
                />
              </div>

              <div className="mt-24">
                <DynamicTextFields
                  onChange={(value) => {
                    setGuidelines(value);
                    setIsFormValid(checkisFormValid());
                  }}
                  name="guidelines"
                  topLabelText={"Objective"}
                />
              </div>

              <div className="items-center justify-center place-content-center mt-24 flex flex-row">
                <NumberPicker onChange={(value) => setSections(value)} />
                <div className="flex flex-col justify-center">
                  {/* <span className="mx-8 items-center text-3xl text-black font-bold text-center">
                {calculateCredits(sections)}
              </span> */}
                  {/* <span className="mx-8 items-center text-sm text-grey-600 ">
                {"Approx credits usage"}
              </span>
            </div>
            <div className="flex flex-col justify-center">
              <span className="mx-8 items-center text-3xl text-black font-bold text-center">
                {calculateCost(sections)}
              </span>
              <span className="mx-8 items-center text-sm text-grey-600 ">
                {"Approx Cost in $"}
              </span> */}
                </div>
              </div>
              <div className="flex w-full items-center justify-center mb-16">
                <Button
                  onClick={() => {
                    onSubmit({
                      kbrId: kbrID,
                      query: objective,
                      sections: sections,
                      guides: guidelines,
                    });
                  }}
                  variant="contained"
                  color="secondary"
                  className="press-button shine-button w-256 mt-16 rounded-md bg-primary hover:bg-primary text-white"
                  aria-label="Sign in"
                  disabled={!isFormValid}
                  type="submit"
                  size="small"
                >
                  Generate
                </Button>
              </div>
            </div>
          </div>
          <div className="mt-24">
            <span className="items-center text-5xl text-black font-bold text-center mb-16">
              {"Reports"}
            </span>
            <div className="mt-16">
              <FileResultsTable fileResults={fileRestults} />
            </div>
          </div>
        </div>
      }
    />
  );
}
