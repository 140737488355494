import Typography from '@mui/material/Typography';

function KanbanAppHeader() {
  return (
    <div className="flex w-full container">
    <div className="flex flex-col sm:flex-row flex-auto sm:items-center min-w-0 p-24 md:p-32 pb-0 md:pb-0">
      <div className="flex flex-col flex-auto">
        <Typography className="text-3xl font-bold tracking-tight leading-8">
          Query History
        </Typography>
        <Typography className="font-regular text-gray-800 tracking-tight mt-4">
          See what your customers are asking
        </Typography>
      </div>
    </div>
  </div>
  );
}

export default KanbanAppHeader;
