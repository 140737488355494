import { useState } from "react";
import SendIcon from "@mui/icons-material/Send";
import {
  Divider,
  InputBase,
  Paper,
  IconButton as MuiIconButton,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useDispatch } from "react-redux";
import { toggleSignupDialog } from "app/store/authSlice";

export function Search() {
  const [searchInput, setSearchInput] = useState("");
  const dispatch = useDispatch();
  return (
    <Paper
      elevation={0}
      className="px-12 py-0 w-full max-w-md flex items-center rounded-full border-2"
    >
      <SearchIcon className="opacity-60" />

      <InputBase
        fullWidth
        className="ml-4 flex-1 "
        placeholder="a prompt for the podcast you want to create"
        inputProps={{ "aria-label": "search google maps" }}
        value={searchInput}
        onChange={(e) => setSearchInput(e.target.value)}
      />

      <Divider className="h-28 mr-8" orientation="vertical" />
      <MuiIconButton
        aria-label="search"
        className="p-10"
        onClick={() => {
          dispatch(toggleSignupDialog(true));
        }}
      >
        <SendIcon />
      </MuiIconButton>
    </Paper>
  );
}
