import { Box, CardContent, Paper, Typography } from "@mui/material";
import { getRandomGradient } from "src/app/utils/colors";

export function FeaturedAlbumCard({
  title,
  description,
  gradient,
  elevation = 0,
  poster = "",
}) {
  return (
    <Paper
      elevation={elevation}
      className="rounded-lg cursor-pointer w-full bg-transparent"
    >
      <div className="relative">
        <Box
          className={`rounded-lg w-full h-auto aspect-square  bg-center bg-no-repeat shadow-white/20 shadow-md`}
          sx={{ background: `url(${poster})` }}
        />
        <div className="absolute inset-0 bg-grey-900/30 rounded-lg" />
        <div className="absolute top-8 left-8">
          <div>
            <img
              className="logo-icon"
              src="assets/images/logo/logo.png"
              alt="logo"
              width={18}
              height={18}
            />
          </div>
          <div className="mt-8">
            {title.split(" ").map((word, index) => (
              <Typography
                key={index}
                className="whitespace-nowrap font-extrabold text-3xl text-main-text-color"
              >
                {word}
              </Typography>
            ))}
          </div>
        </div>
      </div>
      {description && (
        <div className="p-0 mt-8">
          <Typography className="opacity-80 line-clamp-2 text-main-text-color">
            {description}
          </Typography>
        </div>
      )}
    </Paper>
  );
}
