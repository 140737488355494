import FuseUtils from "@fuse/utils/FuseUtils";
import axios from "axios";
import jwtDecode from "jwt-decode";
import history from "@history";
import {
  addUserProperties,
  logLoginFailureEvent,
  logLoginSuccessEvent,
  logLogoutSuccessEvent,
  logSignupFailureEvent,
  logSignupSuccessEvent,
} from "src/app/utils/analytics";
const { v4: uuidv4 } = require("uuid");
import * as amplitude from "@amplitude/analytics-browser";
import { add } from "lodash";


/* eslint-disable camelcase */

class JwtService extends FuseUtils.EventEmitter {
  init() {
    this.setInterceptors();
    this.handleAuthentication();
  }

  setInterceptors = () => {
    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (err) => {
        return new Promise((resolve, reject) => {
          if (
            err.response.status === 401 &&
            err.config &&
            !err.config.__isRetryRequest
          ) {
            // if you ever get an unauthorized response, logout the user
            this.emit("onAutoLogout", "Invalid access_token");
            this.setSession(null);
          }
          throw err;
        });
      }
    );
  };

  handleAuthentication = () => {
    const access_token = this.getAccessToken();

    if (!access_token) {
      this.emit("onNoAccessToken");

      return;
    }

    if (this.isAuthTokenValid(access_token)) {
      this.setSession(access_token);
      this.emit("onAutoLogin", true);
    } else {
      this.setSession(null);
      this.emit("onAutoLogout", "access_token expired");
    }
  };
  getUserFromToken = (token) => {
    return new Promise((resolve, reject) => {
      const headers = { Authorization: `Bearer ${token}` };
      axios
        .get(
          `${process.env.REACT_APP_AUTH_BASE_URL}/api/users/me?populate[organization][populate][0]=monthly_usages,plan`,
          { headers }
        )
        .then((response) => {
          if (response.data) {
            const user = this.createUserJson(
              response.data.username,
              response.data.email,
              response.data.organization,
              response.data.organization?.usage_quota ?? 0,
              response.data.organization?.paid ?? 0
            );

            amplitude.init(
              process.env.REACT_APP_AMPLITUDE_API_KEY,
              response.data.email
            );
            addUserProperties(response.data.organization?.plan?.name);

            resolve(user);
          } else {
            reject(new Error("Failed to get user with token."));
          }
        })
        .catch((error) => {
          reject(new Error("Failed to get user with token."));
        });
    });
  };

  createUser = (data) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${process.env.REACT_APP_AUTH_BASE_URL}/api/auth/local/register`,
          {
            username: data.userName.trim(),
            email: data.email.trim(),
            organization: data.organization.trim(),
            password: data.password,
          }
        )
        .then(async (response) => {
          logSignupSuccessEvent(response.data.user.id);
          // Handle success.
          if (!response.data.user.confirmed) {
            history.push("/email-confirmation-signup");
            return;
          }

          if (response.data.user) {
            const user = await this.getUserFromToken(response.data.jwt);
            this.setSession(response.data.jwt);
            resolve(user);
            if (
              !(
                user.data?.organization?.subscription === "subscribed" ||
                user.data?.organization?.subscription === "trial"
              )
            ) {
              //this.emit("onNoSubscription", user);
            } else {
              this.emit("onLogin", user);
            }
          } else {
            this.emit("onAutoLogout", response.data.error);
          }
        })
        .catch((error) => {
          logSignupFailureEvent(data.email.trim(), error);
          const err = _.isObject(error.response)
            ? "You may have already signup with this email. Try login/forgot password"
            : error.response;
          // Handle error.
          this.emit("onAutoLogout", err);
        });
    });
  };

  forgotPassword = (email) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${process.env.REACT_APP_AUTH_BASE_URL}/api/auth/forgot-password`,
          {
            email: email,
          }
        )
        .then((response) => {
          if (response.data.ok) {
            this.emit(
              "emitMessage",
              "Check your email, If you have an account you would have recieved instructions to reset password"
            );
          }
        })
        .catch((error) => {
          this.emit(
            "emitMessage",
            "Check the email, looks like you have'nt signed up or not a valid email"
          );
        });
    });
  };

  resetPassword = (data) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${process.env.REACT_APP_AUTH_BASE_URL}/api/auth/reset-password`,
          {
            password: data.password,
            passwordConfirmation: data.passwordConfirmation,
            code: data.emailCode,
          }
        )
        .then((response) => {
          if (response.data.jwt) {
            this.emit(
              "emitMessage",
              "Your password has been reset. In few seconds you'll be redirected to login page."
            );
            setTimeout(() => history.push("/sign-in"), 100);
          }
        })
        .catch((error) => {
          this.emit(
            "emitMessage",
            "Code has expired please do click on forgot password to get a new password reset link"
          );
        });
    });
  };

  signInWithEmailAndPassword = (email, password) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.REACT_APP_AUTH_BASE_URL}/api/auth/local`, {
          identifier: email,
          password: password,
        })
        .then(async (response) => {
          if (response.data.jwt) {
            logLoginSuccessEvent(response.data.id);
            const user = await this.getUserFromToken(response.data.jwt);
            this.setSession(response.data.jwt);
            resolve(user);
            // if (
            //   !(
            //     user.data?.organization?.subscription === "subscribed" ||
            //     user.data?.organization?.subscription === "trial"
            //   )
            // ) {
            //   this.emit("onNoSubscription", user);
            // } else
            // {
              this.emit("onLogin", user);
            //}
          } else {
            this.emit("onAutoLogout", "Invalid password");
          }
        })
        .catch((error) => {
          logLoginFailureEvent(email, error);
          if (
            error.response?.data?.error?.message ===
            "Your account email is not confirmed"
          ) {
            history.push("/email-confirmation-signup/?email=" + email);
            return;
          }
          this.emit("onAutoLogout", "Check password or Contact Admin");
          reject("Failed to login");
        });
    });
  };
  signInWithGoogle = (token) => {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${process.env.REACT_APP_AUTH_BASE_URL}/api/auth/google/callback${token}`
        )
        .then(async (response) => {
          console.log("********response.data******", response.data);
          if (response.data.jwt) {
            logLoginSuccessEvent(response.data?.user?.id);
            const user = await this.getUserFromToken(response.data.jwt);
            this.setSession(response.data.jwt);
            resolve(user);
            // if (
            //   !(
            //     user.data?.organization?.subscription === "subscribed" ||
            //     user.data?.organization?.subscription === "trial"
            //   )
            // ) {
            //   this.emit("onNoSubscription", user);
            // } else {
               this.emit("onLogin", user);
            // }
            this.emit("onLogin", user);
          } else {
            console.log("********response.data logout******", response.data);
            this.emit("onAutoLogout", "Invalid password");
          }
        })
        .catch((error) => {
          console.log("********error******", error);
          logLoginFailureEvent("", error);
          // if(error.response?.data?.error?.message === "Your account email is not confirmed"){
          //   history.push('/email-confirmation-signup/?email=' + email);
          //   return;
          // }
          this.emit("onAutoLogout", "Try again");
          reject(new Error("Failed to login"));
        });
    });
  };

  signInWithToken = () => {
    return new Promise((resolve, reject) => {
      const headers = { Authorization: `Bearer ${this.getAccessToken()}` };
      axios
        .get(
          `${process.env.REACT_APP_AUTH_BASE_URL}/api/users/me?populate[organization][populate][0]=monthly_usages,plan`,
          { headers }
        )
        .then((response) => {
          if (response.data) {
            const user = this.createUserJson(
              response.data.username,
              response.data.email,
              response.data.organization,
              response.data.organization?.usage_quota ?? 0,
              response.data.organization?.paid ?? 0
            );

            amplitude.init(
              process.env.REACT_APP_AMPLITUDE_API_KEY,
              response.data.email
            );
            addUserProperties(response.data.organization?.plan?.name);

            resolve(user);
          } else {
            this.logout("");
            reject(new Error("Failed to login with token."));
          }
        })
        .catch((error) => {
          this.logout("");
          reject(new Error("Failed to login with token."));
        });
    });
  };

  sendEmailConfirmation = (email) => {
    return new Promise((resolve, reject) => {
      const options = {
        method: "POST",
        url: `${process.env.REACT_APP_AUTH_BASE_URL}/api/auth/send-email-confirmation`,
        headers: { "Content-Type": "application/json" },
        data: { email: email },
      };

      axios
        .request(options)
        .then(function (response) {
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
        });
    });
  };

  updateUserData = (user) => {
    const updatedUser = {
      username: user.displayName,
      email: user.email,
    };
    return new Promise((resolve, reject) => {
      axios
        .put(
          `${process.env.REACT_APP_AUTH_BASE_URL}/api/users/me`,
          updatedUser,
          {
            headers: {
              Authorization: `Bearer ${this.getAccessToken()}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {

        })
        .catch((error) => {
          // Handle update error
          console.error("Error updating user profile:", error);
        });
    });
  };

  setSession = (access_token) => {
    if (access_token) {
      localStorage.setItem("jwt_access_token", access_token);
      axios.defaults.headers.common.Authorization = `Bearer ${access_token}`;
    } else {
      localStorage.removeItem("jwt_access_token");
      delete axios.defaults.headers.common.Authorization;
    }
  };

  createUserJson = (username, email, organization, usage_quota, paid) => {
    organization.current_month_usage = organization.monthly_usages.sort(
      (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
    )[0];
    const user = {
      uuid: uuidv4(),
      from: "custom-db",
      role: "admin",
      data: {
        displayName: username,
        photoURL: "assets/images/avatars/user.png",
        email: email,
        organization: organization,
        usage_quota: usage_quota,
        paid: paid,
      },
    };
    return user;
  };

  logout = (email = "") => {
    logLogoutSuccessEvent(email);
    this.setSession(null);
    this.emit("onLogout", "Logged out");
  };

  isAuthTokenValid = (access_token) => {
    if (!access_token) {
      return false;
    }
    const decoded = jwtDecode(access_token);
    const currentTime = Date.now() / 1000;
    if (decoded.exp < currentTime) {
      console.warn("access token expired");
      return false;
    }

    return true;
  };

  getAccessToken = () => {
    return window.localStorage.getItem("jwt_access_token");
  };
}

const instance = new JwtService();

export default instance;
