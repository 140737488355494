import React, { useState } from "react";
import SettingsTextField from "../../../../shared-components/forms/settingsTextField";
import { Button } from "@mui/material";
import PropTypes from "prop-types";
import aesEncrypt from "../../../../utils/encryptions";
import { useDispatch } from "react-redux";
import axios from "axios";
import LoadingText from "../LoadingText";
import { showMessage } from "app/store/fuse/messageSlice";

export default function SlackIntegration(props) {
  const formData = {
    slackChannelId: {
      id: "slackChannelId",
      validate: (value) => {
        return value.length >= 3;
      },
      title: "Slack Channel ID",
      icon: "assets/images/base_url_icon.png",
      placeholder: "#general",
      description:
        "Enter the slack channel ID where you want to receive notifications. You can find this in your slack account settings.",
    },
    slackAuthToken: {
      id: "slackAuthToken",
      validate: (value) => {
        return value.length > 3;
      },
      title: "Slack Auth Token",
      icon: "assets/images/whatsapp_token.png",
      description:
        "Enter your slack auth token here. This can be got from your slack account settings. If you are having trouble finding this, our team can help you with this, just reach out!",
      placeholder: "Your slack auth token",
    },
  };

  const [isValidSlackChannelId, setIsValidSlackChannelId] = useState(false);
  const [isValidSlackAuthToken, setIsValidSlackAuthToken] = useState(false);

  const [slackChannelId, setSlackChannelId] = useState("");
  const [slackAuthToken, setSlackAuthToken] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  useState(() => {
    if (props.data.attributes && props.data.attributes.slack_channel_id) {
      setSlackChannelId(props.data.attributes.slack_channel_id);
      setIsValidSlackChannelId(true);
    }
    if (props.data.attributes && props.data.attributes.access_token) {
      setSlackAuthToken(props.data.attributes.access_token);
      setIsValidSlackAuthToken(true);
    }
  });

  const handleFormSubmit = () => {
    if (isValidSlackChannelId && isValidSlackAuthToken) {
      if(props.data?.id != null && props.data.slack_Auth_Token != slackAuthToken ){
      const encryptedValue = aesEncrypt(
        slackAuthToken,
        process.env.REACT_APP_ENCRYPTION_KEY
      );

      setIsLoading(true);
      if (!isLoading) {
       handleSlackIntegrationData(slackChannelId, encryptedValue);
      }
    }
  };
  const updateSlackIntegrationData = async (id, channelId, authToken) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_AUTH_BASE_URL}/api/slack-integration-models/${id}`,
        {
          data: {
            auth_token: authToken,
            slack_channel_id: channelId,
          },
        }
      );
      return response;
    } catch (e) {
      throw e;
    }
  };
  const addSlackIntegrationData = async (chanelId, authToken) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_AUTH_BASE_URL}/api/slack-integration-models`,
        {
          data: {

              access_token: authToken,
              slack_channel_id: chanelId,
              knowledgebase: props.kb_id,


          },
        }
      );
      return response;
    } catch (e) {
      throw e;
    }
  };

  const handleSlackIntegrationData = async (chanelId, authToken) => {
    try {
      var response;
      if (props.data?.id != null) {
        response = await updateSlackIntegrationData(
          props.data?.id,
          chanelId,
          authToken
        );
      } else {
        response = await addSlackIntegrationData(chanelId, authToken);
      }

      if (
        response.data.data.attributes.access_token &&
        response.data.data.attributes.slack_channel_id
      ) {
        setSlackChannelId(response.data.data.attributes.slack_channel_id);
        setSlackAuthToken(response.data.data.attributes.access_token);

        setIsValidSlackAuthToken(true);
        setIsValidSlackChannelId(true);

        setIsLoading(false);
        dispatch(
          showMessage({
            message: "Your slack integration has been added",
          })
        );
      } else {
        throw new Error("Could not add your slack integration");
      }
    } catch (e) {
      setIsLoading(false);
      setSlackAuthToken("");
      setSlackChannelId("");

      setIsValidSlackAuthToken(false);
      setIsValidSlackChannelId;

      setIsLoading(false);
      dispatch(
        showMessage({
          message:
            "Failure. Your slack integration could not be added. Check your details or reachc out to our team",
        })
      );
    } finally {
    }
  };

  return (
    <div className="settigs-grid-container">
      <div className="page-heading">Slack Integration</div>
      <span className="w-full mt-16 mb-32 p-0.5 bg-grey-300 lg:w-1/3"></span>
      {isLoading ? (
        <LoadingText className />
      ) : (
        <div className="grid  w-full grid-cols-1 gap-y-32 ">
          <div className="settings-grid-item">
            <SettingsTextField
              finalValue={isValidSlackChannelId ? slackChannelId : ""}
              id={formData.slackChannelId.id}
              icon={formData.slackChannelId.icon}
              title={formData.slackChannelId.title}
              description={formData.slackChannelId.description}
              placeholder={formData.slackChannelId.placeholder}
              validate={formData.slackChannelId.validate}
              onChange={(id, value, isValid) => {
                setSlackChannelId(value);
                setIsValidSlackChannelId(isValid);
              }}
            />
          </div>
          <div className="grid-item">
            <SettingsTextField
              passwordMode={true}
              finalValue={isValidSlackAuthToken ? slackAuthToken : ""}
              id={formData.slackAuthToken.id}
              icon={formData.slackAuthToken.icon}
              title={formData.slackAuthToken.title}
              textFielldTip={
                "Once you add your token here. You will not be able to see it again. \
                Save this token safely or retrieve it from your meta dashboard if you want to modify it"
              }
              description={formData.slackAuthToken.description}
              placeholder={formData.slackAuthToken.placeholder}
              validate={formData.slackAuthToken.validate}
              onChange={(id, value, isValid) => {
                setSlackAuthToken(value);
                setIsValidSlackAuthToken(isValid);
              }}
            />
          </div>
          <Button
            disabled={
              isLoading || !isValidSlackAuthToken || !isValidSlackChannelId
            }
            onClick={() => {
              handleFormSubmit();
            }}
            variant="contained"
            color="secondary"
            className="press-button shine-button w-96 h-40 mt-16 rounded-md bg-primary hover:bg-primary text-white"
            aria-label="Save"
            type="submit"
            size="large"
          >
            Save
          </Button>
        </div>
      )}
    </div>
  );
}
}

SlackIntegration.propTypes = {
  data: PropTypes.any,
  kb_id: PropTypes.number,
};
