import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import history from '@history';
import _ from '@lodash';
import { showMessage } from 'app/store/fuse/messageSlice';
import reorder, { reorderQuoteMap } from './reorder';
import { newList, removeList } from './listsSlice';
import { newCard } from './cardsSlice';
import { removeCard } from './cardSlice';

import createAppAsyncThunk from 'app/store/createAppAsyncThunk';

const getBoard = createAppAsyncThunk(
	'scrumboardApp/board/get',
	async (boardId, { dispatch, rejectWithValue }) => {
		try {
			const response = await axios.get(
				`${process.env.REACT_APP_AUTH_BASE_URL}/api/scrumboards/${boardId}?populate[0]=members,lists,knowledgebases,scrumboard_cards&populate[1]=scrumboard_cards.listId`
			);
			const data = response.data.data;
			return data;
		} catch (error) {
			throw error;
			// Error handling code remains unchanged
		}
	}
);
/**
 * Update Board
 */
const updateBoard = createAppAsyncThunk(
	'scrumboardApp/board/update',
	async (newData, { getState }) => {
		try {
			const AppState = getState();
			const board = AppState.scrumboardApp.board.data;
			const response = await axios.put(
				`${process.env.REACT_APP_AUTH_BASE_URL}/api/scrumboards/${board.id}?populate[0]=members,lists,scrumboard_cards&populate[1]=scrumboard_cards.listId`,
				{ data: newData }
			);
			const data = response.data.data;

			return data;
		} catch (e) {
			console.log(e);
		}
	}
);
/**
 * Reorder Board List
 */
const reorderList = createAppAsyncThunk(
	'scrumboardApp/board/reorderList',
	async ({ source, destination }, { dispatch, getState }) => {
		try {
			const AppState = getState();
			const board = AppState.scrumboardApp.board.data;

			const ordered = reorder(
				_.merge([], board.attributes.lists.data),
				source.index,
				destination?.index
			);

			const response = await axios.put(
				`${process.env.REACT_APP_AUTH_BASE_URL}/api/scrumboards/${board.id}?populate[0]=members,lists,scrumboard_cards&populate[1]=scrumboard_cards.listId`,
				{ lists: { connect: ordered } }
			);

			const data = response.data.data;

			dispatch(
				showMessage({
					message: 'List Order Saved',
					autoHideDuration: 2000,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'right',
					},
				})
			);

			return data;
		} catch (e) {
			console.log(e);
		}
	}
);

/**
 * Reorder Board Card
 */
const reorderCard = createAppAsyncThunk(
	'scrumboardApp/board/reorderCard',
	async ({ source, destination, draggableId }, { dispatch, getState }) => {
		try {
			const AppState = getState();
			var board = {};
			board = Object.assign({}, AppState.scrumboardApp.board.data);
			// const board = AppState.scrumboardApp.board.data;

			// const ordered = reorderQuoteMap(_.merge([], board.attributes.lists.data), source, destination);

			const response = await axios.put(
				`${process.env.REACT_APP_AUTH_BASE_URL}/api/scrumboard-cards/${draggableId}?populate[0]=members,listId`,
				{ data: { listId: parseInt(destination.droppableId) } }
			);

			const data = response.data.data;

			dispatch(
				showMessage({
					message: 'Card Order Saved',
					autoHideDuration: 2000,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'right',
					},
				})
			);
			return {
				data: data,
				source: parseInt(source.droppableId),
				destination: parseInt(destination.droppableId),
			};
			// board.attributes.scrumboard_cards = {
			//   ...board.attributes.scrumboard_cards,
			//   data: board.attributes.scrumboard_cards.data.map((s) => (s.id === data.id ? data : s))
			// };
			// board.attributes.scrumboard_cards.data=board.attributes.scrumboard_cards.data.map((s)=>s.id===data.id?data:s);
			// return board;
			// const updatedScrumboardCards = {
			// 	...board.attributes.scrumboard_cards,
			// 	data: board.attributes.scrumboard_cards.data.map((s) =>
			// 		s.id === data.id ? data : s
			// 	),
			// };

			// const updatedAttributes = {
			// 	...board.attributes,
			// 	scrumboard_cards: updatedScrumboardCards,
			// };

			// const updatedBoard = {
			// 	...board,
			// 	attributes: updatedAttributes,
			// };
			// //HotFix
			// dispatch(getBoard(updatedBoard.id));
			// return updatedBoard;
		} catch (e) {
			console.log(e);
		}
	}
);

/**
 * Delete Board
 */
const deleteBoard = createAppAsyncThunk(
	'scrumboardApp/board/delete',
	async (params, { getState }) => {
		const AppState = getState();
		const board = AppState.scrumboardApp.board.data;

		const response = await axios.delete(
			`${process.env.REACT_APP_AUTH_BASE_URL}/api/scrumboards/${board.id}?populate[0]=members,lists,scrumboard_cards&populate[1]=scrumboard_cards.listId`
		);

		history.push({
			pathname: '/apps/scrumboard/board',
		});

		const data = response.data.data;

		return data;
	}
);

// Define other thunk actions (updateBoard, reorderList, reorderCard, deleteBoard) in a similar manner...

const initialState = {
	data: null,
	status: 'idle',
	error: null,
};

const boardSlice = createSlice({
	name: 'scrumboardApp/board',
	initialState,
	reducers: {
		resetBoard: () => {},
		addLabel: (state, action) => {
			if (state.data) {
				state.data.labels = [...state.data.labels, action.payload];
			}
		},
		// other reducer cases remain unchanged
	},
	extraReducers: (builder) => {
		builder
			.addCase(getBoard.fulfilled, (state, action) => {
				state.data = action.payload;
				state.status = 'succeeded';
			})
			.addCase(updateBoard.fulfilled, (state, action) => {
				state.data = action.payload;
			})
			.addCase(reorderList.fulfilled, (state, action) => {
				state.data = action.payload;
			})
			.addCase(reorderCard.fulfilled, (state, action) => {
				var board = Object.assign({}, state.data);
				board.attributes.scrumboard_cards.data =
					board.attributes.scrumboard_cards.data.map((s) =>
						s.id === action.payload.data.id
							? action.payload.data
							: s
					);

				const removeListIndex = _.findIndex(
					board.attributes.lists.data,
					['id', action.payload.source]
				);
				const removeList = board.attributes.lists.data[removeListIndex];
				if (removeList) {
					// Find the index of the card to remove within the list
					const removeCardIndex = _.findIndex(
						removeList.attributes.cards,
						['id', action.payload.data.id]
					);

					if (removeCardIndex !== -1) {
						// Remove the card from the removeList
						const removedCard = removeList.attributes.cards.splice(
							removeCardIndex,
							1
						)[0];

						// Find the list to add the card to
						const addListIndex = _.findIndex(
							board.attributes.lists.data,
							['id', action.payload.destination]
						);
						const addList =
							board.attributes.lists.data[addListIndex];

						if (addList) {
							addList.attributes.cards.push(action.payload.data);
						}
					}
				}
				state.data = board;
			})
			.addCase(deleteBoard.fulfilled, (state) => {
				state = initialState;
			})
			.addCase(removeCard.fulfilled, (state, action) => {
				var board = Object.assign({}, state.data);
				const card = action.payload;
				if (state.data) {
					const removeListIndex = _.findIndex(
						board.attributes.lists.data,
						['id', action.payload.attributes.listId.data.id]
					);
					const removeList =
						board.attributes.lists.data[removeListIndex];
					if (removeList) {
						// Find the index of the card to remove within the list
						const removeCardIndex = _.findIndex(
							removeList.attributes.cards,
							['id', action.payload.id]
						);

						if (removeCardIndex !== -1) {
							// Remove the card from the removeList
							board.attributes.lists.data[
								removeListIndex
							].attributes.cards.splice(removeCardIndex, 1);
							const cardIndex = _.findIndex(
								board.attributes.scrumboard_cards.data,
								['id', action.payload.id]
							);
							board.attributes.scrumboard_cards.data.splice(
								cardIndex,
								1
							);
						}
					}
				}
				state.data = board;
			})
			.addCase(removeList.fulfilled, (state, action) => {
				const listId = action.payload;
				if (state.data) {
					// state.data.attributes.lists.data = _.reject(state.data.attributes.lists.data, {
					// 	id: listId,
					// });
					const data= {...state.data};
					const list = [...data.attributes.lists.data];
					_.remove(list, (obj) => obj.id === listId);
					data.attributes.lists.data=[...list];
					state.data=data;
				}
			})
			.addCase(newList.fulfilled, (state, action) => {
				if (state.data) {
					state.data.attributes.lists.data = [
						...state.data.attributes.lists.data,
						{
							id: action.payload.id,
							attributes:{
								cards: [],
							}
						},
					];
				}
			})
			.addCase(newCard.fulfilled, (state, action) => {
				const cardData = action.payload;

				if (state.data) {
					var board = Object.assign({}, state.data);
					board.attributes.scrumboard_cards.data.push(action.payload);
					const removeListIndex = _.findIndex(
						board.attributes.lists.data,
						['id', action.payload.attributes.listId.data.id]
					);
					board.attributes.lists.data[
						removeListIndex
					].attributes.card.push(action.payload);
					state.data = board;
				}
			});
	},
});

const { resetBoard, addLabel } = boardSlice.actions;

const selectBoard = (state) => {
	console.log(state);
	return state.scrumboardApp.board;
};

export {
	getBoard,
	updateBoard,
	reorderList,
	reorderCard,
	deleteBoard,
	resetBoard,
	addLabel,
	selectBoard,
};

export default boardSlice.reducer;
