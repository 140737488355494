// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.scraped-table { 
	height: 400px; 
	width: 100%; 
}

.scraped-table .MuiDataGrid-row{
	background-color: white !important;
	box-shadow: 3px 3px 10px rgb(0 0 0 / 0.2);
    margin: 5px;
	border-radius: 10px;
	max-width: 97%;
}

.scraped-table .MuiDataGrid-row:hover{
	cursor: pointer;
}

.scraped-table .MuiSvgIcon-root{
	fill: gray;
}

.scraped-table .muiltr-10iwwik-MuiDataGrid-root .MuiDataGrid-cell:focus-within {
    outline: none;
    outline-width: 0px;
    outline-offset: -1px;
}

.purple-styled-button:disabled{
	background-color: lightgrey;
}
`, "",{"version":3,"sources":["webpack://./src/app/main/dashboards/knowledge_base/UrlUploadInput/upload.css"],"names":[],"mappings":"AAAA;CACC,aAAa;CACb,WAAW;AACZ;;AAEA;CACC,kCAAkC;CAClC,yCAAyC;IACtC,WAAW;CACd,mBAAmB;CACnB,cAAc;AACf;;AAEA;CACC,eAAe;AAChB;;AAEA;CACC,UAAU;AACX;;AAEA;IACI,aAAa;IACb,kBAAkB;IAClB,oBAAoB;AACxB;;AAEA;CACC,2BAA2B;AAC5B","sourcesContent":[".scraped-table { \n\theight: 400px; \n\twidth: 100%; \n}\n\n.scraped-table .MuiDataGrid-row{\n\tbackground-color: white !important;\n\tbox-shadow: 3px 3px 10px rgb(0 0 0 / 0.2);\n    margin: 5px;\n\tborder-radius: 10px;\n\tmax-width: 97%;\n}\n\n.scraped-table .MuiDataGrid-row:hover{\n\tcursor: pointer;\n}\n\n.scraped-table .MuiSvgIcon-root{\n\tfill: gray;\n}\n\n.scraped-table .muiltr-10iwwik-MuiDataGrid-root .MuiDataGrid-cell:focus-within {\n    outline: none;\n    outline-width: 0px;\n    outline-offset: -1px;\n}\n\n.purple-styled-button:disabled{\n\tbackground-color: lightgrey;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
