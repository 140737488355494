import { configureStore, applyMiddleware } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { createLogger } from 'redux-logger';
import _ from '@lodash';
import createReducer from './rootReducer';

const middlewares = [];

if (process.env.NODE_ENV === 'development') {
  const logger = createLogger({ collapsed: (getState, action, logEntry) => (logEntry ? !logEntry.error : true) });
  middlewares.push(logger);
}

const store = configureStore({
  reducer: createReducer({}),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false
    }).concat(middlewares),
  devTools: process.env.NODE_ENV === 'development'
});

const asyncReducers = {};

export const injectReducer = (key, reducer) => {
  if (asyncReducers[key]) {
    return false;
  }

  asyncReducers[key] = reducer;

  store.replaceReducer(createReducer(asyncReducers));

  return store;
};

export const injectReducers = (reducers) => {
  store.replaceReducer(createReducer(_.merge(asyncReducers, reducers)));

  return store;
};

export const useAppDispatch = useDispatch;
export const useAppSelector = useSelector;

export default store;
