import React from "react";

const PaginationComponent = ({ currentPage, totalPages, onPageChange }) => {
  const handlePrevPage = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      onPageChange(currentPage + 1);
    }
  };

  return (
    <div className="flex justify-center items-center bg-white space-x-16 h-24 py-24 border-t-1 border-grey-300">
      <button
        onClick={handlePrevPage}
        className={`px-8 py-1 text-md rounded ${
          currentPage === 1
            ? "bg-[#ebebe4] cursor-not-allowed text-grey-500"
            : "bg-white text-primary border-1 border-primary"
        }`}
        disabled={currentPage === 1}
      >
        Previous
      </button>
      <span className="text-grey-700 text-md">
        Page {currentPage} of {totalPages}
      </span>
      <button
        onClick={handleNextPage}
        className={`px-8 py-1 text-md rounded ${
          currentPage === totalPages
            ? "bg-white cursor-not-allowed"
            : "bg-white text-primary border-1 border-primary"
        }`}
        disabled={currentPage === totalPages}
      >
        Next
      </button>
    </div>
  );
};

export default PaginationComponent;
