

export const flattenCardJSON = (originalJSON) => {
	const {
	  attributes,
	  data,
	  ...rest
	} = originalJSON;
  
	const flattenedJSON = {
	  ...rest,
	  ...attributes,
	};
  
	const flattenArray = (arr) => {
	  return arr.map((item) => {
		if (item.attributes) {
		  const { id, attributes } = item;
		  return {
			id,
			...attributes
		  };
		}
		return item;
	  });
	};
  
	if (flattenedJSON.memberIds && Array.isArray(flattenedJSON.memberIds.data)) {
	  flattenedJSON.memberIds = flattenArray(flattenedJSON.memberIds.data);
	}
  
	if (flattenedJSON.activities && Array.isArray(flattenedJSON.activities.data)) {
	  flattenedJSON.activities = flattenArray(flattenedJSON.activities.data);
	}
  
	if (flattenedJSON.labels && Array.isArray(flattenedJSON.labels.data)) {
	  flattenedJSON.labels = flattenArray(flattenedJSON.labels.data);
	}
  
	if (flattenedJSON.listId && flattenedJSON.listId.data && flattenedJSON.listId.data.attributes) {
	  const { id, attributes } = flattenedJSON.listId.data;
	  flattenedJSON.listId = {
		id,
		...attributes
	  };
	}
  
	return flattenedJSON;
  };


  export const flattenJSON = (obj) => {
	const isArray = Array.isArray(obj);
  
	if (isArray) {
	  return obj.map(flattenJSON);
	} else if (obj !== null && typeof obj === 'object') {
	  const {
		attributes,
		data,
		...rest
	  } = obj;
  
	  const flattenedObj = {
		...rest,
	  };
  
	  if ('id' in obj) {
		flattenedObj.id = obj.id;
	  }
  
	  if (attributes) {
		Object.assign(flattenedObj, attributes);
	  }
  
	  if (data && data.attributes) {
		return flattenJSON(data.attributes);
	  }
  
	  for (const key in flattenedObj) {
		if (typeof flattenedObj[key] === 'object') {
		  flattenedObj[key] = flattenJSON(flattenedObj[key]);
		}
	  }
  
	  return flattenedObj;
	}
  
	return obj;
  };