
import FuseUtils from '@fuse/utils/FuseUtils';
import _ from 'lodash';

/**
 * The checklist model.
 */
export const ChecklistModel = (data)=> {
  data = data || {};

  return _.defaults(data, {
    id: FuseUtils.generateGUID(),
    name: '',
    checkItems: [],
  });
}

