import { useEffect, useState, useRef, forwardRef, useImperativeHandle } from 'react';
import { Controller, useForm } from 'react-hook-form';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import * as yup from 'yup';
import _ from 'lodash';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import { yupResolver } from '@hookform/resolvers/yup';

const schema = yup.object().shape({
  name: yup.string().required('You must enter a title'),
});

const CardChecklistName = forwardRef((props, ref) => {
  const { name, onNameChange } = props;
  const [formOpen, setFormOpen] = useState(false);
  const { control, formState, handleSubmit, reset } = useForm({
    mode: 'onChange',
    defaultValues: {
      name,
    },
    resolver: yupResolver(schema),
  });

  const { isValid, dirtyFields } = formState;
  const formRef = useRef(null);

  useEffect(() => {
    if (!formOpen) {
      reset({
        name,
      });
    }
  }, [formOpen, reset, name]);

  useEffect(() => {
    if (formOpen && formRef.current) {
      formRef.current.focus();
    }
  }, [formOpen]);

  useEffect(() => {
    if (!formOpen) {
      reset({
        name,
      });
    }
  }, [formOpen, reset, name]);

  useImperativeHandle(ref, () => ({
    openForm: (ev) => handleOpenForm(ev),
  }));

  function handleOpenForm(ev) {
    ev.stopPropagation();
    setFormOpen(true);
  }

  function handleCloseForm() {
    setFormOpen(false);
  }

  function onSubmit(data) {
    onNameChange(data.name);
    handleCloseForm();
  }

  return formOpen ? (
    <ClickAwayListener onClickAway={handleCloseForm}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="name"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              variant="filled"
              margin="dense"
              autoFocus
              inputRef={formRef}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      type="submit"
                      disabled={_.isEmpty(dirtyFields) || !isValid}
                      size="large"
                    >
                      <FuseSvgIcon className='text-[#7F56D9]'>heroicons-outline:check</FuseSvgIcon>
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
      </form>
    </ClickAwayListener>
  ) : (
    <Typography
      className="cursor-pointer mx-8 text-black text-16 font-regular"
      onClick={handleOpenForm}
    >
      {name}
    </Typography>
  );
});

export default CardChecklistName;
