// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.empty-image {
	
	justify-content: center;
	align-items: center;
	width: 150px; /* Adjust the width and height according to your image size */
	height: 150px;
	background-color: #D9D9D9;
	border-radius: 50%; /* This creates the circular shape */
	overflow: hidden;

}
  
.circular-image {
	width: 80px; /* Adjust the size of the image as needed */
	height: 80px;
	object-fit: contain;
}
`, "",{"version":3,"sources":["webpack://./src/app/shared-components/empty-data/empty-data.css"],"names":[],"mappings":";AACA;;CAEC,uBAAuB;CACvB,mBAAmB;CACnB,YAAY,EAAE,6DAA6D;CAC3E,aAAa;CACb,yBAAyB;CACzB,kBAAkB,EAAE,oCAAoC;CACxD,gBAAgB;;AAEjB;;AAEA;CACC,WAAW,EAAE,2CAA2C;CACxD,YAAY;CACZ,mBAAmB;AACpB","sourcesContent":["\n.empty-image {\n\t\n\tjustify-content: center;\n\talign-items: center;\n\twidth: 150px; /* Adjust the width and height according to your image size */\n\theight: 150px;\n\tbackground-color: #D9D9D9;\n\tborder-radius: 50%; /* This creates the circular shape */\n\toverflow: hidden;\n\n}\n  \n.circular-image {\n\twidth: 80px; /* Adjust the size of the image as needed */\n\theight: 80px;\n\tobject-fit: contain;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
