import { useDebounce } from '@fuse/hooks';
import _ from '@lodash';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import Chip from '@mui/material/Chip';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Autocomplete from '@mui/material/Autocomplete';
import format from 'date-fns/format';
import { Controller, useForm } from 'react-hook-form';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'app/store';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import Box from '@mui/material/Box';
import AutoFixHighSharpIcon from '@mui/icons-material/AutoFixHighSharp';
import EmailIcon from '@mui/icons-material/Email';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import {
	closeCardDialog,
	removeCard,
	selectCardData,
	updateCard,
	selectCardLoading,
} from '../../../store/cardSlice';
import CardActivity from './activity/CardActivity';
import CardChecklist from './checklist/CardChecklist';
import CardComment from './comment/CardComment';
import { selectListById } from '../../../store/listsSlice';
import { selectLabels } from '../../../store/labelsSlice';
import { selectBoard } from '../../../store/boardSlice';
import DueMenu from './toolbar/DueMenu';
import LabelsMenu from './toolbar/LabelsMenu';
import CheckListMenu from './toolbar/CheckListMenu';
import OptionsMenu from './toolbar/OptionsMenu';
import { flattenCardJSON } from 'src/app/utils/json-formater';
import axios from 'axios';
import LoadingSpinner from 'app/shared-components/loading-spinner/loading-spinner';
import { Cancel } from '@mui/icons-material';
import { showMessage } from 'app/store/fuse/messageSlice';

/**
 * The board card form component.
 */
function BoardCardForm() {
	const dispatch = useAppDispatch();
	const { data: board } = useAppSelector(selectBoard);
	const labels = useAppSelector(selectLabels);
	// TODO REplace with list of members
	const members = board.attributes.members.data;
	// useAppSelector(selectMembers);
	const card = useAppSelector(selectCardData);
	const isLoading = useAppSelector(selectCardLoading);

	const list = useAppSelector(
		selectListById(card?.attributes?.listId?.data?.id)
	);

	const { register, watch, control, setValue } = useForm({
		mode: 'onChange',
		defaultValues: card != null ? flattenCardJSON(card) : null,
	});

	const cardForm = watch();

	const updateCardData = useDebounce((newCard) => {
		console.log(newCard);
		dispatch(updateCard(newCard));
	}, 600);
	const getDifferentProperties = (obj1, obj2) => {
		const differingProperties = {};

		for (const key in obj2) {
			if (!_.isEqual(obj1[key], obj2[key])) {
				differingProperties[key] = obj2[key];
			}
		}

		return differingProperties;
	};

	useEffect(() => {
		if (!card) {
			return;
		}
		///TODO disabled auto update
		// if (!_.isEqual(flattenCardJSON(card), cardForm)) {
		// 	console.log(getDifferentProperties(card,cardForm));
		// 	if(!_.isEmpty(getDifferentProperties(card,cardForm))){
		// 		// TODO Need to handle equal case
		// 		updateCardData(getDifferentProperties(cardForm));
		// 	}
		// }
	}, [card, cardForm, updateCardData]);

	useEffect(() => {
		register('attachmentCoverId');
	}, [register]);

	if (!card && !board) {
		return null;
	}
	const addNewComment = async (comment) => {
		try {
			const response = await axios.post(
				`${process.env.REACT_APP_AUTH_BASE_URL}/api/scrumboard-comments`,
				{
					data: {
						scrumboard_card: card.id,
						message: comment.message,
						type: comment.type,
					},
				}
			);
			const data = response.data;
		} catch (e) {
			console.log(e);
		}
	};

	return (
		<DialogContent className="flex flex-col sm:flex-row p-8">
			<div className="flex flex-auto flex-col py-16 px-0 sm:px-16">
				<div className="flex flex-col sm:flex-row sm:justify-between justify-center items-center mb-24">
					<div>
					<div className="mb-16 sm:mb-0 flex items-center">
					{card?.attributes.ticketId&&(<Typography className="font-bold text-20 mr-4">
						{`${card?.attributes.ticketId} : `}
						</Typography>)}

						

						<Typography className="font-bold text-20 ml-4">
							{list && list?.attributes.title}
						</Typography>
					</div>
					{list?.attributes.createdAt&&(<div className='text-gray'>
						Created: {format(new Date(card?.attributes.createdAt),'dd-MM-yyyy')}
					</div>)}
					</div>

					<DateTimePicker
						className="w-auto"
						vaiant="filled"
						placeholder="Choose a due date"
						value={
							cardForm.dueDate == null
								? null
								: new Date(cardForm.dueDate)
						}
						format="Pp"
						onChange={(val) => {
							setValue(
								'dueDate',
								format(val, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx")
							);
						}}
						renderInput={(props) => (
							<TextField
								{...props}
								label="Due date"
								placeholder="Choose a due date"
								InputLabelProps={{
									shrink: true,
									style: { color: 'black' },
								}}
								style={{
									backgroundColor: '#FFFBFF',
									borderRadius: '8px',
								}}
								fullWidth
								variant="outlined"
							/>
						)}
					/>
				</div>

				<div className="flex-1 items-center mb-12 flex-col">
					<div className="flex items-center mt-16 mb-8">
						<Typography className="font-regular text-16 mx-4 text-black">
							Title
						</Typography>
					</div>
					<Controller
						name="title"
						control={control}
						render={({ field }) => (
							<TextField
								{...field}
								type="text"
								variant="outlined"
								fullWidth
								required
								style={{
									backgroundColor: '#FFFBFF',
									borderRadius: '8px',
								}}
								inputProps={{
									disableUnderline: true, // Disables the default underline to use modern border
									style: {
										backgroundColor: '#FFFBFF',
										paddingRight: '4px',
										paddingBottom: '2px',
										paddingTop: '2px', // Updated padding for a better look and feel
										borderRadius: '8px', // Rounded corners for a modern look
										fontWeight: 500,
										fontSize: '1.5rem', // Base font size
									},
								}}

								// Add more props as needed
							/>
						)}
					/>
				</div>

				<div className="w-full mb-12">
					<div className="flex items-center mt-16 mb-8">
						<Typography className="font-regular text-16 mx-4 text-black">
							Description
						</Typography>
					</div>
					<Controller
						name="description"
						control={control}
						render={({ field }) => (
							<TextField
								{...field}
								multiline
								rows="4"
								variant="outlined"
								style={{
									backgroundColor: '#FFFBFF',
									borderRadius: '8px',
								}}
								inputProps={{
									disableUnderline: true, // Disables the default underline to use modern border
									style: {
										lineHeight: '2rem',
										backgroundColor: 'transparent',
										paddingRight: '4px',
										paddingBottom: '2px',
										paddingTop: '2px', // Updated padding for a better look and feel
										borderRadius: '8px', // Rounded corners for a modern look
										fontWeight: 400,
										fontSize: '1.4rem', // Base font size
									},
								}}
								fullWidth
							/>
						)}
					/>
				</div>
				{(card?.attributes?.contact_booking?.data?.attributes?.phone_number||card?.attributes?.contact_booking?.data?.attributes?.email)&&(<div >
					<div className="w-full mb-12">
						<div className="flex items-center mt-16 mb-8">
							<Typography className="font-regular text-16 mx-4 text-black">
								
								Contact Details
							</Typography>

						</div>
						{card?.attributes?.contact_booking?.data?.attributes?.phone_number&&<div className='flex items-center'>
							<Typography className="font-regular text-16 mx-4 text-black">
							<WhatsAppIcon
									fontSize="12px"
									className="text-base-purple"
								/>
							</Typography>
							<TextField
								value={card?.attributes?.contact_booking?.data?.attributes?.phone_number}
								className="ai-assesment rounded-lg"
								variant="filled"
								inputProps={{
									disableUnderline: true, // Disables the default underline to use modern border
									style: {
										lineHeight: '2rem',
										paddingRight: '4px',
										paddingBottom: '2px',
										paddingTop: '2px', // Updated padding for a better look and feel
										fontWeight: 400,
										fontSize: '1.4rem', // Base font size
									},
								}}
								fullWidth
								disabled
							/>
							
							</div>}
							{card?.attributes?.contact_booking?.data?.attributes?.email&&<div className='flex items-center'>
							<Typography className="font-regular text-16 mx-4 text-black">
							<EmailIcon
									fontSize="12px"
									className="text-base-purple"
								/>
							</Typography>
							<TextField
								value={card?.attributes?.contact_booking?.data?.attributes?.email}
								className="ai-assesment rounded-lg"
								variant="filled"
								inputProps={{
									disableUnderline: true, // Disables the default underline to use modern border
									style: {
										lineHeight: '2rem',
										paddingRight: '4px',
										paddingBottom: '2px',
										paddingTop: '2px', // Updated padding for a better look and feel
										fontWeight: 400,
										fontSize: '1.4rem', // Base font size
									},
								}}
								fullWidth
								disabled
							/>
							
							</div>}
						</div>
				</div>)}
				{cardForm.ai_assesment && (
					<div className="w-full mb-12">
						<div className="flex items-center mt-16 mb-8">
							<Typography className="font-regular text-16 mx-4 text-black">
								<AutoFixHighSharpIcon
									fontSize="12px"
									className="mr-10 text-base-purple"
								/>
								Ai Assesment
							</Typography>
						</div>
						<Controller
							name="ai_assesment"
							control={control}
							render={({ field }) => (
								<TextField
									{...field}
									multiline
									rows="4"
									className="ai-assesment rounded-lg"
									variant="filled"
									inputProps={{
										disableUnderline: true, // Disables the default underline to use modern border
										style: {
											lineHeight: '2rem',
											paddingRight: '4px',
											paddingBottom: '2px',
											paddingTop: '2px', // Updated padding for a better look and feel
											fontWeight: 400,
											fontSize: '1.4rem', // Base font size
										},
									}}
									fullWidth
									disabled
								/>
							)}
						/>
					</div>
				)}

				{cardForm.labels && cardForm.labels.length > 0 && (
					<div className="flex-1 mb-24 mx-4">
						<div className="flex items-center mt-16 mb-8">
							<Typography className="font-regular text-16 mx-4 text-black">
								Labels
							</Typography>
						</div>
						<Autocomplete
							className="mt-8"
							style={{
								backgroundColor: '#FFFBFF',
								borderRadius: '8px',
								border: '1px', // subtle border for the whole Autocomplete
							}}
							multiple
							freeSolo
							options={labels.map((label) => (

								label.attributes.title
							))}
							getOptionLabel={(option) => option.title || option}
							value={cardForm.labels}
							onChange={(event, value) => {
								if(typeof value[value.length-1] === 'string'){
									const label =labels.find((l) => l.attributes.title.toLowerCase()==value[value.length-1]);
									if(label){
									value[value.length-1]= {id:label.id,...label.attributes};
									setValue('labels', value);
									}else{
										dispatch(showMessage({message:"Label is not available"}));
									}
								}else{
								setValue('labels', value);
								}
							}}
							renderTags={(value, getTagProps) =>
								value.map((option, index) => (
									<Chip
										label={
											typeof option === 'string'
												? option
												: option.title
										}
										{...getTagProps({ index })}
										className=""
										deleteIcon={
											<Cancel
												style={{ color: 'white' }}
											/>
										} // Uniform margin for chips
										style={{
											fontWeight: 500,
											color: 'white',
											backgroundColor: '#7F56D9',
											margin: '6px', // Add some space around chips
											borderRadius: '4px', // Consistent rounded corners with the Autocomplete
										}}
									/>
								))
							}
							renderInput={(params) => (
								<TextField
									{...params}
									placeholder="Add a label"
									variant="outlined"
									inputProps={{
										...params.inputProps,
										style: {
											paddingTop: '6px',
											paddingLeft: '6px',
										},
									}}
									InputProps={{
										...params.InputProps,
										disableUnderline: true,
										style: {
											backgroundColor: 'transparent',
											paddingLeft: '12px', // Uniform padding for the input
											border: 'none', // Remove individual border from input field
											boxShadow: 'none', // Avoid any shadow in the input field
										},
									}}
									InputLabelProps={{
										style: {
											paddingLeft: '12px', // Align label text with input text
										},
									}}
								/>
							)}
						/>
					</div>
				)}
				{/* TODO: MEMBERS and attachments are disabled */}

				{/* {cardForm.memberIds && cardForm.memberIds.length > 0 && (
					<div className="flex-1 mb-24 mx-8">
						<div className="flex items-center mt-16 mb-12">
							<FuseSvgIcon size={20} className='text-[#7F56D9]'>heroicons-outline:users</FuseSvgIcon>
							<Typography className="font-medium text-14 mx-4 text-[#7F56D9]">Members</Typography>
						</div>
						<Autocomplete
							className="mt-4 mb-16"
							multiple
							freeSolo
							options={members.map(m=>{return{id:m.id,...m.attributes};})}
							getOptionLabel={(member) => {
								return typeof member === 'string' ? member : member?.username;
							}}
							value={cardForm.memberIds}
							onChange={(event, value) => {
								const ids = value
									.filter((item) => typeof item !== 'string')
									.map((item) => item.id);
								setValue('memberIds', ids);
							}}
							renderTags={(value, getTagProps) =>
								value.map((option, index) => {
									if (typeof option === 'string') {
										return <span />;
									}
									return (
										<Chip
											label={option.username}
											{...getTagProps({ index })}
											className={clsx('m-3 mb-16', option?.class)}
											avatar={
												<Tooltip title={option.username}>
													<Avatar src={option.avatar??''} />
												</Tooltip>
											}
										/>
									);
								})
							}
							renderInput={(params) => (
								<TextField
									{...params}
									placeholder="Select multiple Members"
									
									variant="filled"
									InputLabelProps={{
										shrink: true
									}}
								/>
							)}
						/>
					</div>
				)}

				{cardForm.attachments && cardForm.attachments.length > 0 && (
					<div className="mb-24">
						<div className="flex items-center mt-16 mb-12">
						
							<FuseSvgIcon size={20} className='text-[#7F56D9]'>heroicons-outline:paper-clip</FuseSvgIcon>
							<Typography className="font-medium text-14 mx-4 text-[#7F56D9]">Attachments</Typography>
						</div>
						<div className="flex flex-col sm:flex-row flex-wrap -mx-16">
							{cardForm.attachments.map((item) => (
								<CardAttachment
									item={item}
									card={cardForm}
									makeCover={() => {
										setValue('attachmentCoverId', item.id);
									}}
									removeCover={() => {
										setValue('attachmentCoverId', '');
									}}
									removeAttachment={() => {
										setValue('attachments', _.reject(cardForm.attachments, { id: item.id }));
									}}
									key={item.id}
								/>
							))}
						</div>
					</div>
				)} */}

				{cardForm.checklists &&
					cardForm.checklists.map((checklist, index) => (
						<CardChecklist
							key={checklist.id}
							checklist={checklist}
							index={index}
							onCheckListChange={(item, itemIndex) => {
								setValue(
									'checklists',
									_.setIn(
										cardForm.checklists,
										`[${itemIndex}]`,
										item
									)
								);
							}}
							onRemoveCheckList={() => {
								setValue(
									'checklists',
									_.reject(cardForm.checklists, {
										id: checklist.id,
									})
								);
							}}
						/>
					))}
				<button
					className="shine-button border border-[#7F56D9] rounded-md bg-base-purple text-white min-h-32 max-h-32 w-fit px-10 ml-16"
					onClick={(e) => {
						if (!_.isEqual(flattenCardJSON(card), cardForm)) {
							if (
								!_.isEmpty(
									getDifferentProperties(
										flattenCardJSON(card),
										cardForm
									)
								)
							) {
								// TODO Need to handle equal case
								updateCardData(
									getDifferentProperties(
										flattenCardJSON(card),
										cardForm
									)
								);
							}
						}
					}}
					disabled={isLoading}
				>
					{' '}
					{isLoading ? (
						<LoadingSpinner
							className="text-white"
							size={20}
						/>
					) : (
						'Save'
					)}
				</button>

				<div className="mb-24">
					<div className="flex items-center mt-24 mb-12">
						<Typography className="font-regular text-16 mx-8 text-black">
							Comment
						</Typography>
					</div>
					<div>
						<CardComment
							onCommentAdd={(comment) => {
								addNewComment(comment);
								return setValue('activities', [
									comment,
									...cardForm.activities,
								]);
							}}
						/>
					</div>
				</div>

				<Controller
					name="activities"
					control={control}
					defaultValue={[]}
					render={({ field: { value } }) => (
						<div>
							{value.length > 0 && (
								<div className="mb-24">
									<div className="flex items-center mt-16">
										<Typography className="font-regular text-16 mx-8">
											Activity
										</Typography>
									</div>
									<List>
										{value.map((item) => (
											<CardActivity
												item={item}
												key={item.id}
											/>
										))}
									</List>
								</div>
							)}
						</div>
					)}
				/>
			</div>

			<div className="flex order-first sm:order-last items-start sticky top-0">
				<Box
					className="flex flex-row sm:flex-col items-center sm:py-8 rounded-12 w-full"
					sx={{ backgroundColor: 'background.default' }}
				>
					<IconButton
						className="order-last sm:order-first"
						color="inherit"
						onClick={() => dispatch(closeCardDialog())}
						size="large"
					>
						<FuseSvgIcon>heroicons-outline:x</FuseSvgIcon>
					</IconButton>
					<div className="flex flex-row items-center sm:items-start sm:flex-col flex-1">
						<Controller
							name="dueDate"
							control={control}
							render={({ field: { onChange, value } }) => (
								<DueMenu
									onDueChange={onChange}
									onRemoveDue={() => onChange(null)}
									dueDate={value}
								/>
							)}
						/>

						<Controller
							name="labels"
							control={control}
							defaultValue={[]}
							render={({ field: { onChange, value } }) => (
								<LabelsMenu
									onToggleLabel={(label) => {
										// Create a new array from the current value
										let updatedLabels = [...value];

										// Check if the label already exists in the array
										const index = updatedLabels.findIndex(
											(l) => l.id === label.id
										);

										if (index === -1) {
											// If the label doesn't exist, add it to the array
											updatedLabels.push({
												id: label.id,
												...label.attributes,
											});
										} else {
											// If the label exists, remove it from the array
											updatedLabels.splice(index, 1);
										}

										// Call the onChange with the updated array
										onChange(updatedLabels);
									}}
									labels={value}
								/>
							)}
						/>
						{/* TODO: MEMBERS and attachments are disabled */}

						{/* <Controller
							name="memberIds"
							control={control}
							defaultValue={[]}
							render={({ field: { onChange, value } }) => (
								<MembersMenu
									onToggleMember={(member) => onChange(_.xor(value, [{id:member.id,...member.attributes}]))}
									memberIds={value}
								/>
							)}
						/>

						<Controller
							name="attachments"
							control={control}
							defaultValue={[]}
							render={() => (
								<IconButton size="large">
									<FuseSvgIcon>heroicons-outline:paper-clip</FuseSvgIcon>
								</IconButton>
							)}
						/> */}

						<Controller
							name="checklists"
							control={control}
							defaultValue={[]}
							render={({ field: { onChange } }) => (
								<CheckListMenu
									onAddCheckList={(newList) => {
										return onChange([
											...cardForm.checklists,
											newList,
										]);
									}}
								/>
							)}
						/>

						<OptionsMenu
							onRemoveCard={() => dispatch(removeCard())}
						/>
					</div>
				</Box>
			</div>
		</DialogContent>
	);
}

export default BoardCardForm;
