import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { RootStateType } from 'app/store/types';
import createAppAsyncThunk from 'app/store/createAppAsyncThunk';
import ListModel from '../models/ListModel';

/**
 * Get Board Lists
 */
const getLists = createAppAsyncThunk(
	'scrumboardApp/lists/get',
	async (boardId) => {
		try {
			const response = await axios.get(
				`${process.env.REACT_APP_AUTH_BASE_URL}/api/scrumboard-lists?filters[boardId][id][$eq]=${boardId}`
			);
			const data = response.data.data;
			return data;
		} catch (e) {
			throw e;
		}
	}
);

/**
 * Create List
 */
const newList = createAppAsyncThunk(
	'scrumboardApp/lists/new',
	async (list, { getState }) => {
		const AppState = getState();
		const board = AppState.scrumboardApp.board.data;
		list['boardId'] = parseInt(board.id);
		try {
			const response = await axios.post(
				`${process.env.REACT_APP_AUTH_BASE_URL}/api/scrumboard-lists`,
				{ data:  list  }
			);

			const data = response.data.data;

			return data;
		} catch (e) {
			console.log(e);
		}
	}
);

/**
 * Update list
 */
const updateList = createAppAsyncThunk(
	'scrumboardApp/lists/update',
	async ({ id, newData }, { getState }) => {
		const AppState = getState();
		const board = AppState.scrumboardApp.board.data;

		const response = await axios.put(
			`{process.env.REACT_APP_AUTH_BASE_URL}/api/scrumboard-lists/${id}`,
			newData
		);

		const data = response.data.data;

		return data;
	}
);

/**
 * Remove list
 */
const removeList = createAppAsyncThunk(
	'scrumboardApp/lists/remove',
	async (id, { getState }) => {
		const AppState = getState();
		const board = AppState.scrumboardApp.board.data;
try{
		const response = await axios.delete(
			`${process.env.REACT_APP_AUTH_BASE_URL}/api/scrumboard-lists/${id}`
		);

		return id;
}catch(e){
	console.log(e);
}
	}
);

const listsAdapter = createEntityAdapter({});
const initialState = listsAdapter.getInitialState({});

const { selectAll: selectLists, selectById } = listsAdapter.getSelectors(
	(state) => state.scrumboardApp.lists
);

/**
 * The Scrumboard Lists Slice.
 */
const listsSlice = createSlice({
	name: 'scrumboardApp/lists',
	initialState,
	reducers: {
		resetLists: () => initialState,
	},
	extraReducers: (builder) => {
		builder
			.addCase(getLists.fulfilled, (state, action) =>
				listsAdapter.setAll(state, action.payload)
			)
			.addCase(newList.fulfilled, (state, action) =>
				listsAdapter.addOne(state, action.payload)
			)
			.addCase(updateList.fulfilled, (state, action) =>
				listsAdapter.upsertOne(state, action.payload)
			)
			.addCase(removeList.fulfilled, (state, action) =>
				listsAdapter.removeOne(state, action.payload)
			);
	},
});

const { resetLists } = listsSlice.actions;

const selectListById = (id) => (state) => selectById(state, id);

export default listsSlice.reducer;
export {
	getLists,
	newList,
	updateList,
	removeList,
	resetLists,
	selectLists,
	selectListById,
};
