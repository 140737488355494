import clsx from 'clsx';
import React, { useState, useEffect } from 'react';

export default function SettingsTextField(props) {
    const { id, icon, title, description, placeholder, validate, onChange, finalValue, textFielldTip, passwordMode=false, isMultilineMode=false, className } = props;

    const [value, setValue] = useState(''); // the current value of the text field
    const [isValid, setIsValid] = useState(false);

    useEffect(() => {
        if (finalValue !== undefined && finalValue !== value) {
            setValue(finalValue);
            const finalValueIsValid = validate(finalValue);
            setIsValid(finalValueIsValid);
        }
    }, [finalValue]);

    const handleInputChange = (event) => {
        const newValue = event.target.value;
        const isValid = validate(newValue);
        setIsValid(isValid);
        setValue(newValue);
        onChange(id, newValue, isValid);
    };

    const inputType = passwordMode ? 'password' : 'text';

    return (
        <div className={clsx(className,'flex items-start')}>
            <div className="left-side">
                <img src={icon} className="w-36 h-36" alt="Icon" />
                <div className="mt-16 ml-2 widget-label-medium">{title}</div>
                <div className="ml-2 mt-8 widget-label-regular w-3/5">{description}</div>
            </div>
            <div className="right-side">
                <div className="h-36"></div>
                {isMultilineMode?
                <textarea 
                type={inputType}  
                className={`w-11/12 settings-text-field mt-16 ${isValid ? 'valid' : 'invalid'}`}
                placeholder={placeholder} 
                rows={4}
                value={value === null ? undefined : value} 
                onChange={handleInputChange}
            />
                :
                <input 
                    type={inputType}  
                    className={`settings-text-field mt-16 ${isValid ? 'valid' : 'invalid'}`}
                    placeholder={placeholder} 
                    value={value} 
                    onChange={handleInputChange}
                />
}
                <div className="ml-2 mt-8 w-3/5 widget-label-regular text-sm ">{textFielldTip}</div>
            </div>
        </div>
    );
}
