import { yupResolver } from '@hookform/resolvers/yup';
import Fab from '@mui/material/Fab';
import ListItem from '@mui/material/ListItem';
import TextField from '@mui/material/TextField';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import _ from '@lodash';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import { ChecklistItemModel } from '../../../../models/ChecklistItemModel';


/**
 * Form Validation Schema
 */
const schema = yup.object().shape({
  name: yup.string().required('You must enter a title')
});

function CardAddChecklistItem(props) {
  const { name = '', onListItemAdd } = props;
  const { control, formState, handleSubmit, reset } = useForm({
    mode: 'onChange',
    defaultValues: {
      name
    },
    resolver: yupResolver(schema)
  });

  const { isValid, dirtyFields } = formState;

  function onSubmit(data) {
    onListItemAdd(ChecklistItemModel(data));
    reset({
      name
    });
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ListItem className="px-0 pl-16 pr-8" dense>
        <span className="w-40" />
        <Controller
          name="name"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <TextField
              {...field}
              className="flex flex-1 mx-8"
              name="name"
              variant="outlined"
              placeholder="Add an item"
              style={{
                backgroundColor:'#FFFBFF',
                borderRadius:"8px",
                
              }}
                      inputProps={{
                        disableUnderline: true, // Disables the default underline to use modern border
                        style: {
                backgroundColor:'#FFFBFF',
                paddingRight:"4px",
                paddingBottom:"2px",
                          paddingTop:"2px", // Updated padding for a better look and feel
                          borderRadius: "8px", // Rounded corners for a modern look
                          fontWeight: 400,
                          fontSize: "1.4rem", // Base font size
                        },
                      }}
              InputProps={{
                style: {
                
                }
              }}
            />
          )}
        />
        <Fab
        className="mx-4"
        aria-label="Add"
        size="small"
        type="submit"
        disabled={_.isEmpty(dirtyFields) || !isValid}
        style={{
          backgroundColor: '#7F56D9', // This sets the background color of the Fab
        }}
      >
        <FuseSvgIcon 
          size={20} 
          style={{
            color: 'white', // This sets the color of the icon
            // If FuseSvgIcon supports 'backgroundColor', uncomment the next line
            backgroundColor: 'transparent',
          }}
        >
          heroicons-outline:plus
        </FuseSvgIcon>
      </Fab>

      </ListItem>
    </form>
  );
}

export default CardAddChecklistItem;
