function CTAButon({ text, image, onPress }) {
  return (
    <button
      onClick={onPress}
      className="flex shadow-md flex-row py-8 px-16 items-center justify-center bg-transparent border border-[#473c4d] hover:bg-base-purple hover:text-white text-base-purple font-medium rounded h-36 group"
    >
      {image && <img
        src={image}
        className="mr-8 p-4 w-24 h-24 bg-black rounded-full transition-colors duration-300  group-hover:bg-base-purple"
      />}
      <span className="text-black font-regular text-md group-hover:text-white">{text}</span>
    </button>
  );
}

export default CTAButon;


