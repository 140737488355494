import React, { useState } from "react";
import * as PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import './source-card.css'
import { ArrowForward } from "@mui/icons-material";
import { ReactSVG } from "react-svg";
export default function SourceCard(props) {
return (
	<div className="relative">
		<div onClick={props.onClick} className="relative flex flex-row bg-[#F9F9F9] border border-solid border-[#E6E6E6] rounded-xl p-12 place-content-between ">
			<div className="flex flex-col ml-20">
				<Typography variant="h6" gutterBottom>
					{props.title}
				</Typography>
				<div className="text-[#4D4D4D]">{ props.description }</div>
			</div>
			<ArrowForward className="self-center text-base-purple"/>
		</div>
		<div className="icon-container">
			<ReactSVG  className="icon rounded-[36px] bg-[#F9F9F9] border border-solid border-[#E6E6E6]" src={props.icon} alt="logo"/>
		</div>
	</div>
)}
SourceCard.propTypes = {
	title: PropTypes.string,
	description: PropTypes.string,
	icon: PropTypes.string,
	onClick: PropTypes.func
}
