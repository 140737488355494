import { lazy } from 'react';
import { Navigate } from 'react-router-dom';

const SuccessPage = lazy(() => import('./SuccessPage'));
const FailurePage = lazy(() => import('./FailurePage'));

const paymentPagesConfig = {
  settings: {
    layout: {
      config: {
        navbar: {
          display: true,
        },
        footer: {
          display: false,
        },
        toolbar: {
          display: false,
        },
      },
    },
  },
  routes: [
    {
      path: 'pages/payment',
      children: [
        {
          path: 'success',
          element: <SuccessPage/>,
        },
        {
          path: 'failed',
          element: <FailurePage />,
        },

      ],
    },
  ],
};

export default paymentPagesConfig;
