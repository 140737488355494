// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.muiltr-73lb2f-MuiPaper-root-MuiDialog-paper{
    max-width: 800px;
}

.modal-card:hover{
    cursor: pointer;
}

.button-div{
    background: #F7F7F8;
    border-radius: 10px;
}

.button-div:hover{
    cursor: default;
}

.close-button{
    position: absolute;
    right: 20px;
    top: 10px;
    background-color: #E71D361d;
    color: #E71D36;
	border-radius: 50%;
	height: 35px;
	width: 35px;
	text-align: center;
    padding: 6px;
    margin: auto;
    cursor: pointer;
    text-transform: capitalize;
}

.flie-list{
    box-shadow: 1px 6px 22px rgba(3, 2, 41, 0.07);
    border-radius: 10px;
    padding: 15px;
    border: none;
}
`, "",{"version":3,"sources":["webpack://./src/app/main/dashboards/knowledge_base/main-modal.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,mBAAmB;IACnB,mBAAmB;AACvB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,SAAS;IACT,2BAA2B;IAC3B,cAAc;CACjB,kBAAkB;CAClB,YAAY;CACZ,WAAW;CACX,kBAAkB;IACf,YAAY;IACZ,YAAY;IACZ,eAAe;IACf,0BAA0B;AAC9B;;AAEA;IACI,6CAA6C;IAC7C,mBAAmB;IACnB,aAAa;IACb,YAAY;AAChB","sourcesContent":[".muiltr-73lb2f-MuiPaper-root-MuiDialog-paper{\n    max-width: 800px;\n}\n\n.modal-card:hover{\n    cursor: pointer;\n}\n\n.button-div{\n    background: #F7F7F8;\n    border-radius: 10px;\n}\n\n.button-div:hover{\n    cursor: default;\n}\n\n.close-button{\n    position: absolute;\n    right: 20px;\n    top: 10px;\n    background-color: #E71D361d;\n    color: #E71D36;\n\tborder-radius: 50%;\n\theight: 35px;\n\twidth: 35px;\n\ttext-align: center;\n    padding: 6px;\n    margin: auto;\n    cursor: pointer;\n    text-transform: capitalize;\n}\n\n.flie-list{\n    box-shadow: 1px 6px 22px rgba(3, 2, 41, 0.07);\n    border-radius: 10px;\n    padding: 15px;\n    border: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
