import React, { useState } from 'react';
import SettingsTextField from '../../../../shared-components/forms/settingsTextField';
import { validatePhoneNumber } from 'src/app/utils/validations';
import { Button } from '@mui/material';
import PropTypes from 'prop-types';
import aesEncrypt from '../../../../utils/encryptions';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import LoadingText from '../LoadingText';
import { showMessage } from 'app/store/fuse/messageSlice';
import MultiSelectDropdown from 'app/shared-components/forms/muti-select-dropdown';
import { selectUser } from 'app/store/userSlice';

export default function EmailIntegration(props) {
	const [isLoading, setIsLoading] = useState(false);
	const [selectedUsernames, setSelectedUsernames] = useState([]);
	const updateEmailIntegrationData = async (id) => {
		try {
			const userIds =
				props.data.attributes.organization.data.attributes.users.data
					.filter((user) =>
						selectedUsernames.includes(user.attributes.username)
					)
					.map((user) => ({ id: user.id }));
			const response = await axios.put(
				`${process.env.REACT_APP_AUTH_BASE_URL}/api/email-integrations/${id}?populate[0]=users`,
				{
					data: {
						users: {
							set: userIds,
						},
					},
				}
			);
			props.data.attributes.email_integration.data.attributes.users.data =
				response.data.data.attributes.users.data;
			return response;
		} catch (e) {
			throw e;
		} finally {
			setIsLoading(false);
		}
	};
	const addEmailIntegrationData = async () => {
		try {
			const userIds =
				props.data.attributes.organization.data.attributes.users.data
					.filter((user) =>
						selectedUsernames.includes(user.attributes.username)
					)
					.map((user) => ({ id: user.id }));
			const response = await axios.post(
				`${process.env.REACT_APP_AUTH_BASE_URL}/api/email-integrations?populate[0]=users`,
				{
					data: {
						users: {
							connect: userIds,
						},
						knowledgebase: props.kb_id,
					},
				}
			);
			props.data.attributes.email_integration.data.attributes.users.data =
				response.data.data.attributes.users.data;
			return response;
		} catch (e) {
			throw e;
		} finally {
			setIsLoading(false);
		}
	};

	const handleFormSubmit = async () => {
		setIsLoading(true);
		if (selectedUsernames.length > 0) {
			if (props.data.attributes.email_integration?.data) {
				updateEmailIntegrationData(
					props.data.attributes.email_integration.data.id
				);
			} else {
				addEmailIntegrationData();
			}
		}
	};

	const handleSelectectionUpdate = (users) => {
		setSelectedUsernames(users);
	};

	return (
		<div className="settigs-grid-container">
			<div className="page-heading">Email Integration</div>
			<span className="w-full mt-16 mb-32 widget-label-regular">
				Enter the emails of the users you would like notified when a ticket or a lead is created
			</span>
			{isLoading ? (
				<LoadingText className />
			) : (
				<div className="grid  w-full grid-cols-1 gap-y-32 ">
					<div className="settings-grid-item">
						<MultiSelectDropdown
							options={props.data.attributes.organization?.data?.attributes?.users?.data?.map(
								(u) => u.attributes.username
							)??[]}
							defaultValues={
								props.data.attributes.email_integration?.data
									? props.data.attributes?.email_integration.data?.attributes?.users?.data.map(
											(u) => u.attributes.username
									  )
									: []
							}
							onSubmit={(e) => handleSelectectionUpdate(e)}
						/>
					</div>

					<Button
						disabled={isLoading}
						onClick={() => {
							handleFormSubmit();
						}}
						variant="contained"
						color="secondary"
						className="press-button shine-button w-96 h-40 mt-16 rounded-md bg-primary hover:bg-primary text-white"
						aria-label="Save"
						// disabled={_.isEmpty(dirtyFields) || !isValid}
						type="submit"
						size="large"
					>
						Save
					</Button>
				</div>
			)}
		</div>
	);
}

EmailIntegration.propTypes = {
	data: PropTypes.any,
	kb_id: PropTypes.number,
};
