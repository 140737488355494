import { lazy } from 'react';

const OrganisationDashboardApp = lazy(() => import('./OrganisationDashboardApp'));

const OrganisationConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'dashboards/organisation',
      element: <OrganisationDashboardApp />,
    },
  ],
};

export default OrganisationConfig;
