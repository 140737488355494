import { Avatar, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import { useState } from "react";
import { PlayTrack } from "./play-track";
import AlbumCard from "app/shared-components/cards/albumCard";

export function PlaylistTable({ tracks }) {
  const [activeTrack, setActiveTrack] = useState(null);

  return (
    <Paper className="w-full text-black rounded-none p-4  bg-main-bg">
      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 xl:grid-cols-4 2xl:grid-cols-5 3xl:grid-cols-6 gap-16 p-16">
        {tracks?.map((track) => (
          <AlbumCard album={track} key={track.id} />
        ))}
      </div>
    </Paper>
  );
}
