import {
  Box,
  Drawer,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  selectCurrentTrack,
  selectShowPlayerView,
  setShowPlayerView,
} from "app/store/playerSlice";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { IoClose } from "react-icons/io5";
import { Tracks } from "./tracks";

export function PlayerViewDrawer() {
  const currentTrack = useSelector(selectCurrentTrack);
  const showPlayerView = useSelector(selectShowPlayerView);
  const dispatch = useDispatch();
  const theme = useTheme();

  const isSmallerScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const handleClose = () => {
    dispatch(setShowPlayerView(false));
  };
  return (
    <Drawer
      anchor={isSmallerScreen ? "bottom" : "right"}
      open={showPlayerView}
      onClose={handleClose}
      variant={isSmallerScreen ? "temporary" : "persistent"}
      className="relative z-10"
    >
      <Box
        role="presentation"
        className="w-full sm:w-[350px] p-16 bg-main-bg text-main-text-color border-none"
      >
        <Stack direction={"row"} justifyContent={"flex-end"}>
          <IconButton onClick={handleClose}>
            <IoClose className="text-main-text-color" />
          </IconButton>
        </Stack>
        <Stack gap={2} className="mt-16">
          <img
            src={currentTrack?.attributes?.feature_image_url}
            className="w-full h-auto aspect-h-video rounded-lg"
          />
          <Typography className="sm:text-xl text-2xl inline-block animate-marquee overflow-x-hidden whitespace-nowrap  font-bold">
            <marquee>{currentTrack?.attributes?.title}</marquee>
          </Typography>
        </Stack>

        <Tracks />
      </Box>
    </Drawer>
  );
}
