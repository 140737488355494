import { IconButton } from "@mui/material";
import { ArrowLeftIcon } from "lucide-react";
import { useNavigate } from "react-router-dom";
export function BackButton({ iconClass = "text-white" }) {
  const navigate = useNavigate();
  return (
    <div className="absolute top-16 left-16 z-50 hidden lg:block">
      <IconButton color="inherit" onClick={() => navigate(-1)}>
        <ArrowLeftIcon className={iconClass} />
      </IconButton>
    </div>
  );
}
