import React, { useState, useEffect } from "react";
import { Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import axios from "axios";
import PaginationComponent from "src/app/shared-components/pagination/PaginationComponent";
import qs from "qs";
import LoadingSpinner from "app/shared-components/loading-spinner/loading-spinner";

export function ChunksTable({
  documentId,
  documentName,
  onAddClick,
  onDeleteAllClick,
  paginationComponent,
}) {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(-1);
  const [chunks, setChunks] = useState([]);
  const itemsPerPage = 5;

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    getChunks(1);
  }, [documentId]);

  const getChunks = async (page) => {
    try {
      setLoading(true);
      const query = {
        populate: {
          chunks: {
            sort: "createdAt:asc",
            limit: itemsPerPage,
            start: (page - 1) * itemsPerPage,
          },
        },
      };
      const queryToString = qs.stringify(query, { encode: false });
      const documents = await axios.get(
        `${process.env.REACT_APP_AUTH_BASE_URL}/api/documents/${documentId}?${queryToString}`
      );

      setChunks((prev) => [
        ...prev,
        ...documents.data.data.attributes.chunks.data,
      ]);
      setLastPage(
        documents.data.data.attributes.chunks.data.length < itemsPerPage
          ? page
          : -1
      );
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  };
  const totalPages =
    lastPage != -1
      ? lastPage
      : chunks
      ? Math.ceil(chunks.length / itemsPerPage) + 1
      : 1;

  const onPageChange = (page) => {
    setCurrentPage(page);
    if (lastPage != page && chunks.length < page * itemsPerPage) {
      getChunks(page);
    }
  };

  return (
    <div className="rounded-lg  w-full overflow-auto">
      <div
        className="bg-white text-black flex flex-col cursor-pointer"
        onClick={handleToggle}
      >
        <div className="flex flex-col justify-between items-start">
          <div className="flex mt-8 ml-24">
            <img
              src="assets/images/delete-icon.png "
              className="mx-auto p-4 w-24 h-24 bg-grey-200 rounded-full hover:bg-red-200"
            ></img>
            <Typography className="ml-16 text-lg font-bold">
              {documentName}
            </Typography>
          </div>

          <div className="mr-16 mb-8 mt-8 ml-64 text-sm text-grey-800">
            This is the extracted data from your document. All the text in the
            document is divided into chunks.
          </div>
        </div>
      </div>
      <div
        className={`transition-max-height duration-500 ease-in-out h-full overflow-auto mb-48`}
      >
        <>
          {loading ? (
            <div className="flex my-64 items-center align-center place-content-center">
              <LoadingSpinner className={"w-60 h-60 text-[#6200ea]"} />
            </div>
          ) : !loading && chunks && chunks.length > 0 ? (
            <>
              <div className="flex bg-[#F9FBFC] p-8 border-grey-300 border-t-1 border-b-1">
                <div className="w-64 font-semibold p-2 pl-16">SL No</div>
                <div className="w-[80%] text-center font-semibold p-2">
                  Content
                </div>
                <div className="flex-1 font-semibold p-2 text-center">
                  Actions
                </div>
              </div>
              {chunks
                .slice(
                  (currentPage - 1) * itemsPerPage,
                  currentPage * itemsPerPage
                )
                .map((_, index) => (
                  <div
                    key={index}
                    className="flex p-4 text-md border-b bg-[#f8f6f9] border-gray-200 last:border-none hover:bg-white transition-colors"
                  >
                    <div className="w-64 p-16 px-32 text-grey-900">
                      {(currentPage - 1) * itemsPerPage + index + 1}
                    </div>
                    <div className="w-[80%] p-16 px-32 text-grey-900 text-md">
                      {
                        chunks[(currentPage - 1) * itemsPerPage + index]
                          .attributes.content
                      }
                    </div>
                    <div className="w-[15%] mx-8 py-16 text-grey-800 items-center align-center justify-center">
                      <img
                        src="assets/images/delete-icon.png "
                        className="mx-auto p-4 w-24 h-24 bg-grey-200 rounded-full hover:bg-red-200"
                      ></img>
                    </div>
                  </div>
                ))}
            </>
          ) : (
            !loading &&
            !chunks.length && (
              <div className="flex-1 flex-col items-center justify-center p-10">
                <img
                  src="https://via.placeholder.com/150"
                  alt="No data"
                  className="mb-4"
                />
                <p className="text-gray-500">No data</p>
              </div>
            )
          )}

        </>
      </div>
      <div className="absolute bottom-0 left-0 w-full bg-white shadow-md border-t-1">
        <PaginationComponent
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={onPageChange}
        />
      </div>
    </div>
  );
}

export default ChunksTable;
