import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const getPlaylists = createAsyncThunk(
  "playlists/getPlaylists",
  async () => {
    const response = await axios
      .get(`${process.env.REACT_APP_AUTH_BASE_URL}/api/playlists`)
      .catch((error) => {
        console.log(error);
      });
    return response.data;
  }
);
export const getPlaylistById = createAsyncThunk(
  "playlists/getPlaylistById",
  async (id) => {
    const response = await axios
      .get(
        `${process.env.REACT_APP_AUTH_BASE_URL}/api/playlists?filters[id][$eq]=${id}`
      )
      .catch((error) => {
        console.log(error);
      });
    return response.data;
  }
);

export const getTracks = createAsyncThunk("playlists/getTracks", async (id) => {
  const response = await axios
    .get(
      `${process.env.REACT_APP_AUTH_BASE_URL}/api/file-results-models?filters[playlist][id][$eq]=${id}`
    )
    .catch((error) => {
      console.log(error);
    });
  return response.data;
});
const initialState = {
  playlists: { data: [], meta: {} },
  playlistDetails: {},
  tracks: [],
};
const playlistsSlice = createSlice({
  name: "playlists",
  initialState,
  reducers: {},
  extraReducers: {
    [getPlaylists.fulfilled]: (state, action) => {
      state.playlists = action.payload;
    },
    [getPlaylistById.fulfilled]: (state, action) => {
      state.playlistDetails = action.payload;
    },
    [getTracks.fulfilled]: (state, action) => {
      state.tracks = action.payload;
    },
  },
});

export const selectPlaylist = ({ playlistsSlice }) => playlistsSlice.playlists;
export const selectPlaylistDetails = ({ playlistsSlice }) =>
  playlistsSlice.playlistDetails;
export const selectTracks = ({ playlistsSlice }) => playlistsSlice.tracks;

export default playlistsSlice.reducer;
