import React, { useState } from 'react';

const LongTextField = ({topLabelText , onTextChange}) => {
  const [text, setText] = useState('');

  const handleChange = (e) => {
    setText(e.target.value);
    onTextChange(e.target.value);
  };

  return (
    <div className="flex items-center justify-center p-8">
      <div className="w-full max-w-md p-4">
        <label htmlFor="text-input" className="block text-lg font-medium text-black mb-2">
          {topLabelText}
        </label>
        <label htmlFor="text-input" className="block text-md font-regular text-black my-4">
           Provide a clear objective for the agent to research and create your report
        </label>
        <textarea
          id="text-input"
          value={text}
          onChange={handleChange}
          className="w-full min-h-[100px] px-8 py-8 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-base-purple focus:border-base-purple resize-none"
          placeholder="Type something..."
          rows="1"
        />
      </div>
    </div>
  );
};

export default LongTextField;
