import * as React from "react";
import PropTypes from "prop-types";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

export default function CircularProgressWithLabel(props) {
  return (
    <CircularProgressbar
      value={props.value}
      text={`${props.value}%`}
      styles={buildStyles({
        // Rotation of path and Trial, in number of turns (0-1)

        // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
        strokeLinecap: "round",

        // Text si2
        textSize: "24px",
        

        // How long animation takes to go from one percentage to another, in seconds
        pathTransitionDuration: 0.5,

        // Can specify path transition in more detail, or remove it entirely
        // pathTransition: 'none',

        // Colors
        pathColor: "#8576b5",
        textColor: "#060708",
        trailColor: "#efeaef",
        backgroundColor: "#3e98c7",
      })}
    />
  );
}

CircularProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate variant.
   * Value between 0 and 100.
   * @default 0
   */
  value: PropTypes.string.isRequired,
};
