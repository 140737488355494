import Typography from '@mui/material/Typography';
import { motion } from 'framer-motion';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'app/store';
import { getBoards, getLoading, resetBoards, selectBoards } from '../store/boardsSlice';
import BoardItem from './BoardItem';
import NewBoardItem from './NewBoardItem';
import CircularProgressWithLabel from 'app/shared-components/indicators/circularProgressLabel';
import LoadingSpinner from 'app/shared-components/loading-spinner/loading-spinner';

/**
 * The scrumboard boards component.
 */
export const  Boards =() =>{
	const dispatch = useAppDispatch();

	const boards = useAppSelector(selectBoards);
	const isLoading = useAppSelector(getLoading);

	useEffect(() => {
		dispatch(getBoards());
		return () => {
			dispatch(resetBoards());
		};
	},[dispatch]);

	const container = {
		show: {
			transition: {
				staggerChildren: 0.1
			}
		}
	};

	const item = {
		hidden: { opacity: 0, y: 20 },
		show: { opacity: 1, y: 0 }
	};
	if(isLoading){
		return <div className="flex flex-col w-full px-28 sm:px-16 items-center my-48">
				<div className="w-full items-center">
					<LoadingSpinner className="w-56 m-auto" />
				</div>
			</div>
		
	}

	if (boards.ids.length==0) {
		// handle the "loading" state or return null
		return (<div className="flex items-center justify-center h-screen">
		<div>No Boards available</div>
	  </div>);
	}

	return (
		<div className="flex grow shrink-0 flex-col items-center container p-24 sm:p-40">
			<motion.div
				initial={{ opacity: 0 }}
				animate={{ opacity: 1, transition: { delay: 0.1 } }}
			>
				<Typography className="mt-16 md:mt-96 text-3xl md:text-6xl font-extrabold tracking-tight leading-7 sm:leading-10 text-center">
					Scrumboards
				</Typography>
			</motion.div>

			<motion.div
				variants={container}
				initial="hidden"
				animate="show"
				className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-16 mt-32 md:mt-64"
			>
				{boards.ids?.map((board) => (
					<motion.div
						variants={item}
						className="min-w-full sm:min-w-224 min-h-360"
						key={board}
					>
						<BoardItem
							board={boards.entities[board]}
							key={board}
						/>
					</motion.div>
				))}

				<motion.div
					variants={item}
					className="min-w-full sm:min-w-224 min-h-360"
				>
					{/* NOTE: removed add board */}
					{/* <NewBoardItem /> */}
				</motion.div>
			</motion.div>
		</div>
	);
}

