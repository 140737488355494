import React, { useState } from "react";
import history from '@history';
import { Box, IconButton, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CodeSyntaxHighlighter from 'app/shared-components/code-syntax-highlighter/CodeSyntaxHighlighter'
import {aesEncrypt} from "src/app/utils/encryptions";
import { useSelector } from "react-redux";
import { selectUser } from "app/store/userSlice";

export default function ChatBotIntegration() {
	const user = useSelector(selectUser);
	let kbID = new URLSearchParams(location.search).get('kb');
	kbID= aesEncrypt(kbID, process.env.REACT_APP_ENCRYPTION_KEY)
	let orgID = aesEncrypt(user.data.organization.org_id, process.env.REACT_APP_ENCRYPTION_KEY);

    return(
		<div className="w-full px-24 md:px-32 pb-24 mt-20">
			 <div className="mt-32 ml-32" sx={{ display: 'flex', alignItems: 'start', mb: 2 }}>
				<div className="flex h-24 align-center" onClick={(e)=>{history.back()}}>
					<ArrowBackIcon className="mt-6" />
					<Typography className="flex text-3xl font-bold tracking-tight" sx={{ ml: 1 }}>
						AI Agents
					</Typography>
				</div>
			</div>
			<div className="w-full px-24 md:px-32 pb-24 mt-32">
			<Typography className="text-xl font-semibold tracking-tight leading-8" >
				Agent UI plugin for websites
			</Typography>
			<br/>
			<Typography className="text-xl font-semibold tracking-tight leading-8" >
				Presetup instuctions
			</Typography>
			<li>
				Create an oranization in Podyc
			</li>
			<li>
				Create a knowledge-base under oranization
			</li>
			<li>
				Train your website to the knowledge-base
			</li>
			<br/><br/>
			<Typography className="text-xl font-semibold tracking-tight leading-8" >
				Getting started with script injection
			</Typography>
			<Typography className="text-lg font-regular tracking-tight leading-8" >
				Type Script
			</Typography>
			<div>Paste bellow code under index.tsx</div>
			<CodeSyntaxHighlighter answer={`
~~~jsx
interface BotScriptProps extends React.DetailedHTMLProps<React.ScriptHTMLAttributes<HTMLScriptElement>, HTMLScriptElement> {
	orgid: string;
	chatbotid: string;
	usethemeargs: string;
	position: string;
	showclosebutton: string;
	showtooltip: string;
	isinitiallyopen: string;
  }

const BotScript = (props: BotScriptProps) => (
	<script src="https://bot.talkbase.ai/chatbot.js" {...props} />
  );
`}/>


			<div>Then add below code to index.tsx</div>
			<CodeSyntaxHighlighter answer={`
~~~jsx
return (
//paste bellow code here
<link rel="stylesheet" href="https://bot.talkbase.ai/chatbot.css" />
<BotScript
	orgid="${orgID}"
	chatbotid="${kbID}"
	usethemeargs="false"
	position="right"
	showclosebutton="true"
	showtooltip="true"
	isinitiallyopen="false"
 />
 ....Your code
 );
`}
			/>
			<Typography className="text-lg font-regular tracking-tight leading-8" >
				TypeScript/Next (SSR)
			</Typography>
			<div>Paste bellow code under index.tsx</div>
			<CodeSyntaxHighlighter answer={`
~~~jsx
import Script from 'next/script'
interface BotScriptProps extends React.DetailedHTMLProps<React.ScriptHTMLAttributes<HTMLScriptElement>, HTMLScriptElement> {
	orgid: string;
	chatbotid: string;
	usethemeargs: string;
	position: string;
	showclosebutton: string;
	showtooltip: string;
	isinitiallyopen: string;
  }

const BotScript = (props: BotScriptProps) => (
	<Script src="https://bot.talkbase.ai/chatbot.js" {...props} />
  );
`}/>


			<div>Then add bellow code to index.tsx</div>
			<CodeSyntaxHighlighter answer={`
~~~jsx
return (
//paste bellow code here
<link rel="stylesheet" href="https://bot.talkbase.ai/chatbot.css" />
<BotScript
	orgid="${orgID}"
	chatbotid="${kbID}"
	usethemeargs="false"
	position="right"
	showclosebutton="true"
	showtooltip="true"
	isinitiallyopen="false"
 />
 ....Your code
 );
`}
			/>
			<Typography className="text-2xl font-semibold tracking-tight leading-8" >
				Non TypeScript
			</Typography>
			<div>copy and paste bellow code snippet to you main tag </div>
			<CodeSyntaxHighlighter answer={`
~~~js
<link rel="stylesheet" href="https://bot.talkbase.ai/chatbot.css" />
<script src="https://bot.talkbase.ai/chatbot.js"
	orgid="${orgID}"
	chatbotid="${kbID}"
	usethemeargs="false"
	position="right"
	showclosebutton="true"
	showtooltip="true"
	isinitiallyopen="false"
	defer
/>`}/>
			<Typography className="text-2xl font-semibold tracking-tight leading-8" >
				Wordpress
			</Typography>
			<li>Add new plugin call 'WPCode Lite' formally known as 'WP Header and Footer'</li>
			<li>Now you can see new menu option Code Snippets</li>
			<li>{`Got Code Snippets -> Header and Footer`}</li>
			<li>Add bellow code to header section</li>
			<CodeSyntaxHighlighter answer={`
~~~js
<link rel="stylesheet" href="https://bot.talkbase.ai/chatbot.css"/>
<script src="https://bot.talkbase.ai/chatbot.js"
	orgid="${orgID}"
	chatbotid="${kbID}"
	usethemeargs="false"
	position="right"
	showclosebutton="true"
	showtooltip="true"
	isinitiallyopen="false"
	defer
/>`}/>
		</div>
		<div className="ml-20">
			<div className="text-xl">Here's what each attribute does:</div>
			{/* <ul> */}
				<li><strong>src:</strong> Specifies the source URL of the chatbot script.</li>
				<li><strong>orgid:</strong> Your organization's unique identifier (Required).</li>
				<li><strong>chatbotid:</strong> The unique identifier for your chatbot (Required).</li>
				<li><strong>usethemeargs:</strong> Choose between default and custom theme for the chatbot's color scheme.</li>
				<li><strong>position:</strong> Choose the chatbot's position on the screen, either "right" (default) "left" or "center".</li>
				<li><strong>showclosebutton:</strong> Set to "true" (default) or "false" to show or hide the chatbot's close button.</li>
				<li><strong>showtooltip:</strong> Set to "true" (default) or "false" to show or hide a tooltip explaining how to open the chatbot.</li>
				<li><strong>isinitiallyopen:</strong> Set to "true" to have the chatbot open automatically when the page loads or "false" (default) to keep it closed initially.</li>
			{/* </ul> */}
		</div>
		</div>
    );
}
