export const getImageForAgentType = (type) => {
    switch (type) {
      case "QnA Agent":
        return "assets/images/qna_agent.svg";
      case "Sales Agent":
        return "assets/images/gd_sales_agent.svg";
      case "Support Agent":
        return "assets/images/gd_support_agent.svg";
      default:
        return "assets/images/qa-icon.png";
    }
  };

  export const getImageForAgentTaskId = (type) => {
    switch (type) {
        case 14:
            return "assets/images/qna_agent.svg";
        case 16:
            return "assets/images/gd_sales_agent.svg";
        case 15:
            return "assets/images/gd_support_agent.svg";
        case 19:
            return "assets/images/qa-icon.svg";

    }
}