import React from "react";
import { Dialog, DialogTitle, DialogContent, Divider } from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import "./base-dialog.css";
import { ArrowBackOutlined } from "@mui/icons-material";
import PropTypes from "prop-types";

export default function BaseDialog(props) {
  return (
    <Dialog
      className="modal-dialog"
      open={props.open}
      onClose={props.handleClose}
      fullWidth
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "1000px",
            borderRadius : "12px",
          },
        },
      }}
    >
      <div className="flex items-center justify-between ml-14">
        <div className="flex items-center">
          {props.showBackButton ? (
            <ArrowBackOutlined
              className="text-black bg-[#E4E7EC] rounded-full text-3xl p-3"
              onClick={() => {
                props.handleBack();
              }}
            />
          ) : (
            <></>
          )}
          <DialogTitle className="flex-grow-1 px-10">{props.title}</DialogTitle>
        </div>
        <CloseIcon className="mr-20 text-black" onClick={props.handleClose} />
      </div>
      <Divider className="" />
      <DialogContent
        className="mx-0"
        style={{
          padding: "0px",
          margin: "0px",
        }}
      >
        {props.children}
      </DialogContent>
    </Dialog>
  );
}
BaseDialog.propTypes = {
  title: PropTypes.string,
  open: PropTypes.bool,
  showBackButton: PropTypes.bool,
  children: PropTypes.node,
  handleClose: PropTypes.func,
  handleBack: PropTypes.func,
};

BaseDialog.defaultProps = {
  showBackButton: false,
};
