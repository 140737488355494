import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useEffect, useState, MouseEvent } from 'react';
import { useAppDispatch } from 'app/store';
import * as yup from 'yup';
import _ from '@lodash';
import Box from '@mui/material/Box';
import { darken } from '@mui/material/styles';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import clsx from 'clsx';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';
import { removeList, updateList } from '../../store/listsSlice';
import { ListType } from '../../types/ListType';

const schema = yup.object().shape({
  title: yup.string().required('You must enter a title'),
});




function BoardListHeader(props) {
  const { list, cardIds, className, handleProps } = props;
  const dispatch = useAppDispatch();

  const [anchorEl, setAnchorEl] = useState(null);
  const [formOpen, setFormOpen] = useState(false);

  const { control, formState, handleSubmit, reset } = useForm({
    mode: 'onChange',
    defaultValues: {
      title: list.attributes.title,
    },
    resolver: yupResolver(schema),
  });

  const { isValid, dirtyFields } = formState;

  useEffect(() => {
    if (!formOpen) {
      reset({
        title: list.attributes.title,
      });
    }
  }, [formOpen, reset, list.attributes.title]);

  useEffect(() => {
    if (formOpen && anchorEl) {
      setAnchorEl(null);
    }
  }, [anchorEl, formOpen]);

  function handleMenuClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleMenuClose() {
    setAnchorEl(null);
  }

  function handleOpenForm(ev) {
    ev.stopPropagation();
    setFormOpen(true);
  }

  function handleCloseForm() {
    setFormOpen(false);
  }

  function onSubmit(newData) {
    dispatch(updateList({ id: list.id, newData }));
    handleCloseForm();
  }

  return (
    <div {...handleProps}>
      <div className={clsx('flex items-center justify-between h-48 sm:h-56 px-12', className)}>
        <div className="flex items-center min-w-0">
          {formOpen ? (
            <ClickAwayListener onClickAway={handleCloseForm}>
              <form className="flex w-full" onSubmit={handleSubmit(onSubmit)}>
                <Controller
                  name="title"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      variant="outlined"
                      margin="none"
                      autoFocus
                      size="small"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton type="submit" disabled={_.isEmpty(dirtyFields) || !isValid} size="large">
                              <FuseSvgIcon>heroicons-outline:check</FuseSvgIcon>
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </form>
            </ClickAwayListener>
          ) : (
            <Typography className="text-15 font-medium cursor-pointer text-[#656c84]" 
            
             onClick={handleOpenForm}>
              {list.attributes.title}
            </Typography>
          )}
           <Box
            className="flex items-center ml-8 justify-center min-w-20 h-20 mx-4 text-xs font-semibold leading-32 rounded-full"
            sx={{
              backgroundColor: (theme) =>
               theme.palette.accent.primary,
              color: 'text.secondary',
            }}
          >
            {cardIds.length}
          </Box>
        </div>
        <div className="flex items-center">
         
          <IconButton aria-haspopup="true" onClick={handleMenuClick} size="small">
            <FuseSvgIcon size={20}>heroicons-outline:dots-horizontal</FuseSvgIcon>
          </IconButton>
          <Menu
            id="actions-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            <MenuItem
              onClick={() => {
                dispatch(removeList(list.id));
              }}
            >
              <ListItemIcon className="min-w-40">
                <FuseSvgIcon>heroicons-outline:trash</FuseSvgIcon>
              </ListItemIcon>
              <ListItemText primary="Remove List" />
            </MenuItem>
            <MenuItem onClick={handleOpenForm}>
              <ListItemIcon className="min-w-40">
                <FuseSvgIcon>heroicons-outline:pencil</FuseSvgIcon>
              </ListItemIcon>
              <ListItemText primary="Rename List" />
            </MenuItem>
          </Menu>
        </div>
      </div>
    </div>
  );
}

export default BoardListHeader;
