import _ from 'lodash';
// import { DraggableLocation, DropResult } from 'react-beautiful-dnd';
// Assuming DraggableLocation and DropResult types are available or replaced with necessary types

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export default reorder;

/**
 * Reorder Quote Map
 */
export const reorderQuoteMap = (lists, source, destination) => {
  const current = _.find(lists, { id: parseInt(source.droppableId) });
  const next = _.find(lists, { id: parseInt(destination?.droppableId) });
  const target = current.attributes.cards[source.index];

  // moving to same list
  if (source.droppableId === destination?.droppableId) {
    const reordered = reorder(current.attributes.cards, source.index, destination.index);
    return lists.map((list) => {
      if (list.id === source.droppableId) {
        list.attributes.cards = reordered;
      }
      return list;
    });
  }

  // moving to different list

  // remove from original
  current.attributes.cards.splice(source.index, 1);

  // insert into next
  next.attributes.cards.splice(destination?.index, 0, target);

  return lists.map((list) => {
    if (list.id === source.droppableId) {
      return current;
    }
    if (list.id === destination?.droppableId) {
      return next;
    }
    return list;
  });
};
