import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import Typography from '@mui/material/Typography';
import FaqList from './FaqList';

function HelpCenterFaqs() {
  const groupedFaqs = [
    {
        "id": "28924eab-97cc-465a-ba21-f232bb95843f",
        "slug": "most-asked",
        "title": "This Pilot Agreement (“Agreement”) is made between Imversion, the provider of the Podyc SaaS solution, and the user of the Podyc SaaS solution (“User”). The pilot program ends on the 30th of April 2023.",
        "faqs": [
            {
                "id": "f65d517a-6f69-4c88-81f5-416f47405ce1",
                "categoryId": "28924eab-97cc-465a-ba21-f232bb95843f",
                "question": "Pilot Program",
                "answer": "User acknowledges that the Podyc SaaS solution is currently in the pilot phase, and as such, minor issues and bugs may occur during the usage of the solution."
            },
            {
                "id": "0fcece82-1691-4b98-a9b9-b63218f9deef",
                "categoryId": "28924eab-97cc-465a-ba21-f232bb95843f",
                "question": "License and Ownership",
                "answer": "Imversion grants User a non-exclusive, non-transferable license to use the Podyc SaaS solution for the duration of the pilot program. Imversion shall own all intellectual property rights to the Podyc SaaS solution, including any modifications or enhancements made during the pilot program."
            },
            {
                "id": "2e6971cd-49d5-49f1-8cbd-fba5c71e6062",
                "categoryId": "28924eab-97cc-465a-ba21-f232bb95843f",
                "question": "Data Maintenance",
                "answer": "User acknowledges that Imversion may need to clean the data uploaded by User from time to time for maintenance purposes. Imversion will notify User in advance of any scheduled data maintenance activities."
            },
            {
                "id": "974f93b8-336f-4eec-b011-9ddb412ee828",
                "categoryId": "28924eab-97cc-465a-ba21-f232bb95843f",
                "question": "Data Usage",
                "answer": "User acknowledges that they are responsible for obtaining the necessary permissions and rights to use any data uploaded to the Podyc SaaS solution. Imversion will not be responsible for any misuse of data or any use of data without the right permissions by User, including the use of publicly available URLs or documents of other companies, competitors, or other third-party entities to create a knowledge base or train the AI."
            },
            {
                "id": "5d877fc7-b881-4527-a6aa-d39d642feb23",
                "categoryId": "28924eab-97cc-465a-ba21-f232bb95843f",
                "question": "Protection of IP and Confidentiality",
                "answer": "User acknowledges that Imversion owns all intellectual property rights to the Podyc SaaS solution, and User shall not reverse engineer, decompile, or disassemble the Podyc SaaS solution or attempt to create any derivative works from the Podyc SaaS solution. User agrees to keep the Podyc SaaS solution confidential and not disclose any confidential information to any third parties without the prior written consent of Imversion."
            },
            {
              "id": "5d877fc7-b881-4527-a6aa-d39d642feb24",
              "categoryId": "28924eab-97cc-465a-ba21-f232bb95843f",
              "question": "Responsibility",
              "answer": "User acknowledges that the responsibility for uploading and using data in the Podyc SaaS solution rests solely with User and not with Imversion as the SaaS solution provider."
            },
            {
              "id": "5d877fc7-b881-4527-a6aa-d39d642feb25",
              "categoryId": "28924eab-97cc-465a-ba21-f232bb95843f",
              "question": "Agreement Embedding",
              "answer": "This Agreement may be embedded in the Podyc SaaS solution website, and User may be required to agree to this Agreement by checking an “I Agree” checkbox or a similar mechanism. Alternatively, this Agreement may be signed offline by both parties."
            }
        ]
    }
    ]

  return (
    <div className="flex flex-col items-center p-24 sm:p-40">
      <div className="flex flex-col w-full max-w-4xl">
        <div className="sm:mt-32">
          <Button
            component={Link}
            to={-1}
            color="secondary"
            startIcon={<FuseSvgIcon>heroicons-outline:arrow-narrow-left</FuseSvgIcon>}
          >
            Back to Sign In
          </Button>
        </div>
        <div className="mt-8 text-4xl sm:text-7xl font-extrabold tracking-tight leading-tight">
          Frequently Asked Questions
        </div>

        {groupedFaqs.map((category) => (
          <div key={category.id}>
            <Typography className="mt-48 sm:mt-64 text-xl font-bold leading-tight tracking-tight">
              {category.title}
            </Typography>
            <FaqList className="w-full mt-32" list={category.faqs} />
            <Typography className="mt-48 sm:mt-64 text-xl font-bold leading-tight tracking-tight">
              By checking the “I Agree” checkbox or signing the offline Agreement, User agrees to the terms and conditions of this Agreement.
              This Agreement represents the entire understanding between the parties and supersedes all prior negotiations, representations, or agreements, either written or oral.
              This Agreement shall be governed and interpreted by the laws of the jurisdiction where Imversion is registered.
            </Typography>
          </div>
        ))}
      </div>
    </div>
  );
}

export default HelpCenterFaqs;
