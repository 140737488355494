// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.icon {
	height: 40px;
	width: 40px;
	position: absolute;
	display: flex;
	align-items: center;
	place-content: center;
	top: 50%;
	left: -20px;
	transform: translateY(-50%);
}
`, "",{"version":3,"sources":["webpack://./src/app/main/dashboards/knowledge_base/source-card/source-card.css"],"names":[],"mappings":"AAAA;CACC,YAAY;CACZ,WAAW;CACX,kBAAkB;CAClB,aAAa;CACb,mBAAmB;CACnB,qBAAqB;CACrB,QAAQ;CACR,WAAW;CACX,2BAA2B;AAC5B","sourcesContent":[".icon {\n\theight: 40px;\n\twidth: 40px;\n\tposition: absolute;\n\tdisplay: flex;\n\talign-items: center;\n\tplace-content: center;\n\ttop: 50%;\n\tleft: -20px;\n\ttransform: translateY(-50%);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
