import { Button, CircularProgress } from '@mui/material';
import SettingsTextField from 'app/shared-components/forms/settingsTextField';
import { useState } from 'react';

const PrefixPrompt = ({ onSubmit, isLoading = false, disabled = false, value }) => {
	const [prompt, setPrompt] = useState();

	return (
		<div className="mt-24 settigs-grid-container bg-white">
			<SettingsTextField
				id="prompt-prefix"
				isMultilineMode={true}
				finalValue={value}
				validate={(value) => {
					return value != null;
				}}
				title="Customize Agent Prompt"
				icon="assets/images/base_url_icon.png"
				description="Add custom instructions to your agents (optional):"
				placeholder='Examples : "Always give your answer in a bullet point format" ,  "Please provide your answer in a paragraph format " , "Always give your answer in a friendly tone"'
				onChange={(id, value, isValid) => {
					setPrompt(value);
				}}
				className="w-4/5"
			></SettingsTextField>
			<Button
				disabled={disabled}
				className="purple-styled-button mr-10 w-fit"
				variant="outlined"
				onClick={(e) => onSubmit(prompt)}
			>
				{isLoading ? (
					<CircularProgress
						thickness={5}
						size={18}
						className="mr-8 text-white"
					/>
				) : (
					<div></div>
				)}
				Update
			</Button>
		</div>
	);
};

export default PrefixPrompt;
