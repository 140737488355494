import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  showPlayerView: false,
  currentTrack: null,
  isPlaying: false,
};
const playerSlice = createSlice({
  name: "player",
  initialState,
  reducers: {
    setPlayerView: (state, action) => {
      state.playerView = action.payload;
    },
    setCurrentTrack: (state, action) => {
      state.currentTrack = action.payload;
    },
    setIsPlaying: (state, action) => {
      state.isPlaying = action.payload;
    },
    setShowPlayerView: (state, action) => {
      state.showPlayerView = action.payload;
    },
  },
});

export const {
  setPlayerView,
  setCurrentTrack,
  setIsPlaying,
  setShowPlayerView,
} = playerSlice.actions;
export const selectShowPlayerView = ({ playerSlice }) =>
  playerSlice.showPlayerView;
export const selectCurrentTrack = ({ playerSlice }) => playerSlice.currentTrack;
export const selectIsPlaying = ({ playerSlice }) => playerSlice.isPlaying;

export default playerSlice.reducer;
