import FuseUtils from '@fuse/utils/FuseUtils';
import _ from 'lodash';

/**
 * The checklist item model.
 */
export const  ChecklistItemModel=(data) =>{
  data = data || {};

  return _.defaults(data, {
    id: FuseUtils.generateGUID(),
    name: '',
    checked: false,
  });
}

