import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

export const getKb = createAsyncThunk('kb/getKb', async () => {
  const response = await axios.get(`${process.env.REACT_APP_AUTH_BASE_URL}/api/knowledgebases`).catch((error) => { console.log(error);});
  return response.data;
});
const initialState = [];
const kbSlice = createSlice({
  name: 'kb',
  initialState,
  reducers: {},
  extraReducers: {
    [getKb.fulfilled]: (state, action) => action.payload,
  },
});

export const selectKb = ({ kb }) => kb;

export default kbSlice.reducer;
