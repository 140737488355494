import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import createAppAsyncThunk from 'app/store/createAppAsyncThunk';
import axios from 'axios';
import { RootStateType } from 'app/store/types';
import history from '@history';
import BoardModel from '../models/BoardModel';

const boardsAdapter = createEntityAdapter({});
// const initialState = boardsAdapter.getInitialState({});
const initialState ={
  boards: boardsAdapter.getInitialState({}),
  loading: false, // Add loading indicator
};

export const getBoards = createAppAsyncThunk('scrumboardApp/boards/getBoards', async (arg,{ getState }) => {
  try{
  const AppState = getState();
  const user = AppState.user;
  const response = await axios.get(`${process.env.REACT_APP_AUTH_BASE_URL}/api/scrumboards?filters[knowledgebases][organization][id][$eq]=${user.data.organization.id}&populate[0]=members&populate[1]=knowledgebases.organization`);
  const data = response.data.data;
  return data;
  }catch (e){
    throw e;
  }
});

export const newBoard = createAppAsyncThunk('scrumboardApp/boards/newBoard', async (board) => {
 

  const response = await axios.post(`${process.env.REACT_APP_AUTH_BASE_URL}/api/scrumboards`,{data:BoardModel(board)});
  const data = response.data;

  history.push({
    pathname: `/apps/scrumboard/boards/${data.id}`
  });

  return data;
});
export const getLoading = (state) =>{ 
  return state.scrumboardApp.boards.loading;
};
export const selectBoards = (state) =>{ 
  return state.scrumboardApp.boards.boards;
  // return boardsAdapter.selectAll(state.scrumboardApp.boards)
};
// export const selectBoards = (state) => boardsAdapter.selectAll(state.scrumboardApp.boards);
export const selectBoardEntities = (state) => {
  return state.scrumboardApp.boards.boards;
  // boardsAdapter.selectEntities(state.scrumboardApp.boards);
}
export const selectBoardById = (state, id) => {
  return state.scrumboardApp.boards.boards.map(e=>e.id==id);
  // boardsAdapter.selectById(state.scrumboardApp.boards, id);
}

export const boardsSlice = createSlice({
  name: 'scrumboardApp/boards',
  initialState,
  reducers: {
    resetBoards: () => {}
  },
  extraReducers: (builder) => {
    builder.addCase(getBoards.pending, (state) => {
      state.loading = true; // Set loading to true when the getBoards action starts
    });
    builder.addCase(getBoards.fulfilled, (state, action) => {
      state.loading = false; // Set loading to false when the getBoards action is fulfilled
      boardsAdapter.setAll(state.boards, action.payload);
    });
    builder.addCase(getBoards.rejected, (state) => {
      state.loading = false; // Set loading to false if the getBoards action encounters an error
    });
  }
});

export const { resetBoards } = boardsSlice.actions;

export default boardsSlice.reducer;
