import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import TextField from "@mui/material/TextField";
import { Link } from "react-router-dom";
import * as yup from "yup";
import _ from "@lodash";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import jwtService from "../../auth/services/jwtService";
import "./sign-in.css";
import Typography from "@mui/material/Typography";
import FormHelperText from "@mui/material/FormHelperText";
import LoadingSpinner from "app/shared-components/loading-spinner/loading-spinner";
import { useState } from "react";
import DividerWithText from "app/shared-components/dividers/DividerWithText";
/**
 * Form Validation Schema
 */
const schema = yup.object().shape({
  email: yup
    .string()
    .email("You must enter a valid email")
    .required("You must enter a email"),
  password: yup
    .string()
    .required("Please enter your password.")
    .min(4, "Password is too short - must be at least 4 chars."),
});

const defaultValues = {
  email: "",
  password: "",
  remember: true,
  terms: false,
  phonenumber: "",
};

function SignInPage() {
  const { control, formState, handleSubmit, setError, setValue } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { isValid, dirtyFields, errors, isLoading } = formState;

  const [loading, setLoading] = useState(false);

  function onSubmit({ email, password }) {
    setLoading(true);
    jwtService
      .signInWithEmailAndPassword(email, password)
      .then((user) => {
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        // setError(error);
      });
  }

  return (
    <div className="flex flex-col xl:flex-row  2xl:flex-row  lg:flex-row md:flex-row w-full h-full align-center items-center justify-end bg-[#FeFeFF]">
      <div className="flex bg-[#fafaf6] flex-col w-full  2xl:w-[40%] xl:w-[40%] md:w-[40%] lg:w-[40%] h-full align-center items-center justify-center sm:mb-16 xs:mb-16">
        <div className="w-full mb-16 mt-32 xl:mt-[-64px] 2xl:mt-[-64px] 3xl:mt-[-64px] md:mt-[-64px] lg:mt-[-64px] px-32 max-w-480">
          <div className="flex w-full justify-start">
            <img
              className="logo-icon mt-2  w-24 h-24"
              src="assets/images/logo/logo.png"
              alt="logo"
            />
            <span className="flex ml-8 text-2xl font-bold md:block">
              Podyc
            </span>
          </div>

          <Typography className="mt-16 text-4xl font-bold tracking-tight leading-tight">
            Login to your acccount
          </Typography>
          <Typography className="mt-12 text-base font-regular tracking-tight text-start text-black">
            Welcome back! Your Audio life coach awaits.
          </Typography>

          <div className="my-32">
            <div
              class="flex p-12 border rounded place-content-center cursor-pointer  hover:bg-gray-800 hover:text-white"
              onClick={() =>
                (window.location = `${process.env.REACT_APP_AUTH_BASE_URL}/api/connect/google`)
              }
            >
              <div class="google-image">
                <svg
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 48 48"
                  class="LgbsSe-Bz112c"
                >
                  <g>
                    <path
                      fill="#EA4335"
                      d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"
                    ></path>
                    <path
                      fill="#4285F4"
                      d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"
                    ></path>
                    <path
                      fill="#FBBC05"
                      d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"
                    ></path>
                    <path
                      fill="#34A853"
                      d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"
                    ></path>
                    <path fill="none" d="M0 0h48v48H0z"></path>
                  </g>
                </svg>
              </div>
              <span class="nsm7Bb-HzV7m-LgbsSe-BPrWId font-medium">
                Sign in with Google
              </span>
            </div>
          </div>

          <DividerWithText> or </DividerWithText>

          <form
            name="loginForm"
            noValidate
            className="flex flex-col justify-center w-full mt-32"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  className="mb-24"
                  size="medium"
                  style={{
                    backgroundColor: "transparent",
                  }}
                  placeholder="Enter your email address"
                  inputProps={{
                    disableUnderline: true, // Disables the default underline to use modern border
                    style: {
                      borderRadius: "6px",
                      backgroundColor: "#FFFBFF",
                      paddingRight: "4px",
                      paddingBottom: "4px",
                      paddingTop: "2px", // Updated padding for a better look and feel
                      fontWeight: 400,
                      height: "5rem", // Fix height of text field
                      fontSize: "1.5rem", // Base font size
                    },
                  }}
                  autoFocus
                  type="email"
                  error={!!errors.email}
                  helperText={errors?.email?.message}
                  variant="outlined"
                  fullWidth
                />
              )}
            />
            <Controller
              name="password"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  className="mb-24"
                  style={{
                    backgroundColor: "transparent",
                  }}
                  placeholder="Enter your password"
                  inputProps={{
                    disableUnderline: true, // Disables the default underline to use modern border
                    style: {
                      borderRadius: "6px",
                      backgroundColor: "#FFFBFF",
                      paddingRight: "4px",
                      paddingBottom: "4px",
                      paddingTop: "2px", // Updated padding for a better look and feel
                      fontWeight: 400,
                      height: "5rem", // Fix height of text field
                      fontSize: "1.5rem", // Base font size
                    },
                  }}
                  type="password"
                  error={!!errors.password}
                  helperText={errors?.password?.message}
                  variant="outlined"
                  fullWidth
                />
              )}
            />
            <div className="flex w-full">
              <div className="flex flex-row w-full items-center justify-start space-x-0 ">
                {" "}
                {/* Adjusted this div */}
                <Controller
                  name="remember"
                  control={control}
                  render={({ field }) => (
                    <FormControl>
                      <FormControlLabel
                        label={
                          <div className="!flex !w-full !place-content-between text-base font-regular tracking-tight text-black">
                            Remember me
                            <span className=""></span>
                            <Link
                              className="text-base text-base-purple font-regular"
                              to="/forgot-password"
                            >
                              Forgot password?
                            </Link>
                          </div>
                        }
                        className="!flex !w-full !place-content-between signin-checkbox text-xs cta-label-style "
                        control={
                          <Checkbox
                            size="small"
                            className="cta-label-style"
                            {...field}
                          />
                        }
                      />
                    </FormControl>
                  )}
                />
              </div>
            </div>
            {loading && (
              <div className="mt-4 flex align-center justify-center items-center">
                <LoadingSpinner size={24} />
              </div>
            )}
            {!loading && (
              <Button
                variant="contained"
                color="secondary"
                className="press-button shine-button w-full mt-16 rounded-md bg-primary hover:bg-primary text-main-bg"
                aria-label="Login"
                // disabled={_.isEmpty(dirtyFields) || !isValid}
                type="submit"
                size="large"
              >
                Sign in
              </Button>
            )}
          </form>

          <div className="mt-24 flex justify-center items-center text-center font-regular text-base text-black">
            <p>Don't have an account?</p>
            <a
              className="text-main-bg font-bold ml-4"
              href={
                process.env.REACT_APP_DISABLE_SIGNUP === "true"
                  ? "https://cal.com/talkbase/15min"
                  : "/sign-up"
              }
            >
              Create an account{" "}
            </a>
          </div>
        </div>
      </div>
      <div className="flex w-full 2xl:w-[60%] xl:w-[60%] md:w-[60%] lg:w-[60%] h-full relative bg-[#000000]">
        <div className="absolute inset-0 flex items-center justify-center">
          <div className="relative w-[60%] h-[60%]">
            <img
              src="assets/images/login_creative.png"
              alt=""
              className="object-contain w-full h-full relative z-0"
            />
            <div className="absolute inset-0 z-10"
              style={{
                background: `
                  radial-gradient(
                    circle at center,
                    transparent 10%,
                    rgba(0,0,0,0.4) 30%,
                    rgba(0,0,0,0.9) 50%,
                    rgba(0,0,0,1) 60%
                  )
                `
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignInPage;
