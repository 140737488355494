import React from "react";

const ProgressBar = ({ progress, height = "h-4" }) => {
  return (
    <div
      className={`w-full bg-gray-300 rounded-full overflow-hidden shadow-lg ${height}`}
    >
      <div
        className="bg-gradient-to-r from-base-purple to-secondary-color h-full transition-all duration-500 ease-in-out"
        style={{ width: `${progress}%` }}
      ></div>
    </div>
  );
};

export default ProgressBar;
