import FusePageSimple from "@fuse/core/FusePageSimple";
import { Typography } from "@mui/material";
import AlbumCard from "app/shared-components/cards/albumCard";
import { PageHeader } from "app/shared-components/PageHeader";
import { getRandomPoster } from "src/app/utils/album";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import {
  selectFeaturedPodcasts,
  getFeaturedPodcasts,
  setSelectedFileResult,
  setIsPlaying,
} from "app/store/fileResultSlice";

export function FeaturedPodcastsPage() {
  const featuredPodcasts = useSelector(selectFeaturedPodcasts);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getFeaturedPodcasts(12));
  }, []);

  return (
    <FusePageSimple
      className="px-16 bg-main-bg "
      content={
        <div className="flex flex-col  w-full py-16 bg-main-bg ">
          <PageHeader title={"Featured Podcasts"} />

          <div className="grid lg:grid-cols-6 gap-0 grid-cols-1 sm:grid-cols-3 md:grid-cols-4">
            {featuredPodcasts?.featuredPodcastsData?.map((album) => (
              <AlbumCard album={album} />
            ))}
          </div>
        </div>
      }
    />
  );
}
