/**
 * Comment Type
 */
const CommentType = {
	id: '',
	type: '',
	idMember: '',
	message: '',
	time: 0,
  };
  
  /**
   * Comments Type
   */
  const CommentsType = []; // Array of CommentType objects
  