import Button from '@mui/material/Button';
import NavLinkAdapter from '@fuse/core/NavLinkAdapter';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import BoardTitle from './BoardTitle';
import { useAppSelector } from 'app/store/index';
import { selectBoard } from '../store/boardSlice';
import { useState } from 'react';



/**
 * The board header component.
 */
function BoardHeader(props) {
	const { onSetSidebarOpen } = props;
	const { data: board } = useAppSelector(selectBoard);
	// const [kb, setKb]= useState();
	// const fetchKb=async ()=>{
	// 	try {
	// 		const kbRec = await axios.get(
	// 		  `${process.env.REACT_APP_AUTH_BASE_URL}/api/knowledgebases?scrumboard{kbrID}?populate[0]=scrumboard`
	// 		);
	// 		setKb(kbRec.data.data);
	// 	  } catch (e) {
	// 		console.log(e);
	// 	  }

	// }
	// useEffect(() => {
	// 	fetchKb();
	//   }, [board]);

	return (
		<div className="p-24 sm:p-32 w-full flex flex-col sm:flex-row items-center justify-between container">
			<div className="flex items-center mb-12 sm:mb-0">
				<BoardTitle />
			</div>

			<div className="flex items-center justify-end space-x-12">
			<Button
                className="purple-styled-button mt-8"
                variant="outlined"
                component={NavLinkAdapter}
                to={`/dashboards/knowledge_base/items_detail_page?kbID=${board.attributes.knowledgebases.data[0].attributes.kb_id}&kbName=${board.attributes.knowledgebases.data[0].attributes.name}&kbrID=${board.attributes.knowledgebases.data[0].id}`}
                // startIcon={
                //   <ReactSVG
                //     color="white"
                //     src="assets/images/icons/circular-pluse-white.svg"
                //   />
                // }
              >
                Knowledgebase
              </Button>
				<Button
					className="whitespace-nowrap"
					component={NavLinkAdapter}
					to="/dashboards/kanban/boards"
					startIcon={<FuseSvgIcon size={20}>heroicons-outline:view-boards</FuseSvgIcon>}
				>
					Boards
				</Button>

				{/* <Button
					className="whitespace-nowrap"
					variant="contained"
					color="secondary"
					onClick={() => onSetSidebarOpen(true)}
					startIcon={<FuseSvgIcon size={20}>heroicons-outline:cog</FuseSvgIcon>}
				>
					Settings
				</Button> */}
			</div>
		</div>
	);
}

export default BoardHeader;
