import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { bool } from "prop-types";

export const getUsage = createAsyncThunk(
  "usage/getUsage",
  async (arg, { getState }) => {
    const response = await axios
      .get(`${process.env.REACT_APP_AUTH_BASE_URL}/api/org`)
      .catch((error) => {
        console.log(error);
      });
    var credits_used;
    var training_tokens_count;
    try {
      credits_used = parseInt(
        response.data?.current_month_usage?.credits_used ?? 0
      );
      training_tokens_count = parseInt(
        response.data?.current_month_usage?.training_tokens_count ?? 0
      );
    } catch (e) {
      training_tokens_count = 0;
    }
    const data = {
      credits_used: credits_used,
      quota : response.data?.current_month_usage?.usage_quota ?? 0,
      queries_count: response.data?.current_month_usage?.query_count ?? 0,
      allowed_credits: parseInt(response.data?.plan?.allowed_credits ?? 0),
      training_tokens_count: training_tokens_count,
      allowed_training_tokens_count: response.data?.plan?.allowed_training_tokens ?? 0,
      allowed_kbs: response.data?.plan?.allowed_kbs ?? 0,
      kbs_count: response.data?.current_month_usage?.kb_count ?? 0,
      allowed_scrape_train_count: response.data?.plan?.allowed_scrape_train_count ?? 0,
      plan_name: response.data?.plan?.name ?? "",
      subscription_type: response.data.subscription ?? ""
    };
    return data;
  }
);
const initialState = {
  credits_used: 0,
  quota: 0,
  queries_count: 0,
  allowed_credits: 0,
  training_tokens_count: 0,
  allowed_training_tokens_count: 0,
  allowed_kbs: 0,
  kbs_count: 0,
  allowed_scrape_train_count: 0,
  subscription_type: ""
};
const usageSlice = createSlice({
  name: "usage",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getUsage.fulfilled, (state, action) => {
      Object.assign(state, action.payload);
    });
  },
});

export const selectUsage = (state) => ({...state.usage});

export function hasExcededCreditLimit(plan , usage){
 return usage["queries_count"] > parseInt(plan.allowed_credits);
}

export function hasExceededTrainingTokenLimit(plan , usage) {
  return usage["training_tokens_count"] > plan.allowed_training_tokens ;
}

export function hasExceededKBLimit(plan , usage){
  return usage.kbs_count > plan.allowed_kbs
}

export function hasExceededScrapeTrainLimit(linksCount , usage){
  return linksCount >= usage["allowed_scrape_train_count"];
}

export default usageSlice.reducer;
