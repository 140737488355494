import Typography from '@mui/material/Typography';
import { Paper, Button } from '@mui/material';
import { useState } from 'react';
import jwtService from '../../auth/services/jwtService';

function SignUpEmailConfirmation() {
  const email = new URLSearchParams(location.search).get('email');
  const [showSendButton, setShowSendButton] = useState(email ? true : false);
  const handleFormSubmit = (event) => {
    jwtService
      .sendEmailConfirmation(email)
      .then((response) => {
        setShowSendButton(false);
      })
      .catch((error) => {
        console.log(error);
    });
  };

  return (
    <div className="flex flex-col flex-auto items-center sm:justify-center min-w-0 bg-main-bg">
      <Paper className="flex shadow-md shadow-primary items-center rounded-md w-full sm:w-auto min-h-full sm:min-h-auto py-32 px-16 sm:p-48 bg-main-text-color">
        <div className="w-full max-w-320 sm:w-320 mx-auto sm:mx-0">
          <div className="flex w-full items-center align-center justify-center mb-24">
            <img className="logo-icon w-96" src="assets/images/logo/logo-text.png" alt="logo" />

          </div>
          <Typography className="flex mt-32 text-xl font-bold tracking-tight leading-tight text-center">
            Please check your email to confirm your account
          </Typography>
          <Typography className="flex mt-32 text-base font-regular tracking-tight leading-tight text-center">
            An email is sent with a confirmation link. Please click on the link to activate your account.
          </Typography>
          {showSendButton && (
            <div className="flex justify-center mt-10">
              <Button className="purple-styled-button items-center sm:justify-center" variant="outlined" onClick={handleFormSubmit}>
                Send Confirmation Email
              </Button>
            </div>
          )}
          {(email && !showSendButton) && <Typography className="mt-32 text-3xl font-bold text-green-500 leading-tight text-center">
            Email Confirmation has been sent again please recheck your mailbox
          </Typography>}
        </div>
      </Paper>
    </div>
  );
}

export default SignUpEmailConfirmation;
