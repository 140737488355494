import { Typography } from "@mui/material";
import { FeaturedAlbumCard } from "app/shared-components/cards/FeaturedAlbumCard";
import { PlaylistTable } from "./playlist-table";
import {
  getPlaylistById,
  getTracks,
  selectPlaylistDetails,
  selectTracks,
} from "app/store/playlistsSlice";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useCallback, useEffect, useMemo } from "react";
import { getRandomGradient } from "src/app/utils/colors";
import { BackButton } from "app/shared-components/buttons/back-button";

export function PlaylistDetailsPage() {
  const dispatch = useDispatch();
  const params = useParams();
  const playlistDetails = useSelector(selectPlaylistDetails);
  const tracks = useSelector(selectTracks);

  const playlist = playlistDetails?.data?.[0];
  useEffect(() => {
    dispatch(getPlaylistById(params.id));
    dispatch(getTracks(params.id));
  }, [params]);

  return (
    <div>
      <div className="w-full">
        <div
          style={{
            background: `linear-gradient(180deg, #66EEBB 0%, #66EEBB 10%, #121212 100%)`,
          }}
          className="relative px-4 sm:px-8 md:px-16 py-8 md:py-16 flex items-center"
        >
          <BackButton />
          <div className="flex flex-col md:flex-row items-center md:items-end gap-8 md:gap-16 w-full mt-24 md:mt-48">
            <div className="max-w-[200px] md:max-w-xs w-full">
              <FeaturedAlbumCard
                title={playlist?.attributes?.name ?? ""}
                elevation={3}
                splitTitle
                poster={playlist?.attributes?.album_art}
              />
            </div>
            <div className="text-white text-center md:text-left">
              <Typography className="opacity-90">Playlist</Typography>
              <Typography className="text-4xl md:text-7xl font-extrabold text-main-text-color">
                {playlist?.attributes?.name ?? ""}
              </Typography>
              <Typography className="text-slightly-light-text opacity-80">
                {playlist?.attributes?.description}
              </Typography>
            </div>
          </div>
        </div>

        <div className="bg-main-bg px-4 sm:px-8 md:px-16">
          <PlaylistTable tracks={tracks.data ?? []} />
        </div>
      </div>
    </div>
  );
}
