import { useState } from "react";
import axios from "axios";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import * as yup from "yup";
import _ from "@lodash";
import CircularProgress from "@mui/material/CircularProgress";
import jwtService from "../../auth/services/jwtService";
import LoadingSpinner from "app/shared-components/loading-spinner/loading-spinner";
import DividerWithText from "app/shared-components/dividers/DividerWithText";

/**
 * Form Validation Schema
 */
const schema = yup.object().shape({
  userName: yup.string().required("You must enter display name"),
  email: yup
    .string()
    .email("You must enter a valid email")
    .required("You must enter a email"),
  password: yup
    .string()
    .required("Please enter your password.")
    .min(8, "Password is too short - should be 8 chars minimum."),
  passwordConfirm: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match"),
});

const defaultValues = {
  userName: "",
  email: "",
  password: "",
  organization: "",
  orgName: "",
  passwordConfirm: "",
  acceptTermsConditions: false,
};

export function SignUpForm() {
  const [isVerifyingEmail, setIsVerifyingEmail] = useState(false);
  const [isVerifyingUsername, setIsVerifyingUsername] = useState(false);
  const [loading, setLoading] = useState(false);

  const { control, formState, handleSubmit, reset, watch, setError, setValue } =
    useForm({
      mode: "onChange",
      defaultValues,
      resolver: yupResolver(schema),
    });

  const { isValid, dirtyFields, errors } = formState;
  const orgType = watch("organization");

  function onSubmit({ userName, organization, orgName, password, email }) {
    setLoading(true);

    organization = "individual";
    orgName = userName;
    name = userName;
    jwtService
      .createUser({
        userName,
        organization,
        password,
        email,
        name,
      })
      .then((user) => {
        setLoading(false);
        // No need to do anything, registered user data will be set at app/auth/AuthContext
      })
      .catch((error) => {
        setLoading(false);
        setError(error);
      });
  }
  const verifyEmail = async (email) => {
    setIsVerifyingEmail(true);
    try {
      // Make the API call to verify email
      const response = await axios.post(
        `${process.env.REACT_APP_AUTH_BASE_URL}/api/user/verify-email`,
        {
          email: email,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.data === "invalid") {
        setError("email", {
          type: "manual",
          message: "Email already exists",
        });
        setValue("email", email, { shouldDirty: false });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsVerifyingEmail(false);
    }
  };

  const verifyUsername = async (username) => {
    setIsVerifyingUsername(true);
    try {
      // Make the API call to verify email
      const response = await axios.post(
        `${process.env.REACT_APP_AUTH_BASE_URL}/api/user/verify-username`,
        {
          username: username,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.data === "invalid") {
        setError("userName", {
          type: "manual",
          message: "Username already exists",
        });
        setValue("userName", username, { shouldDirty: false });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsVerifyingUsername(false);
    }
  };

  return (
    <form
      name="registerForm"
      noValidate
      className="flex flex-col justify-center w-full"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Controller
        name="email"
        control={control}
        render={({ field }) => (
          <div className="flex">
            <TextField
              {...field}
              className="mb-12 "
              autoFocus
              type="email"
              error={!!errors.email}
              helperText={errors?.email?.message}
              variant="outlined"
              required
              fullWidth
              onChange={(e) => {
                field.onChange(e);
                verifyEmail(e.target.value);
              }}
              style={{
                backgroundColor: "transparent",
              }}
              placeholder="Email"
              inputProps={{
                disableUnderline: true, // Disables the default underline to use modern border
                style: {
                  borderRadius: "6px",
                  backgroundColor: "#FFFBFF",
                  paddingRight: "4px",
                  paddingBottom: "4px",
                  paddingTop: "2px", // Updated padding for a better look and feel
                  fontWeight: 400,
                  height: "5rem", // Fix height of text field
                  fontSize: "1.5rem", // Base font size
                },
              }}
            />
            {isVerifyingEmail && (
              <CircularProgress className="ml-4 mt-8 text-main-text-color" size={24} />
            )}
          </div>
        )}
      />
      <Controller
        name="password"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            className="mb-12 "
            type="password"
            error={!!errors.password}
            helperText={errors?.password?.message}
            variant="outlined"
            required
            fullWidth
            style={{
              backgroundColor: "transparent",
            }}
            placeholder="Enter your password"
            inputProps={{
              disableUnderline: true, // Disables the default underline to use modern border
              style: {
                borderRadius: "6px",
                backgroundColor: "#FFFBFF",
                paddingRight: "4px",
                paddingBottom: "4px",
                paddingTop: "2px", // Updated padding for a better look and feel
                fontWeight: 400,
                height: "5rem", // Fix height of text field
                fontSize: "1.5rem", // Base font size
              },
            }}
          />
        )}
      />
      <Controller
        name="passwordConfirm"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            className="mb-12 cta-text-style "
            style={{
              backgroundColor: "transparent",
            }}
            placeholder="Confirm your password"
            inputProps={{
              disableUnderline: true, // Disables the default underline to use modern border
              style: {
                borderRadius: "6px",
                backgroundColor: "#FFFBFF",
                paddingRight: "4px",
                paddingBottom: "4px",
                paddingTop: "2px", // Updated padding for a better look and feel
                fontWeight: 400,
                height: "5rem", // Fix height of text field
                fontSize: "1.5rem", // Base font size
              },
            }}
            type="password"
            error={!!errors.passwordConfirm}
            helperText={errors?.passwordConfirm?.message}
            variant="outlined"
            required
            fullWidth
          />
        )}
      />
      <Controller
        name="userName"
        control={control}
        render={({ field }) => (
          <div className="flex">
            <TextField
              {...field}
              className="mb-12 "
              type="name"
              error={!!errors.userName}
              helperText={errors?.userName?.message}
              variant="outlined"
              required
              fullWidth
              onChange={(e) => {
                field.onChange(e);
                verifyUsername(e.target.value);
              }}
              style={{
                backgroundColor: "transparent",
                borderRadius: "2px",
              }}
              placeholder="Enter your username"
              inputProps={{
                disableUnderline: true, // Disables the default underline to use modern border
                style: {
                  borderRadius: "6px",
                  backgroundColor: "#FFFBFF",
                  paddingRight: "4px",
                  paddingBottom: "4px",
                  paddingTop: "2px", // Updated padding for a better look and feel
                  fontWeight: 400,
                  height: "5rem", // Fix height of text field
                  fontSize: "1.5rem", // Base font size
                },
              }}
            />
            {isVerifyingUsername && (
              <CircularProgress className="ml-4 mt-8" size={24} />
            )}
          </div>
        )}
      />

      {loading && (
        <div className="mt-4 flex align-center justify-center items-center">
          <LoadingSpinner size={24} />
        </div>
      )}
      {!loading && (
        <Button
          variant="contained"
          className="press-button shine-button mt-16  rounded-md bg-primary hover:bg-primary text-main-bg w-full"
          aria-label="Register"
          // disabled={
          //   _.isEmpty(dirtyFields) ||
          //   !isValid ||
          //   Object.keys(errors).length > 0
          // }
          type="submit"
          size="large"
        >
          Create your account
        </Button>
      )}
    </form>
  );
}
