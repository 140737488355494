import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { useEffect } from 'react';
import history from '@history';

function EmailConfirmation() {
  useEffect(() => {
    const delay = 3000; // 5 seconds
    const timer = setTimeout(() => {
      history.push('/sign-in');
    }, delay);

    // Clear the timer when the component is unmounted to avoid memory leaks
    return () => clearTimeout(timer);
  }, [history]);

  return (
    <div className="flex flex-col flex-auto items-center sm:justify-center min-w-0 bg-[#281463]">
      <Paper className="flex  shadow-md shadow-base-purple items-center rounded-md w-full sm:w-auto min-h-full sm:min-h-auto rounded-0 py-32 px-16 sm:p-48">
        <div className="w-full max-w-320 sm:w-320 mx-auto sm:mx-0">
          <div className="flex w-full items-center align-center justify-center mb-24">
            <img className="logo-icon w-28" src="assets/images/logo/logo.png" alt="logo" />
            <span className="logo-heading">Podyc</span>
          </div>
          <Typography className="mt-32 text-xl font-bold tracking-tight leading-tight text-center">
              Thank You for Confirming your Email
          </Typography>
          <Typography className="mt-32 text-base font-regular tracking-tight leading-tight text-center">
              You will be redirected to login page soon
          </Typography>
        </div>
      </Paper>
    </div>
  );
}

export default EmailConfirmation;
