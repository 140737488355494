
const { format } = require('date-fns');
import FuseUtils from '@fuse/utils/FuseUtils';
import _ from 'lodash';

/**
 * The comment model.
 */
export const CommentModel = (data) =>{
  data = data || {};

  return _.defaults(data, {
    id: FuseUtils.generateGUID(),
    type: 'comment',
    idMember: null,
    message: '',
    time: format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"),
  });
}

