import { Controller, useForm } from 'react-hook-form';
import _ from '@lodash';
import IconButton from '@mui/material/IconButton';
import LinearProgress from '@mui/material/LinearProgress';
import List from '@mui/material/List';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { useState, useEffect, useRef } from 'react';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import CardAddChecklistItem from './CardAddChecklistItem';
import CardChecklistItem from './CardChecklistItem';
import CardChecklistName, { CardChecklistHandle } from './CardChecklistName';

function CardChecklist(props) {
  const { onCheckListChange, checklist, index, onRemoveCheckList } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const checkListNameRef = useRef(null);

  const { watch, control } = useForm({ mode: 'onChange', defaultValues: checklist });
  const form = watch();

  useEffect(() => {
    if (!_.isEqual(form, checklist)) {
      onCheckListChange(form, index);
    }
  }, [form, index, onCheckListChange, checklist]);

  function handleOpenNameForm(ev) {
    handleMenuClose();
    if (checkListNameRef.current) {
      checkListNameRef.current.openForm(ev);
    }
  }

  function handleMenuOpen(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleMenuClose() {
    setAnchorEl(null);
  }

  function checkItemsChecked() {
    return _.sum(form.checkItems.map((x) => (x.checked ? 1 : 0)));
  }

  if (!form) {
    return null;
  }

  return (
    <div className="mb-24">
      <div className="flex items-center justify-between mt-16 mb-12 ml-2">
        <div className="flex items-center">

          <Controller
            name="name"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value } }) => (
              <CardChecklistName name={value} onNameChange={(val) => onChange(val)} ref={checkListNameRef} />
            )}
          />
        </div>
        <div>
          <IconButton
            className='mr-24'
            aria-owns={anchorEl ? 'actions-menu' : null}
            aria-haspopup="true"
            onClick={handleMenuOpen}
            size="small"
          >
            <FuseSvgIcon size={20}>heroicons-outline:dots-vertical</FuseSvgIcon>
          </IconButton>
          <Menu
            id="actions-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            <MenuItem onClick={onRemoveCheckList}>
              <ListItemIcon className="min-w-40">
                <FuseSvgIcon>heroicons-outline:trash</FuseSvgIcon>
              </ListItemIcon>
              <ListItemText primary="Remove Checklist" />
            </MenuItem>
            <MenuItem onClick={handleOpenNameForm}>
              <ListItemIcon className="min-w-40">
                <FuseSvgIcon>heroicons-outline:pencil</FuseSvgIcon>
              </ListItemIcon>
              <ListItemText primary="Rename Checklist" />
            </MenuItem>
          </Menu>
        </div>
      </div>

      <div>
        <div className="flex items-center ml-16">
          <Typography className="flex font-semibold mx-6 text-16">
            {`${checkItemsChecked()} / ${form.checkItems.length}`}
          </Typography>
          <LinearProgress
            className="flex flex-1 ml-12 mr-64"
            style={{
              height: '8px', // Adjust the thickness of the progress bar
              backgroundColor: '#e0e0e0', // Color for the "unfilled" part of the bar
              borderRadius: '4px', // Slight roundness at the edges for a smoother look
            }}
            classes={{
              bar: {
                borderRadius: '4px', // Roundness for the filled part
                backgroundColor: '#4c0050', // Custom color for the filled part
              },
              barColorSecondary: {
                backgroundColor: '#f5ac57', // Custom color if using 'secondary' color variant
              },
            }}
            variant="determinate"
            value={(100 * checkItemsChecked()) / form.checkItems.length}
          />
        </div>
        <Controller
          name="checkItems"
          control={control}
          defaultValue={[]}
          render={({ field: { onChange, value } }) => (
            <List>
              {value.map((checkItem, _index) => (
                <CardChecklistItem
                  item={checkItem}
                  key={checkItem.id}
                  index={_index}
                  onListItemChange={(item, itemIndex) => {
                    onChange(_.setIn(value, `[${itemIndex}]`, item));
                  }}
                  onListItemRemove={() => {
                    onChange(_.reject(value, { id: checkItem.id }));
                  }}
                />
              ))}
              <CardAddChecklistItem  onListItemAdd={(item) => onChange([...value, item])} />
            </List>
          )}
        />
      </div>
    </div>
  );
}

export default CardChecklist;
