import Typography from "@mui/material/Typography";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { loadStripe } from "@stripe/stripe-js";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Grid from "@mui/material/Grid";
import { showMessage } from "app/store/fuse/messageSlice";
import { useSelector, useDispatch } from "react-redux";
import "./subscription.css";
import PlanCard from "app/shared-components/plan-card/plan-card";
import { selectUser } from "app/store/userSlice";
import { useLocation } from "react-router-dom";
import LoadingSpinner from "app/shared-components/loading-spinner/loading-spinner";

function SubscriptionPage() {
  const bgColorsList = ["#fefefe", "#1d1d1d", "#fefefe", "#F3F9A1", "#F9F0E1"];
  const textColorsList = [
    "#000000",
    "#FFFFFF",
    "#000000",
    "#F3F9A1",
    "#F9F0E1",
  ];

  const [plans, setPlans] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [term, setTerm] = useState("monthly");
  const user = useSelector(selectUser);
  const location = useLocation();
  const stripePromise = loadStripe(
    process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
  );
  const dispatch = useDispatch();

  useEffect(() => {
    fetchPlans();
  }, [term]);

  const handleTerm = (event, newTerm) => {
    setTerm(newTerm);
  };

  async function fetchPlans() {
    try {
      setIsLoading(true);
      const response = await axios
        .get(
          `${process.env.REACT_APP_AUTH_BASE_URL}/api/plans?filters[type][$eq]=${term}`
        )
        .catch((error) => {
          console.log(error);
        });
      var plans = response.data.data;
      plans = response.data.data.filter(function (obj) {
        return obj.attributes.name !== "Trial";
      });
      plans.sort((a, b) => a.attributes.paid - b.attributes.paid);
      setPlans(plans);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  const handlePaymnent = async (value) => {
    try {
      if (value?.attributes.name === "Trial") {
        return;
      }
      setIsLoading(true);
      if (
        user.data.organization?.plan?.id === value.id &&
        user.data.organization?.current_month_usage
      ) {
        const createdDate = new Date(
          user.data.organization?.current_month_usage?.createdAt
        );
        const endDate = new Date(createdDate);
        if (organization.plan.type === "monthly") {
          endDate.setMonth(createdDate.getMonth() + 1);
        } else {
          endDate.setFullYear(createdDate.getFullYear() + 1);
        }
        if (currentDate <= endDate) {
          dispatch(
            showMessage({ message: "Currently you are on the same plan" })
          );
          return;
        }
      }
      const stripe = await stripePromise;
      const response = await axios
        .post(
          `${process.env.REACT_APP_AUTH_BASE_URL}/api/subscription-orders`,
          { type: "subscription", plan: value.id }
        )
        .catch((error) => {
          console.log(error);
          dispatch(
            showMessage({
              message: "Something went worng. Try after some time",
            })
          );
        });
      const res = await stripe.redirectToCheckout({
        sessionId: response.data.stripeSession.id,
      });
    } catch (e) {
      console.log(e);
      dispatch(
        showMessage({ message: "Something went worng. Try after some time" })
      );
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <div className="flex h-full flex-col bg-[#fefefe]">
      <Typography className="gradient-text flex w-full justify-center my-32 font-extrabold text-7xl text-black">Get all features. Pay only for credits</Typography>

      <div className="flex w-full justify-center">
        <ToggleButtonGroup
          value={term}
          exclusive
          onChange={handleTerm}
          aria-label="Subscription term"

         
        >
          <ToggleButton value="monthly" aria-label="Mothly subscription">
            Monthly
          </ToggleButton>
          <ToggleButton value="yearly" aria-label="Year subscription">
            Yearly
          </ToggleButton>
        </ToggleButtonGroup>
      </div>

      {isLoading ? (
        <LoadingSpinner className="w-56 mt-32 self-center"></LoadingSpinner>
      ) : (
        <div className="mt-24 ml-24">
          <Grid container justifyContent="center" spacing={6}>
            {plans?.map((value, index) => (
              <Grid key={value.id} item>
                <PlanCard
                  key={value.id}
                  plan={value}
                  color={bgColorsList[index]}
                  textColor={textColorsList[index]}
                  isPopular={
                    value.attributes.paid === 49 ||
                    value.attributes.paid === 529
                      ? true
                      : false
                  }
                  isCurrentPlan={
                    user.data.organization?.plan?.id === value.id ? true : false
                  }
                  onClick={(e) => handlePaymnent(e)}
                  buttonText={
                    user.data.organization?.plan?.id === value.id
                      ? "Renew"
                      : location.state === "update"
                      ? "Change Plan"
                      : "Subscribe"
                  }
                ></PlanCard>
              </Grid>
            ))}
          </Grid>
        </div>
      )}
    </div>
  );
}

export default SubscriptionPage;
