import KnowledgeBaseDashboardAppConfig from "./knowledge_base/KnowledgeBaseDashboardAppConfig";
import UsageDashboardAppConfig from "./home/HomeDashboardAppConfig";
import QueryDashboardAppConfig from "./query/QueryDashboardAppConfig";
import OrganisationConfig from "./organisation/OrganisationConfig";
import HistoryQueriesAppConfig from "./historyQueries/HistoryQueriesAppConfig";
import ScrumboardConfig from "./scrumboard/ScrumboardConfig";

export const dashboardSettingsConfig = {
  settings: {
    layout: {
      config: {
        navbar: {
          display: true,
        },
        toolbar: {
          display: false,
        },
        footer: {
          display: false,
        },
        leftSidePanel: {
          display: false,
        },
        rightSidePanel: {
          display: false,
        },
      },
    },
  },
};
const dashboardsConfigs = [
  UsageDashboardAppConfig,
  QueryDashboardAppConfig,
  KnowledgeBaseDashboardAppConfig,
  OrganisationConfig,
  HistoryQueriesAppConfig,
  ScrumboardConfig,
].map((d) => ({
  ...d,
  ...dashboardSettingsConfig,
}));

export default dashboardsConfigs;
