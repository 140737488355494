import authRoles from "../../auth/authRoles";
import { YourFeaturedPlaylistPage } from "./YourFeaturedPlaylistPage";

export const YourFeaturedPlaylistsPageConfig = {
  settings: {
    layout: {
      config: {
        navbar: {
          display: true,
        },
        toolbar: {
          display: false,
        },
        footer: {
          display: false,
        },
        leftSidePanel: {
          display: false,
        },
        rightSidePanel: {
          display: false,
        },
      },
    },
  },
  auth: authRoles.user,
  routes: [
    {
      path: "/your-featured-playlists",
      element: <YourFeaturedPlaylistPage />,
    },
  ],
};
