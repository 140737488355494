import React, { lazy } from 'react';
import withReducer from 'app/store/withReducer';

/**
 * A Higher Order Component that lazily loads a component and injects the provided reducer.
 */
const lazyWithReducer = (key, importFunction, reducer) => {
  const LazyComponent = lazy(importFunction);
  return withReducer(key, reducer)(LazyComponent);
};

export default lazyWithReducer;
