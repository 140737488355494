import React from 'react';

export const humanReadableNumber = (value) => {

   if(value === 0){
    return "0"
   }
    // Handle very small numbers
    if (Math.abs(value) < 1) {
      return value.toExponential(0);
    }

    // Define suffixes for large numbers
    const suffixes = ["", " thousand", " million", " billion", " trillion", " quadrillion", " quintillion"];
    const tier = Math.log10(Math.abs(value)) / 3 | 0;

    if (tier === 0) {
      return Math.round(value).toString();
    }

    const suffix = suffixes[tier];
    const scale = Math.pow(10, tier * 3);

    const scaledValue = value / scale;

    return `${scaledValue}${suffix}`;
  };

export const HumanReadableNumber = ({ value }) => {
  return (
    <span>{humanReadableNumber(value)}</span>
  );
};