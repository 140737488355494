import IconButton from "@mui/material/IconButton";
import { useDispatch, useSelector } from "react-redux";
import {
  selectFuseCurrentSettings,
  setDefaultSettings,
} from "app/store/fuse/settingsSlice";
import _ from "@lodash";
import useThemeMediaQuery from "@fuse/hooks/useThemeMediaQuery";
import { navbarToggle, navbarToggleMobile } from "app/store/fuse/navbarSlice";
import FuseSvgIcon from "@fuse/core/FuseSvgIcon";
import { MenuIcon } from "lucide-react";

function NavbarToggleButton(props) {
  const dispatch = useDispatch();
  const isMobile = useThemeMediaQuery((theme) => theme.breakpoints.down("lg"));
  const settings = useSelector(selectFuseCurrentSettings);
  const { config } = settings.layout;

  return (
    <IconButton
      className="flex-none"
      color="inherit"
      size="medium"
      onClick={(ev) => {
        if (isMobile) {
          dispatch(navbarToggleMobile());
        } else if (config.navbar.style === "style-2") {
          dispatch(
            setDefaultSettings(
              _.set(
                {},
                "layout.config.navbar.folded",
                !settings.layout.config.navbar.folded
              )
            )
          );
        } else {
          dispatch(navbarToggle());
        }
      }}
    >
      <MenuIcon className="text-white" color="white" />
    </IconButton>
  );
}

export default NavbarToggleButton;
