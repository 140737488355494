/**
 * Settings Type
 */
const SettingsType = {
	subscribed: false,
	cardCoverImages: false
  };
  
  /**
   * Board Type
   */
  const BoardType = {
	id: '',
	title: '',
	description: '',
	lastActivity: '',
	icon: '',
	members: [],
	settings: SettingsType,
	lists: [],
	labels: []
  };
  
  /**
   * Boards Type
   */
  const BoardsType = [];
  