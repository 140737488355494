// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.signin-checkbox > .MuiFormControlLabel-label{
    font-size: 9px !important;
    margin-left: 0px;
    width: 100%;
}

 .MuiFormControl-root{
    margin-left: 0px;
    width: 100% !important;
}

.logo {
    max-width: 70px; /* Set the maximum width of the logo */
    max-height: 70px; /* Set the maximum height of the logo */
    margin-bottom: 10px;
  }

.no-underline  {
    text-decoration: none !important;
}

.radio-button-style{
    color : #7F56D9 !important;
    stroke-width: 0.1px!important; /* IE 9 */ /* Chrome, Safari, Opera */
    transform: scale(0.75667085);
    padding: 0 !important;

}

.cta-text-style{ 
    text-decoration: none !important; 
    font-weight:500;
    color : #7F56D9 !important;
}

.cta-label-style{
    color: #667085 !important;
}

.cta-link-style{
    color: #000000 !important;
}

.label-style{
    color:#344054
}
.google-image{
    height: 20px;
    margin-left: -4px;
    margin-right: 8px;
    min-width: 20px;
    width: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/app/main/sign-in/sign-in.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,gBAAgB;IAChB,WAAW;AACf;;CAEC;IACG,gBAAgB;IAChB,sBAAsB;AAC1B;;AAEA;IACI,eAAe,EAAE,sCAAsC;IACvD,gBAAgB,EAAE,uCAAuC;IACzD,mBAAmB;EACrB;;AAEF;IACI,gCAAgC;AACpC;;AAEA;IACI,0BAA0B;IAC1B,6BAA6B,EACD,SAAS,EACL,0BAA0B;IAC1D,4BAA4B;IAC5B,qBAAqB;;AAEzB;;AAEA;IACI,gCAAgC;IAChC,eAAe;IACf,0BAA0B;AAC9B;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI;AACJ;AACA;IACI,YAAY;IACZ,iBAAiB;IACjB,iBAAiB;IACjB,eAAe;IACf,WAAW;AACf","sourcesContent":[".signin-checkbox > .MuiFormControlLabel-label{\n    font-size: 9px !important;\n    margin-left: 0px;\n    width: 100%;\n}\n\n .MuiFormControl-root{\n    margin-left: 0px;\n    width: 100% !important;\n}\n\n.logo {\n    max-width: 70px; /* Set the maximum width of the logo */\n    max-height: 70px; /* Set the maximum height of the logo */\n    margin-bottom: 10px;\n  }\n\n.no-underline  {\n    text-decoration: none !important;\n}\n\n.radio-button-style{\n    color : #7F56D9 !important;\n    stroke-width: 0.1px!important;\n    -ms-transform: scale(0.75); /* IE 9 */\n    -webkit-transform: scale(0.75); /* Chrome, Safari, Opera */\n    transform: scale(0.75667085);\n    padding: 0 !important;\n\n}\n\n.cta-text-style{ \n    text-decoration: none !important; \n    font-weight:500;\n    color : #7F56D9 !important;\n}\n\n.cta-label-style{\n    color: #667085 !important;\n}\n\n.cta-link-style{\n    color: #000000 !important;\n}\n\n.label-style{\n    color:#344054\n}\n.google-image{\n    height: 20px;\n    margin-left: -4px;\n    margin-right: 8px;\n    min-width: 20px;\n    width: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
