import FusePageSimple from "@fuse/core/FusePageSimple";
import { Typography } from "@mui/material";
import AlbumCard from "app/shared-components/cards/albumCard";
import { PageHeader } from "app/shared-components/PageHeader";
import { getRandomPoster } from "src/app/utils/album";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import {
  getFileResult,
  selectFileResult,
  selectIsPlaying,
  setIsPlaying,
  setSelectedFileResult,
} from "src/app/store/fileResultSlice";

export function MyPodcastsPage() {
  const { id } = useLocation().state || {};
  const dispatch = useDispatch();
  const fileResult = useSelector(selectFileResult);

  useEffect(() => {
    dispatch(getFileResult(id));
  }, [id]);

  return (
    <FusePageSimple
      className="px-16 bg-main-bg "
      content={
        <div className="flex flex-col  w-full py-16 bg-main-bg min-h-screen">
          <PageHeader title={"My Podcasts"} />

          <div className="grid lg:grid-cols-6 gap-0 grid-cols-1 sm:grid-cols-3 md:grid-cols-4">
            {fileResult.data.map((album) => (
              <AlbumCard album={album} />
            ))}
          </div>
        </div>
      }
    />
  );
}
