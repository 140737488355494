import authRoles from "../../../auth/authRoles";
import { MyPodcastsPage } from "./MyPodcastsPage";

export const MyPodcastsPageConfig = {
  settings: {
    layout: {
      config: {
        navbar: {
          display: true,
        },
        toolbar: {
          display: false,
        },
        footer: {
          display: false,
        },
        leftSidePanel: {
          display: false,
        },
        rightSidePanel: {
          display: false,
        },
      },
    },
  },
  auth: authRoles.user,
  routes: [
    {
      path: "/my-podcasts",
      element: <MyPodcastsPage />,
    },
  ],
};
