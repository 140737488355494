import React from 'react';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';

function FileViewerList({ files, onRemove }) {
	return (
		<div className="w-full mt-30">
			<ul className="border border-gray-300 rounded-lg">
				{files.map((file, index) => (
					<li
						key={index}
						className="flex flie-list items-center justify-between p-18"
					>
						<div className="flex w-full justify-between items-center">
							<p className="truncate max-w-md">{file.name}</p>
							<button
								className="text-red-600 hover:text-red-700 text-lg mr-10"
								onClick={() => onRemove(index)}
							>
								<FuseSvgIcon
									className="text-black-600"
									size={20}
									color="action"
								>
									heroicons-solid:x-circle
								</FuseSvgIcon>
							</button>
						</div>
					</li>
				))}
			</ul>
			<div className="mt-16 ml-4 text-sm">
				Submit this document for ingestion. Ingestion can take up to 2
				minutes.
			</div>
		</div>
	);
}

export default FileViewerList;
