import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

// Assuming RootStateType and createAppAsyncThunk are imported correctly
// You might need to adjust the import paths based on your project structure

// Define the AppRootStateType
const AppRootStateType = (state) => state; // Replace with your RootStateType definition

/**
 * Get Labels
 */
export const getLabels = (boardId) => async (dispatch, getState) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_AUTH_BASE_URL}/api/labels`);
    const data = response.data.data;
    // const data =[
    //   {
    //     id: "e0175175-2784-48f1-a519-a1d2e397c9b3",
    //     boardId: "2c82225f-2a6c-45d3-b18a-1132712a4234",
    //     title: "Research",
    //   },
    //   {
    //     id: "51779701-818a-4a53-bc16-137c3bd7a564",
    //     boardId: "2c82225f-2a6c-45d3-b18a-1132712a4234",
    //     title: "Wireframing",
    //   },
    //   {
    //     id: "e8364d69-9595-46ce-a0f9-ce428632a0ac",
    //     boardId: "2c82225f-2a6c-45d3-b18a-1132712a4234",
    //     title: "Design",
    //   },
    //   {
    //     id: "caff9c9b-a198-4564-b1f4-8b3df1d345bb",
    //     boardId: "2c82225f-2a6c-45d3-b18a-1132712a4234",
    //     title: "Development",
    //   },
    //   {
    //     id: "f9eeb436-13a3-4208-a239-0d555960a567",
    //     boardId: "2c82225f-2a6c-45d3-b18a-1132712a4234",
    //     title: "Bug",
    //   },
    // ];

    dispatch({ type: 'scrumboardApp/labels/getLabels/fulfilled', payload: data });
  } catch (error) {
    // Handle error if needed
    console.error(error);
  }
};

const labelsAdapter = createEntityAdapter({});

export const selectLabels = (state) => {
  return state.scrumboardApp.labels.ids.map((id) => state.scrumboardApp.labels.entities[id]);
}
// labelsAdapter.getSelectors().selectAll(state);

export const selectLabelById = (id) => (state) => {
  return state.scrumboardApp.labels.entities[id];
}
// labelsAdapter.getSelectors().selectById(state, id);

/**
 * The Scrumboard Labels Slice.
 */
const labelsSlice = createSlice({
  name: 'scrumboardApp/labels',
  initialState: labelsAdapter.getInitialState({}),
  reducers: {
    resetLabels: () => {},
  },
  extraReducers: (builder) => {
    builder.addCase('scrumboardApp/labels/getLabels/fulfilled', (state, action) =>
      labelsAdapter.setAll(state, action.payload)
    );
  },
});

export const { resetLabels } = labelsSlice.actions;

export const LabelsSliceType = labelsSlice;

export default labelsSlice.reducer;
