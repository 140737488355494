export function formatDateToHumanReadable(isoDateString) {
    const date = new Date(isoDateString);

    const options = { month: 'long', day: 'numeric', year: 'numeric' };
    const dateParts = date.toLocaleDateString('en-US', options).split(' ');

    const day = parseInt(dateParts[1].replace(',', ''));
    const daySuffix = getDaySuffix(day);

    return `${dateParts[0]} ${day}${daySuffix}, ${dateParts[2]}`;
}

export function getDaySuffix(day) {
    if (day > 3 && day < 21) return 'th'; // covers 11th to 20th
    switch (day % 10) {
        case 1: return 'st';
        case 2: return 'nd';
        case 3: return 'rd';
        default: return 'th';
    }
}

