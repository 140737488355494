import { useState } from "react";
import axios from "axios";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import * as yup from "yup";
import _ from "@lodash";
import CircularProgress from "@mui/material/CircularProgress";
import jwtService from "../../auth/services/jwtService";
import LoadingSpinner from "app/shared-components/loading-spinner/loading-spinner";
import DividerWithText from "app/shared-components/dividers/DividerWithText";

/**
 * Form Validation Schema
 */
const schema = yup.object().shape({
  userName: yup.string().required("You must enter display name"),
  email: yup
    .string()
    .email("You must enter a valid email")
    .required("You must enter a email"),
  password: yup
    .string()
    .required("Please enter your password.")
    .min(8, "Password is too short - should be 8 chars minimum."),
  passwordConfirm: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match"),
});

const defaultValues = {
  userName: "",
  email: "",
  password: "",
  organization: "",
  orgName: "",
  passwordConfirm: "",
  acceptTermsConditions: false,
};

function SignUpPage() {
  const [isVerifyingEmail, setIsVerifyingEmail] = useState(false);
  const [isVerifyingUsername, setIsVerifyingUsername] = useState(false);
  const [loading, setLoading] = useState(false);

  const { control, formState, handleSubmit, reset, watch, setError, setValue } =
    useForm({
      mode: "onChange",
      defaultValues,
      resolver: yupResolver(schema),
    });

  const { isValid, dirtyFields, errors } = formState;
  const orgType = watch("organization");

  function onSubmit({ userName, organization, orgName, password, email }) {
    setLoading(true);

    organization = "individual";
    orgName = userName;
    name = userName;
    jwtService
      .createUser({
        userName,
        organization,
        password,
        email,
        name,
      })
      .then((user) => {
        setLoading(false);
        // No need to do anything, registered user data will be set at app/auth/AuthContext
      })
      .catch((error) => {
        setLoading(false);
        setError(error);
      });
  }
  const verifyEmail = async (email) => {
    setIsVerifyingEmail(true);
    try {
      // Make the API call to verify email
      const response = await axios.post(
        `${process.env.REACT_APP_AUTH_BASE_URL}/api/user/verify-email`,
        {
          email: email,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.data === "invalid") {
        setError("email", {
          type: "manual",
          message: "Email already exists",
        });
        setValue("email", email, { shouldDirty: false });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsVerifyingEmail(false);
    }
  };

  const verifyUsername = async (username) => {
    setIsVerifyingUsername(true);
    try {
      // Make the API call to verify email
      const response = await axios.post(
        `${process.env.REACT_APP_AUTH_BASE_URL}/api/user/verify-username`,
        {
          username: username,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.data === "invalid") {
        setError("userName", {
          type: "manual",
          message: "Username already exists",
        });
        setValue("userName", username, { shouldDirty: false });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsVerifyingUsername(false);
    }
  };

  return (
    <div className="flex flex-col  xl:flex-row  2xl:flex-row  lg:flex-row md:flex-row w-full h-full align-center items-center justify-end bg-[#FeFeFF]">
      <div className="flex flex-col  bg-[#fafaf6] mb-16 xl:mt-[-32px] 2xl:mt-[-32px] 3xl:mt-[-32px] md:mt-[-32px] lg:mt-[-32px] w-full  2xl:w-[40%] xl:w-[40%] md:w-[40%] lg:w-[40%] h-full align-center items-center justify-center sm:mb-16 xs:mb-16">
        <div className="w-full p-32 max-w-480">
          <div className="flex w-full justify-start">
            <img
              className="logo-icon mt-2  w-24 h-24"
              src="assets/images/logo/logo.png"
              alt="logo"
            />
            <span className="flex ml-8 text-2xl font-bold md:block">
              Podyc
            </span>
          </div>
          <Typography className="mt-8 text-5xl font-extrabold text-start tracking-tight">
            Signup
          </Typography>

          <Typography className="mt-4 text-base font-regular tracking-tight text-start text-black">
            Get started - it's free. No credit card required.
          </Typography>

          <div className="m-16 my-32">
            <div
              class="flex p-12 shadow-lg rounded-8 border-solid border-1 !border-grey-500  place-content-center cursor-pointer hover:bg-gray-800 hover:text-white"
              onClick={() => {
                (window.location = `${process.env.REACT_APP_AUTH_BASE_URL}/api/connect/google`);
              }}
            >
              <div class="h-16 mt-1 mr-8 min-w-16 w-16">
                <svg
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 48 48"
                  class="LgbsSe-Bz112c"
                >
                  <g>
                    <path
                      fill="#EA4335"
                      d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"
                    ></path>
                    <path
                      fill="#4285F4"
                      d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"
                    ></path>
                    <path
                      fill="#FBBC05"
                      d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"
                    ></path>
                    <path
                      fill="#34A853"
                      d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"
                    ></path>
                    <path fill="none" d="M0 0h48v48H0z"></path>
                  </g>
                </svg>
              </div>
              <span class="nsm7Bb-HzV7m-LgbsSe-BPrWId font-medium">
                Sign in with Google
              </span>
            </div>
          </div>

          <DividerWithText> Or </DividerWithText>

          <form
            name="registerForm"
            noValidate
            className="flex flex-col justify-center w-full mt-32"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <div className="flex">
                  <TextField
                    {...field}
                    className="mb-12 px-16"
                    autoFocus
                    type="email"
                    error={!!errors.email}
                    helperText={errors?.email?.message}
                    variant="outlined"
                    required
                    fullWidth
                    onChange={(e) => {
                      field.onChange(e);
                      verifyEmail(e.target.value);
                    }}
                    style={{
                      backgroundColor: "transparent",
                    }}
                    placeholder="Email"
                    inputProps={{
                      disableUnderline: true, // Disables the default underline to use modern border
                      style: {
                        borderRadius: "6px",
                        backgroundColor: "#FFFBFF",
                        paddingRight: "4px",
                        paddingBottom: "4px",
                        paddingTop: "2px", // Updated padding for a better look and feel
                        fontWeight: 400,
                        height: "5rem", // Fix height of text field
                        fontSize: "1.5rem", // Base font size
                      },
                    }}
                  />
                  {isVerifyingEmail && (
                    <CircularProgress className="ml-4 mt-8" size={24} />
                  )}
                </div>
              )}
            />
            <Controller
              name="password"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  className="mb-12 px-16"
                  type="password"
                  error={!!errors.password}
                  helperText={errors?.password?.message}
                  variant="outlined"
                  required
                  fullWidth
                  style={{
                    backgroundColor: "transparent",
                  }}
                  placeholder="Enter your password"
                  inputProps={{
                    disableUnderline: true, // Disables the default underline to use modern border
                    style: {
                      borderRadius: "6px",
                      backgroundColor: "#FFFBFF",
                      paddingRight: "4px",
                      paddingBottom: "4px",
                      paddingTop: "2px", // Updated padding for a better look and feel
                      fontWeight: 400,
                      height: "5rem", // Fix height of text field
                      fontSize: "1.5rem", // Base font size
                    },
                  }}
                />
              )}
            />
            <Controller
              name="passwordConfirm"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  className="mb-12 cta-text-style px-16"
                  style={{
                    backgroundColor: "transparent",
                  }}
                  placeholder="Confirm your password"
                  inputProps={{
                    disableUnderline: true, // Disables the default underline to use modern border
                    style: {
                      borderRadius: "6px",
                      backgroundColor: "#FFFBFF",
                      paddingRight: "4px",
                      paddingBottom: "4px",
                      paddingTop: "2px", // Updated padding for a better look and feel
                      fontWeight: 400,
                      height: "5rem", // Fix height of text field
                      fontSize: "1.5rem", // Base font size
                    },
                  }}
                  type="password"
                  error={!!errors.passwordConfirm}
                  helperText={errors?.passwordConfirm?.message}
                  variant="outlined"
                  required
                  fullWidth
                />
              )}
            />
            <Controller
              name="userName"
              control={control}
              render={({ field }) => (
                <div className="flex">
                  <TextField
                    {...field}
                    className="mb-12 px-16"
                    type="name"
                    error={!!errors.userName}
                    helperText={errors?.userName?.message}
                    variant="outlined"
                    required
                    fullWidth
                    onChange={(e) => {
                      field.onChange(e);
                      verifyUsername(e.target.value);
                    }}
                    style={{
                      backgroundColor: "transparent",
                      borderRadius: "2px",
                    }}
                    placeholder="Enter your username"
                    inputProps={{
                      disableUnderline: true, // Disables the default underline to use modern border
                      style: {
                        borderRadius: "6px",
                        backgroundColor: "#FFFBFF",
                        paddingRight: "4px",
                        paddingBottom: "4px",
                        paddingTop: "2px", // Updated padding for a better look and feel
                        fontWeight: 400,
                        height: "5rem", // Fix height of text field
                        fontSize: "1.5rem", // Base font size
                      },
                    }}
                  />
                  {isVerifyingUsername && (
                    <CircularProgress className="ml-4 mt-8" size={24} />
                  )}
                </div>
              )}
            />

            {loading && (
              <div className="mt-4 flex align-center justify-center items-center">
                <LoadingSpinner size={24} />
              </div>
            )}
            {!loading && (
              <Button
                variant="contained"
                className="press-button shine-button mt-16 ml-16  max-w-[92%] rounded-md bg-primary hover:bg-primary text-main-bg"
                aria-label="Register"
                // disabled={
                //   _.isEmpty(dirtyFields) ||
                //   !isValid ||
                //   Object.keys(errors).length > 0
                // }
                type="submit"
                size="large"
              >
                Create your account
              </Button>
            )}
          </form>
        </div>
        <div className=" text-base font-normal tracking-tight text-black">
          By proceeding, you agree to our{" "}
          <a
            href="https://app.termly.io/document/terms-of-service/6a5187f5-d98b-466c-9172-86ed1ec33973"
            className="underline bg-yellow-200 hover:bg-yellow-300"
          >
            Terms of Service {"  "}
          </a>
        </div>

        <div className="mt-36 flex items-baseline font-regular text-center justify-center text-base text-black">
          <p>Already have an account?</p>
          <a className="ml-4 text-main-bg font-bold" href="/sign-in">
            Login{" "}
          </a>
        </div>
      </div>
      <div className="flex w-full 2xl:w-[60%] xl:w-[60%] md:w-[60%] lg:w-[60%] h-full relative bg-[#000000]">
      <div className="absolute inset-0 flex items-center justify-center">
          <div className="relative w-[60%] h-[60%]">
            <img
              src="assets/images/login_creative.png"
              alt=""
              className="object-contain w-full h-full relative z-0"
            />
            <div className="absolute inset-0 z-10"
              style={{
                background: `
                  radial-gradient(
                    circle at center,
                    transparent 10%,
                    rgba(0,0,0,0.4) 30%,
                    rgba(0,0,0,0.9) 50%,
                    rgba(0,0,0,1) 60%
                  )
                `
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignUpPage;
