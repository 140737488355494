import React, { useRef, useEffect, useState, useMemo } from "react";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import "@videojs/http-streaming"; // HLS support for Video.js
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import SkipNextIcon from "@mui/icons-material/SkipNext";
import SkipPreviousIcon from "@mui/icons-material/SkipPrevious";
import ShuffleIcon from "@mui/icons-material/Shuffle";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import { Avatar, IconButton, Stack, Typography } from "@mui/material";
import { CgPlayButtonR } from "react-icons/cg";
import { HiOutlineQueueList } from "react-icons/hi2";
import {
  selectCurrentTrack,
  selectShowPlayerView,
  setIsPlaying,
  setShowPlayerView,
  selectIsPlaying,
} from "app/store/playerSlice";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

const AudioPlayerUI = () => {
  const playerRef = useRef(null);
  const playerInstance = useRef(null); // New ref to store player instance
  const [volume, setVolume] = useState(1); // Volume state (1 is 100%, 0 is muted)
  const [progress, setProgress] = useState(0); // Progress of the audio
  const [duration, setDuration] = useState(0);
  const dispatch = useDispatch();
  const isPlaying = useSelector(selectIsPlaying);
  const showPlayerView = useSelector(selectShowPlayerView);
  const currentTrack = useSelector(selectCurrentTrack);

  const src = useMemo(
    () => currentTrack?.attributes?.audio_url,
    [currentTrack]
  );
  const trackTitle = useMemo(
    () => currentTrack?.attributes?.title,
    [currentTrack]
  );
  const poster = useMemo(
    () => currentTrack?.attributes?.feature_image_url,
    [currentTrack]
  );

  function handlePlay() {
    dispatch(setIsPlaying(true));
  }
  function handlePause() {
    dispatch(setIsPlaying(false));
  }

  useEffect(() => {
    // Initialize player only once without source
    if (!playerInstance.current) {
      const player = videojs(playerRef.current, {
        controls: false,
        autoplay: false,
        preload: "auto",
        fluid: true,
        responsive: true,
        techOrder: ["html5"],
        html5: {
          hls: {
            enableLowInitialPlaylist: true,
            smoothQualityChange: true,
            overrideNative: true,
            withCredentials: false,
            maxMaxBufferLength: 30,
            retryOnError: true,
            debug: true,
          },
        },
      });
      playerInstance.current = player;

      return () => {
        if (playerInstance.current) {
          playerInstance.current.dispose();
        }
      };
    }
  }, []); // Empty dependency array - runs once on mount

  useEffect(() => {
    if (src && playerInstance.current) {
      // Set up event listeners
      playerInstance.current.on("play", handlePlay);
      playerInstance.current.on("pause", handlePause);
      playerInstance.current.on("error", (e) => {
        console.error("Player error:", playerInstance.current.error());
        console.error("Error details:", playerInstance.current.error().message);

        playerInstance.current.reset();
        playerInstance.current.src({
          src: src,
          type: "application/x-mpegURL",
        });
      });

      playerInstance.current.on("timeupdate", () => {
        setProgress(playerInstance.current.currentTime());
        setDuration(playerInstance.current.duration());
      });

      playerInstance.current.on("loadstart", () => {
        console.log("Load Start");
      });

      playerInstance.current.on("loadedmetadata", () => {
        console.log("Loaded Metadata");
      });

      playerInstance.current.on("loadeddata", () => {
        console.log("Loaded Data");
      });

      playerInstance.current.on("waiting", () => {
        console.log("Waiting for data");
      });
    }
  }, [src]);

  // Handle source changes
  useEffect(() => {
    if (playerInstance.current && src) {
      playerInstance.current.src({
        src: src,
        type: "application/x-mpegURL",
      });
    }
  }, [src]);

  // Handle shouldPlay changes
  useEffect(() => {
    if (playerInstance.current) {
      if (isPlaying) {
        playerInstance.current.play();
      } else {
        playerInstance.current.pause();
      }
    }
  }, [isPlaying, src]);

  // Update togglePlay to use playerInstance
  const togglePlay = () => {
    if (playerInstance.current) {
      if (isPlaying) {
        playerInstance.current.pause();
      } else {
        playerInstance.current.play();
      }
    }
  };

  // Update other control handlers to use playerInstance
  const handleVolumeChange = (e) => {
    if (playerInstance.current) {
      const newVolume = parseFloat(e.target.value);
      setVolume(newVolume);
      playerInstance.current.volume(newVolume);
    }
  };

  const handleSeekChange = (e) => {
    if (playerInstance.current) {
      const newTime = parseFloat(e.target.value);
      playerInstance.current.currentTime(newTime);
    }
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const sec = Math.floor(seconds % 60);
    return `${minutes}:${sec < 10 ? `0${sec}` : sec}`;
  };

  return (
    <div className="bg-gray-900 w-full rounded-none py-4 px-32 sm:z-20 relative">
      <div className="flex flex-col sm:flex-row gap-8 sm:gap-16 items-center">
        {/* left */}
        <div className="flex-col sm:flex-row gap-8 hidden sm:flex items-center w-full sm:w-auto">
          <Avatar src={poster} variant="rounded" className="flex-none" />

          <div className="audio-player-marquee-container">
            <Typography className="inline-block animate-audio-player-marquee whitespace-nowrap text-white">
              {trackTitle}
            </Typography>
          </div>
        </div>
        {/* middle  */}

        <Stack className="flex-1 items-center w-full">
          {/* Left Controls (Shuffle, Previous, Play, Next, Repeat) */}
          <Stack direction={"row"} gap={1}>
            <IconButton size="small">
              <ShuffleIcon className="text-gray-300 w-18 h-18" />
            </IconButton>
            <IconButton size="small">
              <SkipPreviousIcon className="text-gray-300 w-18 h-18" />
            </IconButton>
            <IconButton size="small" onClick={togglePlay}>
              {isPlaying ? (
                <PauseIcon className="text-gray-300 w-18 h-18" />
              ) : (
                <PlayArrowIcon className="text-gray-300 w-18 h-18" />
              )}
            </IconButton>
            <IconButton size="small">
              <SkipNextIcon className="text-gray-300 w-18 h-18" />
            </IconButton>
          </Stack>
          <input
            type="range"
            min="0"
            max={duration}
            value={progress}
            step="0.01"
            onChange={handleSeekChange}
            className="w-full mt-[14px] h-4 bg-gray-600 rounded-full appearance-none cursor-pointer"
          />
          <div className="flex justify-between w-full text-xs text-gray-400 mt-4">
            <span>{formatTime(progress)}</span>
            <span>{formatTime(duration)}</span>
          </div>
        </Stack>

        {/* right  */}
        <Stack direction={"row"}>
          {/* Right Controls (Volume, Chat, Menu) */}
          <IconButton
            size="small"
            onClick={() => {
              dispatch(setShowPlayerView(!showPlayerView));
            }}
          >
            <CgPlayButtonR
              className={`text-white w-16 h-16 ${
                showPlayerView ? "text-green-500" : ""
              }`}
            />
          </IconButton>
          <IconButton size="small">
            <HiOutlineQueueList className="text-white  w-16 h-16" />
          </IconButton>
          <div className="flex items-center space-x-8 text-gray-300 pr-8">
            {/* Volume Control */}
            <div className="flex items-center">
              <VolumeUpIcon className="text-gray-300" fontSize="medium" />
              <input
                type="range"
                min="0"
                max="1"
                step="0.01"
                value={volume}
                onChange={handleVolumeChange}
                className="mx-2 w-96 h-4 bg-gray-600 rounded-full appearance-none cursor-pointer"
              />
            </div>
          </div>
        </Stack>
      </div>

      {/* Custom styles for the range input */}
      <style jsx>{`
        input[type="range"]::-webkit-slider-thumb {
          -webkit-appearance: none;
          appearance: none;
          width: 10px;
          height: 10px;
          background: white;
          cursor: pointer;
          border-radius: 50%;
        }
        input[type="range"]::-moz-range-thumb {
          width: 10px;
          height: 10px;
          background: white;
          cursor: pointer;
          border-radius: 50%;
        }
      `}</style>

      {/* Hidden Player Element */}
      <div style={{ display: "none" }}>
        <video
          ref={playerRef}
          className="video-js vjs-default-skin"
          controls
          preload="auto"
        ></video>
      </div>

      {/* Add this CSS to your styles */}
      <style jsx>{`
        .audio-player-marquee-container {
          overflow: hidden;
          white-space: nowrap;
          position: relative;
          width: 300px; /* Adjust as needed */
        }
        .audio-player-animate-marquee {
          display: inline-block;
          animation: audio-player-marquee 10s linear infinite; /* Changed animation name */
          min-width: 100%;
        }
        @keyframes audio-player-marquee { /* Changed keyframes name */
          0% { transform: translateX(100%); }
          100% { transform: translateX(-100%); }
        }
      `}</style>
    </div>
  );
};

export default AudioPlayerUI;
