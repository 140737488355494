import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  IconButton,
} from "@mui/material";
import { Link } from "react-router-dom";
import FuseSvgIcon from "@fuse/core/FuseSvgIcon";
import CloseIcon from "@mui/icons-material/Close";
import "./upload.css";
import BaseDialog from "app/shared-components/dialog/base-dialog";

export default function ScrapedInput({
  open,
  handleClose,
  handleSubmit,
  loading,
  successMessage,
  failureMessage,
  url,
  scrapeUrls,
}) {
  const columns = [
    {
      field: "url",
      headerName: "URL",
      width: 670,
      renderCell: (params) => {
        const truncatedUrl =
          params.value.length > 70
            ? params.value.slice(0, 70) + "..."
            : params.value;
        return (
          <div>
            {truncatedUrl}
            <IconButton
              component={Link}
              onClick={(event) => {
                window.open(
                  `${process.env.REACT_APP_TRACKER_PAGE}?url=${params.value}`,
                  "_blank"
                );
                event.stopPropagation();
              }}
              disableTouchRipple
            >
              <FuseSvgIcon size={20} color="action">
                material-outline:call_made
              </FuseSvgIcon>
            </IconButton>
          </div>
        );
      },
      disableClickEventBubbling: true,
    },
  ];

  useEffect(() => {
    const allRowIds = scrapeUrls.map((row) => row.id);
    setSelectedRows(allRowIds);
  }, [scrapeUrls]);

  const [selectedRows, setSelectedRows] = useState(
    scrapeUrls.map((row) => row.id)
  );

  const handleSelectionChange = (selectionModel) => {
    setSelectedRows(selectionModel);
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    const selectedUrls = scrapeUrls.filter((row) =>
      selectedRows.includes(row.id)
    );
    handleSubmit(selectedUrls);
  };

  return (
    <BaseDialog open={open} handleClose={handleClose} title="Add Urls(Scrape)">
      <div className="flex">
        <div className="bg-gray-100 p-8 flex rounded-md w-full ">
          <input
            type="text"
            value={url}
            className="mx-6 flex-grow bg-gray-100 text-gray"
            placeholder="Url"
            disabled
          />
        </div>
      </div>
      <div className="scraped-table">
        <DataGrid
          rows={scrapeUrls}
          columns={columns}
          pageSizeOptions={[10]}
          rowsPerPageOptions={[50]}
          selectionModel={selectedRows}
          onSelectionModelChange={handleSelectionChange}
          checkboxSelection
        />
      </div>
      {successMessage && (
        <div className="text-green-600 text-center mt-5">{successMessage}</div>
      )}
      {failureMessage && (
        <div className="text-red-600 text-center mt-5">{failureMessage}</div>
      )}
      <DialogActions className="justify-end px-0 mx-0 mt-20">
        {loading ? (
          <CircularProgress thickness={5} size={30} color="secondary" className="mr-24" />
        ) : (
          <Button
            disabled={selectedRows.length === 0}
            className="shine-buton press-button bg-black min-h-32 max-h-32 mr-24 hover:bg-base-purple mb-16"
            variant="outlined"
            onClick={handleFormSubmit}
          >
            Submit
          </Button>
        )}
      </DialogActions>
    </BaseDialog>
  );
}
