import clsx from 'clsx';
import { forwardRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/system';
import { ReactSVG } from "react-svg";

const Root = styled(Box)(({ theme, size, color }) => ({
  width: size,
  height: size,
  minWidth: size,
  minHeight: size,
  fontSize: size,
  lineHeight: size,
  color: {
    primary: theme.palette.primary.main,
    secondary: theme.palette.secondary.main,
    info: theme.palette.info.main,
    success: theme.palette.success.main,
    warning: theme.palette.warning.main,
    action: theme.palette.action.active,
    error: theme.palette.error.main,
    disabled: theme.palette.action.disabled,
    inherit: undefined,
  }[color],
}));

const SvgIcon = forwardRef((props, ref) => {
	const { children, size, sx, className, color } = props;
  const iconPath = props.children;

  return useMemo(
    () => (
		<ReactSVG className={clsx('shrink-0 fill-current text-white', className)} size={size} src={iconPath} />
    ),
    [ref, className, size, color, iconPath]
  );
});

SvgIcon.propTypes = {
	children: PropTypes.string,
	size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	sx: PropTypes.object,
	color: PropTypes.oneOf([
	  'inherit',
	  'disabled',
	  'primary',
	  'secondary',
	  'action',
	  'error',
	  'info',
	  'success',
	  'warning',
	]),
  };

SvgIcon.defaultProps = {
	children: '',
	size: 24,
	sx: {},
	color: 'inherit',
  };

export default SvgIcon;
