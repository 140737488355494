import EmailConfirmation from './EmailConfirmation';
import SignUpEmailConfirmation from './SignUpEmailConfirmation';

const EmailConfirmationConfig = {
  settings: {
    layout: {
      config: {
        navbar: {
          display: false,
        },
        toolbar: {
          display: false,
        },
        footer: {
          display: false,
        },
        leftSidePanel: {
          display: false,
        },
        rightSidePanel: {
          display: false,
        },
      },
    },
  },
  auth: null,
  routes: [
    {
      path: 'email-confirmation',
      element: <EmailConfirmation />,
    },
    {
      path: 'email-confirmation-signup',
      element: <SignUpEmailConfirmation />,
    },
  ],
};

export default EmailConfirmationConfig;
