import { AES, enc } from "crypto-js";
import * as amplitude from '@amplitude/analytics-browser';
import { Identify } from '@amplitude/analytics-browser';



export const addUserProperties = (planName) => {
  const identifyObj = new Identify();
  identifyObj.set('planName', planName);
  amplitude.identify(identifyObj);
}

export const logSignupSuccessEvent = (userId) => {

  try {
    amplitude.track("Sign Up");
   
  } catch (e) {
    console.log(e);
  }
  try {
    window.gtag("event", "Signup", {
      event_category: "User",
      event_action: "Success",
      event_label: "User has successfully signed up.",
      user_id: userId,
      timestamp: new Date().toString(),
    });
    window.gtag("event", "signup", {
      event_category: "User",
      event_label: "New User Signed Up",
    });
  } catch (e) {
    console.log(e);
  }
};
export const logSignupFailureEvent = (userId, errorMessage) => {
  try {
    amplitude.track("Sign Up Fail");
  } catch (e) {
    console.log(e);
  }
  window.gtag("event", "Signup", {
    event_category: "User",
    event_action: "Failure",
    event_label: "User signup attempt has failed.",
    user_id: userId,
    timestamp: new Date().toString(),
    error_message: errorMessage.toString(),
  });
};

export const logLoginSuccessEvent = (userId) => {
  try {
    amplitude.track("Log in");
  
  } catch (e) {
    console.log(e);
  }
  window.gtag("event", "Login", {
    event_category: "User",
    event_action: "Success",
    event_label: "User has successfully logged in.",
    user_id: userId,
    timestamp: new Date().toString(),
  });
};
export const logLoginFailureEvent = (userId, errorMessage) => {
  try {
    amplitude.track("Log in Failure");
  } catch (e) {
    console.log(e);
  }
  window.gtag("event", "Login", {
    event_category: "User",
    event_action: "Failure",
    event_label: "User login attempt has failed.",
    user_id: userId,
    timestamp: new Date().toString(),
    error_message: errorMessage.toString(),
  });
};

export const logLogoutSuccessEvent = (userId) => {
  try {
    amplitude.track("Logout");
  } catch (e) {
    console.log(e);
  }
  window.gtag("event", "Logout", {
    event_category: "User",
    event_action: "Success",
    event_label: "User has successfully logged out.",
    user_id: userId,
    timestamp: new Date().toString(),
  });
};

export const logNavigationClickEvent = (userId, menuItem) => {
  try {
    amplitude.track("Navigation Click", {
      menu_item: menuItem,
    });
  } catch (e) {
    console.log(e);
  }
  window.gtag("event", "Click", {
    event_category: "Navigation",
    event_action: "Menu Item",
    event_label: "User has clicked a menu item.",
    user_id: userId,
    timestamp: new Date().toString(),
    menu_item: menuItem,
  });
};
export const logPageViewLoadEvent = (userId, pageName) => {
  window.gtag("event", "Load", {
    event_category: "PageView",
    event_action: "Load",
    event_label: "User has loaded a page.",
    user_id: userId,
    timestamp: new Date().toString(),
    page_name: pageName,
  });
};

export const logChatbotCreateClickedEvent = () => {
  try {
    amplitude.track("Create Chatbot Click");
  } catch (e) {
    console.log(e);
  }
  window.gtag("event", "Click", {
    event_category: "Chatbot",
    event_action: "Create",
    event_label: "User has clicked create chatbot.",
    timestamp: new Date().toString(),
  });
};
export const logChatbotCreationEvent = (userId, formName, formData) => {
  try {
    amplitude.track("Create Chatbot", {
      form_name: formName,
      form_data: formData.toString(),
    });
  } catch (e) {
    console.log(e);
  }
  window.gtag("event", "ChatbotCreate", {
    event_category: "Form",
    event_action: "Chatbot Creation",
    event_label: "User has submitted a form.",
    user_id: userId,
    timestamp: new Date().toString(),
    form_name: formName,
    form_data: formData.toString(),
  });
};
export const logPopulateUrlEvent = (userId, formName, formData) => {
  try {
    amplitude.track("Populate Chatbot", {
      form_name: formName,
    });
    amplitude.track("Populate Url", {
      form_name: formName,
    });
  } catch (e) {
    console.log(e);
  }
  window.gtag("event", "Populate Url", {
    event_category: "Form",
    event_action: "Populate Url",
    event_label: "User has submitted a form.",
    user_id: userId,
    timestamp: new Date().toString(),
    form_name: formName,
    form_data: formData.toString(),
  });
};
export const logPopulateDocEvent = (userId, formName, formData) => {
  try {
    amplitude.track("Populate Chatbot", {
      form_name: formName,
    });
    amplitude.track("Populate Doc", {
      form_name: formName,
    });
  } catch (e) {
    console.log(e);
  }
  window.gtag("event", "Populate Doc", {
    event_category: "Form",
    event_action: "Populate Doc",
    event_label: "User has submitted a form.",
    user_id: userId,
    timestamp: new Date().toString(),
    form_name: formName,
    form_data: formData.toString(),
  });
};
export const logPopulateYoutubeEvent = (userId, formName, formData) => {
  try {
    amplitude.track("Populate Chatbot", {
      form_name: formName,
    });
    amplitude.track("Populate Youtube", {
      form_name: formName,
    });
  } catch (e) {
    console.log(e);
  }
  window.gtag("event", "Populate Youtube", {
    event_category: "Form",
    event_action: "Populate Youtube",
    event_label: "User has submitted a form.",
    user_id: userId,
    timestamp: new Date().toString(),
    form_name: formName,
    form_data: formData.toString(),
  });
};
export const logPopulateSourceEvent = (userId, formName, formData) => {
  window.gtag("event", "Populate Source", {
    event_category: "Form",
    event_action: "Populate Source",
    event_label: "User has submitted a form.",
    user_id: userId,
    timestamp: new Date().toString(),
    form_name: formName,
    form_data: formData.toString(),
  });
};
export const logErrorOccurrenceEvent = (userId, errorType, errorMessage) => {
  window.gtag("event", "Error Occur", {
    event_category: "Error",
    event_action: "Error Type",
    event_label: "An error has occurred.",
    user_id: userId,
    timestamp: new Date().toString(),
    error_type: errorType,
    error_message: errorMessage.toString(),
  });
};
export const logAskQuestionEvent = (userId, query) => {
  try {
    amplitude.track("Ask Question");
  } catch (e) {
    console.log(e);
  }
  window.gtag("event", "Execute", {
    event_category: "AskQuestion",
    event_action: "Query",
    event_label: "User has executed a search.",
    user_id: userId,
    timestamp: new Date().toString(),
    query: query,
  });
};
export const logCheckoutSuccessEvent = (userId) => {
  try {
    amplitude.track("Checkout Success");
  } catch (e) {
    console.log(e);
  }
  window.gtag("event", "Checkout", {
    event_category: "Transaction",
    event_action: "Success",
    event_label: "User has successfully paid.",
    user_id: userId,
    timestamp: new Date().toString(),
  });
};
export const logCheckoutFailedEvent = (userId) => {
  try {
    amplitude.track("Checkout Failure");
  } catch (e) {
    console.log(e);
  }
  window.gtag("event", "Checkout", {
    event_category: "Transaction",
    event_action: "Failed",
    event_label: "Payment has failed.",
    user_id: userId,
    timestamp: new Date().toString(),
  });
};
export const logCheckoutExpiredEvent = (userId) => {
  try {
    amplitude.track("Checkout Expired");
  } catch (e) {
    console.log(e);
  }
  window.gtag("event", "Checkout", {
    event_category: "Transaction",
    event_action: "Expired",
    event_label: "User has abandoned cart.",
    user_id: userId,
    timestamp: new Date().toString(),
  });
};
export const logSubscriptionDeletedEvent = (userId) => {
  try {
    amplitude.track("Subscription Deleted");
  } catch (e) {
    console.log(e);
  }
  window.gtag("event", "Deleted", {
    event_category: "Subscription",
    event_action: "Success",
    event_label: "User deleted subscription.",
    user_id: userId,
    timestamp: new Date().toString(),
  });
};
export const logSubscriptionUpdatedEvent = (userId) => {
  try {
    amplitude.track("Subscription Updated");
  } catch (e) {
    console.log(e);
  }
  window.gtag("event", "Updated", {
    event_category: "Subscription",
    event_action: "Success",
    event_label: "User updated subscription.",
    user_id: userId,
    timestamp: new Date().toString(),
  });
};
export const logPurchaseSuccessEvent = (
  userId,
  transactionId,
  value,
  tax,
  currency,
  skuId,
  term
) => {
  try {
    amplitude.track("Purchase", {
      value: value,
      skuId : skuId,
      term : term,
    });
  } catch (e) {
    console.log(e);
  }
  window.gtag("event", "purchase", {
    event_category: "GAEvent",
    event_action: "Success",
    event_label: "User made a purchase.",
    user_id: userId,
    timestamp: new Date().toString(),
    transaction_id: transactionId,
    value: value,
    tax: tax,
    currency: currency,
    sku_id: skuId,
    term: term,
  });
};
