import { Controller, useForm } from 'react-hook-form';
import { useEffect } from 'react';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import TextField from '@mui/material/TextField';
import _ from 'lodash';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';

function CardChecklistItem(props) {
  const { item, onListItemChange, index, onListItemRemove } = props;

  const { control, watch } = useForm({ mode: 'onChange', defaultValues: item });
  const form = watch();

  useEffect(() => {
    if (!_.isEqual(item, form)) {
      onListItemChange(form, index);
    }
  }, [form, index, onListItemChange, item]);

  return (
    <ListItem key={item.id} dense >
      <Controller
        name="checked"
        control={control}
        defaultValue={false}
        render={({ field: { onChange, value } }) => (
          <Checkbox
            tabIndex={-1}
            checked={value}
            onChange={(ev) => onChange(ev.target.checked)}
            disableRipple
            sx={{
              '& .MuiSvgIcon-root': {
                color: value ? 'primary.main' : 'grey', // Change 'grey' to your desired color
              },
            }}
          />
        )}
      />

      <Controller
      
        name="name"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            className="flex flex-1 mx-8"
            variant="outlined"
            style={{
              backgroundColor:'#FFFBFF',
              borderRadius:"8px",
              
            }}
                    inputProps={{
                      disableUnderline: true, // Disables the default underline to use modern border
                      style: {
              backgroundColor:'#FFFBFF',
              paddingRight:"4px",
              paddingBottom:"2px",
                        paddingTop:"2px", // Updated padding for a better look and feel
                        borderRadius: "8px", // Rounded corners for a modern look
                        fontWeight: 400,
                        fontSize: "1.4rem", // Base font size
                      },
                    }}
            InputProps={{
             
            }}
          />
        )}
      />
    
      <IconButton
        aria-label="Delete"
        onClick={onListItemRemove}
        size="large"
      >
        <FuseSvgIcon  size={20} color={'black'} fontWeight={200}>heroicons-outline:trash</FuseSvgIcon>
      </IconButton>
    </ListItem>
  );
}

export default CardChecklistItem;
