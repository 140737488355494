import FusePageSimple from "@fuse/core/FusePageSimple";
import { FeaturedAlbumCard } from "app/shared-components/cards/FeaturedAlbumCard";
import { PageHeader } from "app/shared-components/PageHeader";
import { getPlaylists, selectPlaylist } from "app/store/playlistsSlice";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

export function FeaturedPlaylistsPage() {
  const playlists = useSelector(selectPlaylist);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPlaylists());
  }, []);
  return (
    <FusePageSimple
      className="px-16"
      header={<PageHeader title={"Featured Playlists"} />}
      content={
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-24 xl:grid-cols-8">
          {playlists.data.map((playlist) => (
            <Link to={`/playlist/${playlist.id}`} key={playlist.id}>
              <FeaturedAlbumCard
                splitTitle
                title={playlist.attributes.name}
                description={playlist.attributes.description}
                poster={playlist.attributes.album_art}
              />
            </Link>
          ))}
        </div>
      }
    />
  );
}
