import { lazy } from 'react';

const HistoryQueriesDashboardApp = lazy(() => import('./HistoryQueriesDashboardApp'));

const HistoryQueriesAppConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'dashboards/historyQueries',
      element: <HistoryQueriesDashboardApp />,
    },
  ],
};

export default HistoryQueriesAppConfig;
