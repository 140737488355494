import React from 'react';

const CircleWithCheckmark = () => {
  return (
    <div style={{ display: 'flex', alignItems: 'center', marginRight: '8px' , padding:'2px'}}>
      <div
        style={{
          width: '16px',
          height: '16px',
          borderRadius: '50%',
          
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: '#278664',
        }}
      >
        <span style={{ color: '#ffffff', fontSize: '14px' }}>✓</span>
      </div>
    </div>
  );
};

export default CircleWithCheckmark;
