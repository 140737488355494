import React from 'react';
import classNames from 'classnames';

const StatusPill = ({ status }) => {
  const baseClasses = 'inline-block py-1 px-4 rounded-md text-sm font-medium text-start border';

  const statusClasses = classNames({
    'bg-green-100 text-green-700 border-green-500': status === 'succeeded',
    'bg-red-100 text-red-700 border-red-500': status === 'failed',
    'bg-yellow-100 text-yellow-700 border-yellow-500': status === 'processing',
  });

  return (
    <span className={`${baseClasses} ${statusClasses}`}>
      {status.charAt(0).toUpperCase() + status.slice(1)}
    </span>
  );
};

export default StatusPill;
