import { createSlice } from '@reduxjs/toolkit';
import createAppAsyncThunk from 'app/store/createAppAsyncThunk';
import axios from 'axios';
import { showMessage } from 'app/store/fuse/messageSlice';
import { BoardSliceType } from './boardSlice';
import { CardType } from '../types/CardType';
import { BoardType } from '../types/BoardType';

const initialState = {
  dialogOpen: false,
  data: null,
  isLoading: false
};


export const updateCard = createAppAsyncThunk('scrumboardApp/card/update', async (newData, { dispatch, getState }) => {
  dispatch(cardSlice.actions.setLoading(true));
  const AppState = getState();
  const board = AppState.scrumboardApp.board.data;
  const card = AppState.scrumboardApp.card.data;
  let data;
try{
  const response = await axios.put(`${process.env.REACT_APP_AUTH_BASE_URL}/api/scrumboard-cards/${card?.id}?populate[0]=members_ids,memberIds,activities,listId,labels,contact_booking`, {data:newData});
  data = response.data.data;
}catch(e){
  console.log(e);
}
finally {
  // Dispatch an action to set isLoading back to false after data is fetched or in case of an error
  dispatch(cardSlice.actions.setLoading(false));
}

  dispatch(
    showMessage({
      message: 'Card Saved',
      autoHideDuration: 2000,
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'right'
      }
    })
  );

  return data;
});

export const removeCard = createAppAsyncThunk('scrumboardApp/card/removeCard', async (_params, { dispatch, getState }) => {
  const AppState = getState();
  const board = AppState.scrumboardApp.board.data;
  const card = AppState.scrumboardApp.card.data;

  const response = await axios.delete(`${process.env.REACT_APP_AUTH_BASE_URL}/api/scrumboard-cards/${card?.id}?populate[0]=listId,contact_booking`);
  const data = response.data.data;

  dispatch(closeCardDialog());

  return data;
});

export const cardSlice = createSlice({
  name: 'scrumboardApp/card',
  initialState,
  reducers: {
    openCardDialog: (state, action) => {
      state.dialogOpen = true;
      state.data = action.payload;
    },
    closeCardDialog: (state) => {
      state.dialogOpen = false;
      state.data = null;
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateCard.fulfilled, (state, action) => {
      state.isLoading=false
      state.data = action.payload;
    });
  }
});

export const data = (state) => state.scrumboardApp.card.data;

export const { openCardDialog, closeCardDialog } = cardSlice.actions;

export const selectCardDialogOpen = (state) => state.scrumboardApp.card.dialogOpen;

export const selectCardData = (state) => state.scrumboardApp.card.data;
export const selectCardLoading = (state) => state.scrumboardApp.card.isLoading;

export default cardSlice.reducer;
