import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import ColorPickerField from "./colorPickerField";
import { selectUser } from "app/store/userSlice";
import { aesEncrypt } from "src/app/utils/encryptions";
import _ from "lodash";
import axios from "axios";
import { showMessage } from "app/store/fuse/messageSlice";
import { useDispatch } from "react-redux";
import LoadingSpinner from "../loading-spinner/loading-spinner";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { IconButton, Typography } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Button } from "@mui/material";
import { desaturateColor } from "src/app/utils/colors";

export const ThemeForm = () => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  let kbID = new URLSearchParams(location.search).get("kb");

  let orgID = aesEncrypt(
    user.data.organization.org_id,
    process.env.REACT_APP_ENCRYPTION_KEY
  );
  const [kb, setKb] = useState();
  const [isLoaded, setIsLoaded] = useState(false);
  const [styleID, setStyleID] = useState();
  const [theme, setTheme] = useState({
    fabColor: {
      title: "Chatbot Bubble Color",
      description:
        "The color of the chatbot floating action button that appears on your webstie or app.",
      type: "colorPicker",
      value: "#0d0d0d",
    }, // Example hex color
    bgColor: {
      title: "Main Background Color",
      description:
        "The main background color of the chatbot for the text area.",
      type: "colorPicker",
      value: "#F0F0F0",
    },
    headerColor: {
      title: "Header Color",
      description: "The color of the top header of the chatbot.",
      type: "colorPicker",
      value: "#f0f0f0",
    },
    textColor: {
      title: "Header Text Color",
      description: "The text color of the header text of the chatbot.",
      type: "colorPicker",
      value: "#0d0d0d",
    },
    secondaryTextColor: {
      title: "Secondary Text Color",
      description:
        "The color of the secondary text in the chatbot. Used for text content like Powered by and wherever text color is not explicitly set.",
      type: "colorPicker",
      value: "#6C757D",
    },
    botBubbleColor: {
      title: "Bot Message Bubble Color",
      description: "The color of the chatbot bot message bubble.",
      type: "colorPicker",
      value: "#EEEEEE",
    },
    userBubbleColor: {
      title: "User Message Bubble Color",
      description: "The color of the chatbot user message bubble.",
      type: "colorPicker",
      value: "#fefefe",
    },
    botBubbleTextColor: {
      title: "Chatbot Bot Message Bubble Text Color",
      description: "The color of the text in the chatbot bot message bubble.",
      type: "colorPicker",
      value: "#212529",
    },
    userBubbleTextColor: {
      title: "User Message Bubble Text Color",
      description: "The color of the text in the chatbot user message bubble.",
      type: "colorPicker",
      value: "#212529",
    },
    inputBackgroundColor: {
      title: "Text Input Background Color",
      description: "The color of the text input background in the chatbot.",
      type: "colorPicker",
      value: "#f0f0f0",
    },
    inputTextColor: {
      title: "Text Input Text Color",
      description: "The color of the text input text in the chatbot.",
      type: "colorPicker",
      value: "#212529",
    },
    linkColor: {
      title: "Link Color",
      description: "The color of the links in the chatbot.",
      type: "colorPicker",
      value: "#007BFF",
    },

    initial_message: {
      title: "Welcome Message",
      description:
        "The first message that the chatbot will display when it first loads.",
      type: "smallText",
      value: "Welcome! Im an AI Agent, how can I help you today?",
    },
    suggested_question_1: {
      title: "Suggested Question 1",
      description:
        "The first suggested questions that the chatbot will display to the user below the initial message.",
      type: "smallText",
      value: "",
    },
    suggested_question_2: {
      title: "Suggested Question 2",
      description:
        "The second suggested questions that the chatbot will display to the user below the initial message.",
      type: "smallText",
      value: "",
    },
    suggested_question_3: {
      title: "Suggested Question 3",
      description:
        "The third suggested questions that the chatbot will display to the user below the initial message.",
      type: "smallText",
      value: "",
    },
    sendButtonColor: {
      title: "Send Button Background Color",
      description: "The color of the send button in the chatbot.",
      type: "colorPicker",
      value: "#007BFF",
    },
    sendButtonTextColor: {
      title: "Send Button Text/Icon Color",
      description:
        "The color of the text and icon in the send button in the chatbot.",
      type: "colorPicker",
      value: "#9522d4",
    },
    border_color: {
      title: "Border Color",
      description: "The color of the border around the chatbot.",
      type: "colorPicker",
      value: "#DDDDDD",
    },
    tooltip_message: {
      title: "First Tooltip Message",
      description:
        "The first message that will appear in the tooltip when the chatbot is minimized.",
      type: "smallText",
      value: "Click here to start chatting!",
    },
    second_tooltip_message: {
      title: "Second Tooltip Message",
      description:
        "The second message that will appear in the tooltip when the chatbot is minimized.",
      type: "smallText",
      value: "Need help? Ask us anything!",
    },
    chat_bot_icon: {
      title: "Chatbot Icon",
      description:
        "The icon that will appear in the chatbot bubble. Enter the URL of the image you would like to use.",
      type: "smallText",
      value:
        "https://imversion.s3.us-west-2.amazonaws.com/chat-bot-images/chat-bot-logo.png",
    }, // Path to the icon image
    profile_picture: {
      title: "Profile Picture",
      description:
        "The profile picture that will appear in the chatbot header. Enter the URL of the image you would like to use.",
      type: "smallText",
      value:
        "https://imversion.s3.us-west-2.amazonaws.com/chat-bot-images/chat-bot-logo.png",
    }, //
    tooltipBackgroundColor: {
      title: "Tooltip Background Color",
      description:
        "The background color of the tooltip when the chatbot is minimized.",
      type: "colorPicker",
      value: "#000000",
    },
    tooltipTextColor: {
      title: "Tooltip Text Color",
      description:
        "The text color of the tooltip when the chatbot is minimized.",
      type: "colorPicker",
      value: "#FFFFFF",
    },
  });

  const getKb = async () => {
    let kb_ID = new URLSearchParams(location.search).get("kb");
    const res = await axios
      .get(
        `${process.env.REACT_APP_AUTH_BASE_URL}/api/knowledgebases/${kb_ID}?populate[0]=chatbot_style`
      )
      .then((result) => {
        if (result.data.data.attributes.chatbot_style.data == null) {
          result.data.data.attributes.chatbot_style.data = {};
          result.data.data.attributes.chatbot_style.data.attributes = {};
          result.data.data.attributes.chatbot_style.data.attributes["suggested_questions"] = [];
          for (let key in theme) {
            result.data.data.attributes.chatbot_style.data.attributes[key] =
              theme[key].value;
          }
        }
        var chatbot_style =
          result.data.data.attributes.chatbot_style.data.attributes;
        if (
          result.data.data.attributes.chatbot_style.data.attributes[
            "suggested_questions"
          ]
        ) {
          const suggestedQuestions =
            result.data.data.attributes.chatbot_style.data.attributes[
              "suggested_questions"
            ];
          chatbot_style["suggested_question_1"] = suggestedQuestions[0] || "";
          chatbot_style["suggested_question_2"] = suggestedQuestions[1] || "";
          chatbot_style["suggested_question_3"] = suggestedQuestions[2] || "";
        } else {
          chatbot_style["suggested_question_1"] = "";
          chatbot_style["suggested_question_2"] = "";
          chatbot_style["suggested_question_3"] = "";
        }
        result.data.data.attributes.chatbot_style.data.attributes =
          chatbot_style;

        setKb(result.data.data.attributes);
        setStyleID(
          result.data.data.attributes.chatbot_style.data != null
            ? result.data.data.attributes.chatbot_style.data.id
            : ""
        );
        setIsLoaded(true);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  useEffect(() => {
    if (!kb) {
      getKb();
    }
  }, []);

  const arrayToFields = (array) => {};

  const handleColorChange = (name, color) => {
    setKb((prevState) => {
      return {
        ...prevState,
        chatbot_style: {
          data: {
            attributes: {
              ...prevState.chatbot_style.data.attributes,
              [name]: color,
            },
          },
        },
      };
    });
  };

  const handleCheckboxChange = (e) => {
    setTheme((prevState) => {
      return {
        ...prevState,
        [e.target.name]: {
          ...prevState[e.target.name],
          value: e.target.checked,
        },
      };
    });
  };

  const handleChange = (e) => {
    const title = e.target.name;
    if (kb?.chatbot_style.data) {
      setKb((prevState) => {
        return {
          ...prevState,
          chatbot_style: {
            data: {
              attributes: {
                ...prevState.chatbot_style.data.attributes,
                [title]: e.target.value,
              },
            },
          },
        };
      });
    } else {
      setKb((prevState) => {
        return {
          ...prevState,
          chatbot_style: {
            data: {
              attributes: {
                [title]: e.target.value,
              },
            },
          },
        };
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      kb.chatbot_style.data.attributes["suggested_question_1"] &&
      kb.chatbot_style.data.attributes["suggested_question_1"] !== ""
    ) {
      kb.chatbot_style.data.attributes["suggested_questions"].unshift(
        kb.chatbot_style.data.attributes["suggested_question_1"]
      );
    }
    if (
      kb.chatbot_style.data.attributes["suggested_question_2"] &&
      kb.chatbot_style.data.attributes["suggested_question_2"] !== ""
    ) {
      kb.chatbot_style.data.attributes["suggested_questions"].unshift(
        kb.chatbot_style.data.attributes["suggested_question_2"]
      );
    }
    if (
      kb.chatbot_style.data.attributes["suggested_question_3"] &&
      kb.chatbot_style.data.attributes["suggested_question_3"] !== ""
    ) {
      kb.chatbot_style.data.attributes["suggested_questions"].unshift(
        kb.chatbot_style.data.attributes["suggested_question_3"]
      );
    }
    if (kb.chatbot_style.data.attributes["suggested_questions"].length > 3) {
      kb.chatbot_style.data.attributes["suggested_questions"].splice(3);
    }
    kb.chatbot_style.data.attributes["suggested_questions"].reverse();

    if (!styleID) {
      let data = kb.chatbot_style.data.attributes;
      data["knowledgebase"] = kbID;
      axios
        .post(`${process.env.REACT_APP_AUTH_BASE_URL}/api/chatbot-styles`, {
          data: kb.chatbot_style.data.attributes,
        })
        .then((r) => {
          dispatch(showMessage({ message: "Updated new Style" }));
        })
        .catch((e) => {
          console.log(e);
          dispatch(showMessage({ message: "Failed to create new style" }));
        });
    } else {
      axios
        .put(
          `${process.env.REACT_APP_AUTH_BASE_URL}/api/chatbot-styles/${styleID}`,
          { data: kb.chatbot_style.data.attributes }
        )
        .then((r) => {
          dispatch(showMessage({ message: "Updated new Style" }));
        })
        .catch((e) => {
          console.log(e);
          dispatch(showMessage({ message: "Failed to update new style" }));
        });
    }
  };

  return isLoaded ? (
    <div className="flex flex-row">
      <div className="container mx-auto p-4 w-1/2">
        {isLoaded === false ? (
          <div className="mt-4 flex align-center justify-center items-center">
            <LoadingSpinner size={24} />
          </div>
        ) : (
          <form onSubmit={handleSubmit} className="p-8 rounded-lg">
            <div className="flex justify-end">
              <Button
                type="submit"
                variant="outlined"
                className="purple-styled-button mt-8 w-[100px]"
                startIcon={<SaveIcon />}
              >
                Save
              </Button>
              <Button
                className="purple-styled-button mt-8 w-[100px] ml-16"
                startIcon={<VisibilityIcon />}
                onClick={() => {
                  const link = `${
                    process.env.REACT_APP_PREVIEW_BASE_URL
                  }?orgId=${aesEncrypt(
                    user.data.organization.org_id,
                    process.env.REACT_APP_ENCRYPTION_KEY
                  )}&chatbotId=${aesEncrypt(
                    String(kb.kb_id),
                    process.env.REACT_APP_ENCRYPTION_KEY
                  )}`;
                  window.open(link, "_blank");
                }}
              >
                Preview
              </Button>
            </div>
            <div className="flex">
              <IconButton
                className="w-40 h-40"
                onClick={(e) => {
                  history.back();
                }}
              >
                <ArrowBackIcon style={{ color: "black" }} />
              </IconButton>
              <Typography className="text-3xl mt-2 font-semibold tracking-tight leading-8 mb-10 sm:mb-0">
                <span className="ml-8 flex align-center">Chatbot Style</span>
              </Typography>
            </div>

            {/* Text Fields */}
            {Object.keys(theme)
              .filter(
                (key) =>
                  theme[key].type !== "checkbox" &&
                  theme[key].type !== "colorPicker"
              )
              .map((key) => (
                <div key={key} className="mb-16 mr-16">
                  <label
                    htmlFor={key}
                    className="ml-8 mt-24 block text-lg font-medium"
                  >
                    {theme[key].title}
                  </label>
                  <label
                    htmlFor={key}
                    className="ml-8 mt-8 block text-sm font-normal mb-2 text-gray-800"
                  >
                    {theme[key].description}
                  </label>
                  <input
                    type="text"
                    id={key}
                    name={key}
                    value={
                      kb?.chatbot_style.data
                        ? kb?.chatbot_style.data.attributes[key]
                        : ""
                    }
                    onChange={handleChange}
                    className="ml-8 mt-8 w-full py-16 px-8  block rounded-md border-gray-800 border-1 shadow-sm focus:border-base-purple focus:ring-indigo-500 text-base"
                  />
                </div>
              ))}

            {/* Checkboxes */}
            {Object.keys(theme)
              .filter((key) => theme[key].type === "checkbox")
              .map((key) => (
                <div key={key} className="flex items-center mb-4">
                  <input
                    type="checkbox"
                    id={key}
                    name={key}
                    checked={
                      kb?.chatbot_style.data
                        ? kb?.chatbot_style.data.attributes[key]
                        : false
                    }
                    onChange={handleCheckboxChange}
                    className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-800 rounded"
                  />
                  <label
                    htmlFor={key}
                    className="text-sm font-medium text-gray-700"
                  >
                    {theme[key].title}
                  </label>
                </div>
              ))}

            {/* Color Pickers */}
            <div className="grid grid-cols-1 ml-16 sm:grid-cols-2 md:grid-cols-3 gap-4 ">
              {Object.keys(theme)
                .filter((key) => theme[key].type === "colorPicker")
                .map((key) => {
                  return (
                    <div key={key} className="mb-4">
                      <label className="mt-16  block text-lg font-medium mb-2">
                        {theme[key].title}
                      </label>
                      <label
                        htmlFor={key}
                        className="mt-8 mr-32 block text-sm font-normal mb-2 text-gray-800"
                      >
                        {theme[key].description}
                      </label>
                      <ColorPickerField
                        col={
                          kb?.chatbot_style.data
                            ? kb?.chatbot_style.data.attributes[key]
                            : "#000000"
                        }
                        onChange={(color) => {
                          handleColorChange(key, color);
                        }}
                      />
                    </div>
                  );
                })}
            </div>
          </form>
        )}
      </div>
    </div>
  ) : (
    <LoadingSpinner className={"w-56 m-auto my-256"} />
  );
};
