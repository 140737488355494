import React from "react";

const DividerWithText = ({ children }) => {
  return (
    <div className="flex items-center mx-48 mt-8">
      <div className="border-b-1 !border-gray-400 w-full" />
      <span className="flex w-full text-center justify-center py-1 px-2 text-lg font-sm text-gray-900">{children}</span>
      <div className="border-b-1 !border-gray-400 w-full" />
    </div>
  );
};

export default DividerWithText;
