import React from 'react';
import PropTypes from 'prop-types';
import BaseDialog from './base-dialog';
import { Button, DialogActions } from '@mui/material';
export default function ConfirmDialog(props){
	return (
		<BaseDialog open={props.open} title={props.title} handleClose={props.onCancel}>
			<div>
				{props.message}
			</div>
			<DialogActions>
          <Button className="text-center bg-base-purple text-l rounded text-white px-10 min-h-32 max-w-48 max-h-32" onClick={props.onCancel}>Cancel</Button>
          <Button className='min-h-32 max-w-48 max-h-32 rounded bg-red text-white' onClick={props.onConfirm} autoFocus>
            Confirm
          </Button>
        </DialogActions>
		</BaseDialog>
	);
}
ConfirmDialog.propTypes = {
	title: PropTypes.string,
	message: PropTypes.string,
	onCancel: PropTypes.func,
    onConfirm: PropTypes.func,
  };

  ConfirmDialog.defaultProps = {
    title: "Alert",
	message: "Are you sure..."
};
