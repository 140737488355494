import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

export const getAiTasks = createAsyncThunk('aiTasks/getAiTasks', async () => {
  const response = await axios.get(`${process.env.REACT_APP_AUTH_BASE_URL}/api/ai-tasks`).catch((error) => { console.log(error);});
  const data = await response.data.data;
  return data;
});

const initialState = [];

const aiTasksSlice = createSlice({
  name: 'aiTasks',
  initialState ,
  reducers: {},
  extraReducers: {
    [getAiTasks.fulfilled]: (state, action) => action.payload,
  },
});

export const selectAiTasks = ({ aiTasks }) => {
  return aiTasks}
  ;

export default aiTasksSlice.reducer;
