import { useEffect, useState } from "react";

import _ from "@lodash";
import FusePageSimple from "@fuse/core/FusePageSimple";

import { Button } from "@mui/material";
import AlbumCard from "app/shared-components/cards/albumCard";

import { useDispatch } from "react-redux";

import { toggleLoginDialog } from "app/store/authSlice";
import { motion } from "framer-motion";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";

export function HomePage() {
  const dispatch = useDispatch();
  const [previewPodcasts, setPreviewPodcasts] = useState([]);

  useEffect(() => {
    getPreviewPodcasts();
  }, []);

  const getPreviewPodcasts = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_AUTH_BASE_URL}/api/random-public-tracks-preview`
      );
      const results = response?.data?.data?.map((d) => ({
        id: uuidv4().toString(),
        attributes: { ...d, status: 'completed' },
      }));

      setPreviewPodcasts(results);

    } catch (error) {
      console.error("Error fetching preview podcasts:", error);
      return [];
    }
  };

  return (
    <FusePageSimple
      className="bg-black overflow-hidden"
      classes={{
        root: "overflow-hidden",
        wrapper: "overflow-hidden p-0 !important",
        content: "bg-black overflow-hidden p-0 !important",
        leftSidebar: "overflow-hidden",
        rightSidebar: "overflow-hidden",
        sidebarWrapper: "overflow-hidden",
      }}
      content={
        <div
          className="flex flex-col gap-36 container bg-main-bg overflow-y-auto overflow-x-hidden [&::-webkit-scrollbar]:hidden [-ms-overflow-style:none] [scrollbar-width:none]"
          style={{
            msOverflowStyle: "none",
            scrollbarWidth: "none",
          }}
        >
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            style={{
              msOverflowStyle: "none",
              scrollbarWidth: "none !important",
            }}
            className="relative min-h-[500px] flex items-center justify-center mx-16 mt-32 overflow-visible"
          >
            <div
              className="relative z-10 flex flex-col md:flex-row items-center justify-between w-full px-24 py-32 bg-black rounded-3xl"
              style={{
                boxShadow: "0 0 20px 0 #8d5eb7",
              }}
            >
              <div className="flex flex-col items-center md:items-start text-center md:text-left max-w-2xl ">
                <div className="relative inline-block bg-primary rounded-full px-8 py-4 overflow-hidden">
                  <span className="relative z-10 text-black font-regular text-sm">On Demand Podcast Platform</span>
                  <div className="absolute inset-0 shine-effect"></div>
                </div>
                <h1 className="text-4xl md:text-6xl font-bold text-white my-8">
                  <span className="text-primary">Learn</span> on the go.
                  <span className="text-main-text-color">
                    {" "}
                    Your audio AI Life coach.
                  </span>
                </h1>
                <p className="text-lg text-slightly-light-text mb-12">
                Your Prompt, Your Podcast – Instant Audio on Any Topic.
                </p>
                <div className="flex flex-col sm:flex-row gap-8">
                  <Button
                    variant="contained"
                    color="secondary"
                    size="large"
                    onClick={() => dispatch(toggleLoginDialog(true))}
                    className="min-w-192 bg-primary text-main-bg rounded-md shadow-primary-900/20 shadow-md hover:bg-primary/90 hover:scale-105 transition-all duration-300"
                  >
                    Get Started
                  </Button>
                  {/* <Button
                    variant="outlined"
                    color="inherit"
                    size="large"
                    component={Link}
                    to="/featured-podcasts"
                    className="min-w-192 text-white border-white hover:bg-white/10"
                  >
                    Browse Podcasts
                  </Button> */}
                </div>
              </div>

              <motion.div
                initial={{ scale: 0.8, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                transition={{ delay: 0.2, duration: 0.8 }}
                className="mt-32 md:mt-0 relative h-full"
              >
                <div className="absolute inset-0 pointer-events-none">
                  <div className="absolute top-0 left-0 right-0 h-48 bg-gradient-to-b from-black via-black/80 to-transparent z-10 backdrop-blur-lg" />
                  <div className="absolute bottom-0 left-0 right-0 h-48 bg-gradient-to-t from-black via-black/80 to-transparent z-10 backdrop-blur-lg" />
                  <div className="absolute top-0 left-0 bottom-0 w-48 bg-gradient-to-r from-black via-black/80 to-transparent z-10 backdrop-blur-lg" />
                  <div className="absolute top-0 right-0 bottom-0 w-48 bg-gradient-to-l from-black via-black/80 to-transparent z-10 backdrop-blur-lg" />
                </div>
                <img
                  src="assets/images/hero_2.png"
                  alt="Podcast Hero"
                  className="h-[500px] object-contain relative z-0"
                />
              </motion.div>
            </div>
          </motion.div>

          <div>
            <div className="flex flex-col w-full py-16">
              <div className="px-16">
                <h2 className="text-2xl font-bold text-white mb-8">
                  Preview Podcasts
                </h2>
              </div>
              <h3 className="text-lg text-slightly-light-text mb-8 px-16">
                Explore our curated selection of podcasts created using our AI.
              </h3>

              <div className="grid lg:grid-cols-6 gap-0 grid-cols-1 sm:grid-cols-3 md:grid-cols-4">
                {previewPodcasts.slice(0, 6).map((album) => (
                  <AlbumCard isPreview album={album} />
                ))}
              </div>
            </div>
          </div>
          <div>
            {/* <div className="flex flex-col space-y-16  w-full py-16 px-16">
              <PageHeader
                title={"  Featured Playlists"}
                onLinkClick={() => {
                  dispatch(toggleLoginDialog(true));
                }}
              /> */}

            {/* <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-24 xl:grid-cols-8">
                {playlists.data.map((playlist) => (
                  <button
                    key={playlist.id}
                    onClick={() => dispatch(toggleLoginDialog(true))}
                  >
                    <FeaturedAlbumCard
                      splitTitle
                      title={playlist.attributes.name}
                      description={playlist.attributes.description}
                      poster={playlist.attributes.album_art}
                    />
                  </button>
                ))}
              </div> */}
          </div>
        </div>
      }
    />
  );
}
