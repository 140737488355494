import { useSelector } from 'react-redux';
import * as PropTypes from 'prop-types';
import { selectAiTasks } from 'app/store/aiTaskSlice';
import AiAgentCardLong from './ai-agent-card-long';

export default function AiAgentCardsList(props) {
	const aiTaskData = useSelector(selectAiTasks);
	return (
		<div className="flex flex-col gap-20 mt-20">
			{aiTaskData.map((ai) => (
				<AiAgentCardLong
					key={ai.id}
					id={ai.id}
					title={ai.attributes.name}
					description={ai.attributes.description}
					details={ai.attributes.detailsFactor}
					speed={ai.attributes.speedFactor}
					isActive={ai.attributes.task_id == 2 ? true : false}
					cost={ai.attributes.tokensUsageFactor}
					onSelect={() => {props.onAgentSelect(ai.id)}}
				/>
			))}
		</div>
	);
}
AiAgentCardsList.propTypes = {
	activeAgentId: PropTypes.string,
	onAgentSelect: PropTypes.func,
};
