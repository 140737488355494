import validator from 'validator' 

function validateUrl(url) {
    // Regular expression pattern to match a URL
    var urlPattern = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/i;
  
    // Test if the URL matches the pattern
    return urlPattern.test(url);
  }

function validatePhoneNumber (number)  {
    const isValidPhoneNumber = validator.isMobilePhone(number)
    return (isValidPhoneNumber)
  }

function validateName (name) {
    const isValidName = validator.isAlpha(name) && validator.isLength(name, {min: 2, max: 10})
    return (isValidName)
}

export { validateUrl, validatePhoneNumber};
