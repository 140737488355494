import React, { useState } from 'react';
import AddCircleIcon from '@mui/icons-material/AddCircle';// Ensure you have Heroicons installed for the plus icon
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

const DynamicTextFields = ({onChange}) => {
  const [fields, setFields] = useState(['']);

  const addField = () => {
    setFields([...fields, '']);
  };

  const handleChange = (index, event) => {
    const values = [...fields];
    values[index] = event.target.value;
    setFields(values);
    onChange(values);
  };

  const removeField = (index) => {
    const values = [...fields];
    values.splice(index, 1);
    setFields(values);
    onChange(values);
  };

  return (
    <div className="flex flex-col items-center justify-center p-4">
      <div className="w-full max-w-md">
      <label htmlFor="text-input" className="block text-lg font-medium text-black mb-2">
            Guidelines
        </label>
        <label htmlFor="text-input" className="block text-md font-regular text-black mb-2">
           Provide some guidelines for the agent to follow, like the format, how it shhould conduct research etc.
        </label>
        {fields.map((field, index) => (
          <div key={index} className="flex items-center mb-4">
            <input
              type="text"
              value={field}
              onChange={(e) => handleChange(index, e)}
              className="w-full my-4 px-4 py-4 min-h-32 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
              placeholder={`Field ${index + 1}`}
            />
            <button
              onClick={() => removeField(index)}
              className="ml-2 text-red-500 hover:text-red-700 focus:outline-none"
            >
              <RemoveCircleIcon className="ml-4 w-24 h-24 text-base-purple" />
            </button>
          </div>
        ))}
        <button
          onClick={addField}
          className="flex items-center justify-center w-max-48 px-8 py-8 mt-2 text-base-purple rounded-md  hover:bg-black focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        >
          <AddCircleIcon className="w-24 h-24 mr-2" />
          Add Guidelines
        </button>
      </div>
    </div>
  );
};

export default DynamicTextFields;
