import React, { useState } from 'react';
import {
	Button,
	DialogActions,
	CircularProgress,
} from '@mui/material';
import LoadingText from '../LoadingText';
import FileViewerList from '../FileViewerList';
import BaseDialog from 'app/shared-components/dialog/base-dialog';

function SourceCodeTrainModal({
	open,
	handleClose,
	handleSubmit,
	uploading,
	successMessage,
	failureMessage,
	onBackClick
}) {
	const [loading, setLoading] = useState(false);
	const [dragOver, setDragOver] = useState(false);
	const [error, setError] = useState('');
	const [files, setUploadedFiles] = useState([]);

	const handleFormSubmit = (event) => {
		event.preventDefault();
		handleSubmit(files);
		setUploadedFiles([]);
	};

	const handleFileChange = async (droppedFiles) => {
		setLoading(true);
		setError('');

		try {
			if (droppedFiles.length > 1) {
				setError('Please select only one file to upload.');
			}

			const newFiles = Array.from(droppedFiles);
			setUploadedFiles(newFiles);
		} catch (err) {
			setError(err.message);
		}

		setLoading(false);
	};

	const handleRemoveFile = (index) => {
		const updatedFiles = [...files];
		updatedFiles.splice(index, 1);
		setUploadedFiles(updatedFiles);
	};
	const handleDragEnter = (event) => {
		event.preventDefault();
		setDragOver(true);
	};

	const handleDragOver = (event) => {
		event.preventDefault();
	};

	const handleDragLeave = (event) => {
		event.preventDefault();
		setDragOver(false);
	};

	const handleDrop = (event) => {
		event.preventDefault();
		setDragOver(false);
		const droppedFiles = event.dataTransfer.files;
		handleFileChange(droppedFiles);
	};

	return (
		<BaseDialog
			open={open}
			title="Add Source code"
			handleClose={handleClose}
			showBackButton={true}
			handleBack={onBackClick}
		>
			<div className="flex items-center justify-center w-full flex-col">
				{(files.length === 0 || uploading) && (
					<div>
						<label
							htmlFor="dropzone-file"
							className={`flex p-8 flex-col items-center justify-center w-full border rounded-lg cursor-pointer relative border-[#E4E7EC]`}
							onDragEnter={handleDragEnter}
							onDragOver={handleDragOver}
							onDragLeave={handleDragLeave}
							onDrop={handleDrop}
						>
							<div className="flex flex-col self-start justify-center p-10">
								{loading ? (
									<LoadingText />
								) : (
									<div className="flex flex-row items-center text-center">
										<img
											height={50}
											width={50}
											src="assets/images/icons/upload.png"
											alt="upload"
										/>
										<div className="flex flex-col items-start ml-10">
											<p className="mb-2 text-sm">
												<span className="font-semibold text-base-purple">
													Click to upload
												</span>{' '}
												or drag and drop
											</p>
											<p className="text-sm">
												Only PDF(max 5MB per file)
											</p>
										</div>
										{/* <Button className="styled-button" variant="outlined" onClick={() => document.getElementById('dropzone-file').click()}>Upload</Button> */}
										<input
											id="dropzone-file"
											key={files.length}
											type="file"
											className="hidden"
											accept=".js, .dart"
											onChange={(event) =>
												handleFileChange(
													event.target.files
												)
											}
										/>
									</div>
								)}
							</div>
						</label>
						<div className="flex items-center justify-center w-full mt-16 mb-16 text-[#667085] text-sm">
						Add a source code file. Currently we support only dart and javascript files to be uploaded & trained.
						</div>
					</div>
				)}
				{error && (
					<div className="flex items-center justify-center w-full mt-4">
						<p className="text-sm text-red-500">{error}</p>
					</div>
				)}
				{files.length > 0 && (
					<FileViewerList
						files={files}
						onRemove={handleRemoveFile}
					/>
				)}
				{successMessage && (
				<div className="mt-16 text-green-600">
					Check back in a bit. Training new data can take upto 10 minutes depending on the size of the data source
				</div>
			)}
				{successMessage && (
					<div
						style={{ marginTop: '20px' }}
						className="text-green-600 text-center mt-4"
					>
						{successMessage}
					</div>
				)}
				{failureMessage && (
					<div
						style={{ marginTop: '20px' }}
						className="text-red-600 text-center mt-4"
					>
						{failureMessage}
					</div>
				)}
			</div>
			<DialogActions className="justify-start px-0 mx-0 mt-20">
				{uploading ? (
					<CircularProgress
						thickness={5}
						size={30}
						color="secondary"
					/>
				) : (
					files.length > 0 && (
						<Button
							className="purple-styled-button min-h-32 max-h-32"
							variant="outlined"
							onClick={handleFormSubmit}
						>
							Submit
						</Button>
					)
				)}
			</DialogActions>
		</BaseDialog>
	);
}

export default SourceCodeTrainModal;
