import './plan-card.css';
import { Card } from '@mui/material';
import { Divider } from '@mui/material';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon/FuseSvgIcon';
import CircleWithCheckmark from '../circle-with-checkmark/circle-with-checkmark';
const PlanCard = ({
	plan,
	color = '#E5E1F8',
	textColor = '#535353',
	isPopular = false,
	disableButton = false,
	buttonColor = '#21224e',
	buttonTextColor = 'white',
	buttonText = 'Subscribe',
	isCurrentPlan = false,
	onClick,
}) => {
	return (
		<Card
			key={plan.id}
			className="plan-card flex flex-col gap-8 text-start relative items-start"
			style={{
				backgroundColor: color,
				color: textColor ,
				borderColor:isPopular?'#6530c1':'#d4d4d4',
				borderWidth:'1px',
			}}
		>
			{/* {isCurrentPlan?<div className='bg-base-purple rounded text-white w-fit p-6 absolute top-0 right-0'>
			Active
		</div>:<></>} */}
		<div className='flex w-full justify-between'>
		<div className="text-start font-bold text-2xl text-main-text-color">{plan.attributes.name}</div>
		{isPopular?
		<div className='rounded-full self-center bg-base-purple px-12 py-4'>
			Popular
		</div>:<></>
		 }

		</div>

			<div className='flex flex-row'>
				<div className="flex justify-center items-center">
					<span className="plan-currency-label">$</span>
					<span className="plan-price-label ml-2">
						{plan.attributes.paid}
					</span>
				</div>

			</div>
			<div
				className="plan-description"
				style={{ color: textColor }}
			>
				{plan.attributes.description}
			</div>
			{plan.attributes?.name === 'Trial' ? (
						<div></div>
					) : (
						<button
							className="shine-button press-button plan-button text-center bg-base-purple text-l rounded text-white p-10 w-full mt-16"
							size="sm"
							variant="contained"
							onClick={(e) => onClick(plan)}
							style={{
								backgroundColor: buttonColor,
								color: buttonTextColor,
							}}
						>
							{buttonText}
						</button>
					)}

			<Divider
				className="mt-10 mb-6"
				style={{
					width: '100%',
					borderColor: '#d4d4d4',
					borderWidth: '0.1px',
				}}
			/>
			<div>
				<div className='font-bold'>FEATURES</div>
			</div>

			{plan.attributes.key_features?.map((value) => (
				<div key={value} className='flex flex-row items-center'>
					<CircleWithCheckmark/>
				<div
					key={value}
					className="plan-text grow flex-col items-start gap-x-8 w-full"
				>
					{value}
					{/* {value !=
					plan.attributes.key_features[
						plan.attributes.key_features.length - 1
					] ? (
						<Divider
							className="mt-10 mb-6"
							style={{ width: '100%', borderColor: '#d4d4d4' }}
						/>
					) : (
						<></>
					)} */}
				</div>
				</div>
			))}

			{/* {disableButton ? (
				<></>
			) : (
				<div className="flex-row justify-center items-center w-full">
					<Divider
						className="mt-10 mb-6"
						style={{ width: '100%', borderColor: '#d4d4d4' }}
					/>
					<></>

				</div>
			)} */}
		</Card>
	);
};
export default PlanCard;
