import { lazy } from 'react';

const HomeDashboardApp = lazy(() => import('./HomeDashboardApp'));

const HomeDashboardAppConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'dashboards/home',
      element: <HomeDashboardApp />,
    },
  ],
};

export default HomeDashboardAppConfig;
