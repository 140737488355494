import { Button, CircularProgress } from '@mui/material';
import { showMessage } from 'app/store/fuse/messageSlice';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useSelector } from 'react-redux';
import { selectAiTasks, getAiTasks } from 'app/store/aiTaskSlice';

const LeadForm = ({ kbID, value }) => {
	const dispatch = useDispatch();
	const aiTaskData = useSelector(selectAiTasks);
	const [isLoading, setIsLoading] = useState(false);
	const options = [
		{ key: 'Always', value: 'Always' },
		{ key: 'TriggeredWhenUnanswered', value: 'Triggered When Unanswered' },
		{ key: 'NoLeads', value: 'No Leads' },
	];
	const [selectedOption, setSelectedOption] = useState(
		options.filter((o) => o.key === value)[0]
	);
	const handleCheckboxChange = (event) => {
		if (event.target.checked) {
			setSelectedOption(
				options.filter((o) => o.value === event.target.value)[0]
			);
		} else {
			setSelectedOption('');
		}
	};
	useEffect(() => {
		dispatch(getAiTasks());
	}, [dispatch]);

	const updateKb = async () => {
		setIsLoading(true);
		const data = { collect_leads: selectedOption.key };
		switch (selectedOption.key) {
			case 'Always':
				var agent = aiTaskData.filter((task) => task.attributes.task_id === 17)[0];
				data['default_ai_task'] = agent.id;

				break;
			case 'TriggeredWhenUnanswered':
				var agent = aiTaskData.filter((task) => task.attributes.task_id === 16)[0];
				data['default_ai_task'] = agent.id;

				break;
			case 'NoLeads':
				var agent = aiTaskData.filter((task) => task.attributes.task_id === 17)[0];
				data['default_ai_task'] = agent.id;

				break;
		}
		try {
			const response = await axios
				.put(
					`${process.env.REACT_APP_AUTH_BASE_URL}/api/knowledgebases/${kbID}`,
					{ data: data }
				)
				.then((r) => {
					dispatch(
						showMessage({
							message: 'Successfully updated lead preference ',
						})
					);
				});
		} catch (e) {
			dispatch(
				showMessage({
					message: 'Failed to update lead preference. Try again ',
				})
			);
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<div className="mt-24 settigs-grid-container">
			<div className="flex flex-col">
				<div className="left-side">
					<img
						src={'assets/images/base_url_icon.png'}
						className="w-36 h-36"
						alt="Icon"
					/>
					<div className="mt-16 ml-2 widget-label-medium">
						Collect leads
					</div>
					<div className="ml-2 mt-8 widget-label-regular">
						By selecting this you can collect leads in your chatbot
						by presenting a form to the customer after every
						question
					</div>
				</div>
				<div className="right-side mt-20">
					<FormControl>
						{options.map((option) => (
							<FormControlLabel
								key={option.key}
								control={
									<Checkbox
										checked={
											selectedOption?.key === option.key
										}
										onChange={handleCheckboxChange}
										value={option.value}
										sx={{
											'& .MuiSvgIcon-root': {
												color: option.value
													? 'primary.main'
													: 'grey', // Change 'grey' to your desired color
											},
										}}
									/>
								}
								label={option.value}
							/>
						))}
					</FormControl>
					{/* <Switch  checked={checked}
				onChange={handleChange}
				inputProps={{ 'aria-label': 'controlled' }}
				sx={{
					'& .MuiSwitch-thumb': {
					  color: checked ? '#7F56D9' : '#9e9e9e', // Blue when checked, gray when unchecked
					},
					'& .MuiSwitch-track': {
					  backgroundColor: checked ? '#2196f3' : '#9e9e9e', // Blue when checked, gray when unchecked
					},
				  }}
			/> */}
					{/* <CustomSwitch

			selectionMode={1}
			option1={'Single'}
			option2={'Scrape'}
			// onSelectSwitch={onSelectSwitch}
			/> */}
				</div>
			</div>
			<Button
				disabled={isLoading}
				className="purple-styled-button mr-10 w-fit mt-20"
				variant="outlined"
				onClick={(e) => updateKb()}
			>
				{isLoading ? (
					<CircularProgress
						thickness={5}
						size={18}
						className="mr-8 text-white"
					/>
				) : (
					<div></div>
				)}
				{isLoading ? 'Updating' : 'Update'}
			</Button>
		</div>
	);
};
export default LeadForm;
