import i18next from "i18next";

import ar from "./navigation-i18n/ar";
import en from "./navigation-i18n/en";
import tr from "./navigation-i18n/tr";
import authRoles from "../auth/authRoles";
import { HomeIcon, LogOutIcon, Building2Icon, LogInIcon } from "lucide-react";

i18next.addResourceBundle("en", "navigation", en);
i18next.addResourceBundle("tr", "navigation", tr);
i18next.addResourceBundle("ar", "navigation", ar);

const navigationConfig = [
  {
    id: "dashboards",
    type: "group",
    icon: <HomeIcon className="w-20 h-20" />,
    translate: "DASHBOARDS",
    children: [
      {
        id: "dashboards.usage",
        title: "Home",
        type: "item",
        icon: <HomeIcon className="w-20 h-20 " />,
        url: "/dashboards/home",
      },

      {
        id: "dashboards.organisation",
        title: "Profile",
        type: "item",
        icon: <Building2Icon className="w-20 h-20" />,
        url: "/dashboards/organisation",
      },
    ],
  },
  {
    id: "auth",
    type: "group",
    icon: "verified_user",
    children: [
      {
        id: "sign-in",
        title: "Sign in",
        type: "item",
        url: "sign-in",
        auth: authRoles.onlyGuest,
        icon: <LogInIcon className="w-20 h-20" />,
      },
      {
        id: "sign-out",
        title: "Sign out",
        type: "item",
        auth: authRoles.user,
        url: "sign-out",
        icon: <LogOutIcon className="w-20 h-20" />,
        style: { flex: 1, justifyContent: "flex-end" },
      },
    ],
  },
  // {
  //   type: 'divider',
  //   id: 'divider-2',
  // },
];

export const drawerStatsItems = [
  {
    image: "assets/images/queries-count.png",
    alt: "Search Icon",
    text: "0/100 Queries",
  },
  {
    image: "assets/images/words-count.png",
    alt: "Cloud Icon",
    text: "0.00/0.02 Million words stored",
  },
  {
    image: "assets/images/agent-count.png",
    alt: "Agents Icon",
    text: "0/1 Agents",
  },
  {
    image: "assets/images/datastore-count.png",
    alt: "Datastores Icon",
    text: "0/1 Datastores",
  },
];

export default navigationConfig;
