import React from 'react';

export const Pill = ({ text }) => {
  return (
    <div className="relative inline-flex items-center px-4 py-2 text-white font-bold rounded-sm overflow-hidden text-xs from-base-purple to-secondary-color bg-gradient-to-r">
      {text}
      <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-r from-transparent to-white opacity-25 rounded-full transform translate-x-[-100%] animate-shine"></div>
    </div>
  );
};



export const DisabledPill = ({ text }) => {
  return (
    <div className="relative inline-flex items-center px-4 py-2 text-white font-bold rounded-sm overflow-hidden text-xs from-red-400 to-base-purple bg-gradient-to-r">
      {text}
      <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-r from-transparent to-white opacity-25 rounded-full transform translate-x-[-100%] animate-shine"></div>
    </div>
  );
};

export default Pill;


